import React from "react";

import {
  Container,
  Button,
  // Customizable Area Start
  createTheme,
  Breadcrumbs,
  Typography,
  Box,
  styled,
  Switch,
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Divider,
  Backdrop,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HeaderDropdown from "../../../blocks/navigationmenu/src/HeaderDropdown.web";
import { BackArrowBlack, sad } from "../../../blocks/subscriptionbilling/src/assets";
import CloseIcon from '@mui/icons-material/Close';
import {Toaster} from "react-hot-toast";
import { avatar, avatar_female, crossIcon } from "./assets";

const StyledTypography = styled(Typography)({

  "&.storage-title": {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    "& p": {
      marginBottom: "0px",
      marginTop: "0px",
    },
    "@media (max-width: 1200px)": {
      fontSize: 24,
      marginTop: "10px",
    },
    "@media (max-width: 800px)": {
      fontSize: 20,
      paddingRight: "5px",
    },
  },

  "&.breadcrumbsText": {
    fontSize: "14px",
    color: "#1C2110",
  },

  "&.titleText": {
    color: "#1C1917",
    fontSize: "30px",
    fontWeight: 700,
    "@media (max-width: 700px)": {
      fontSize: "24px",
    },
    "@media (max-width: 550px)": {
      fontSize: "22px",
    },
  }, 

  "&.modal-title": {
      fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110',
      "@media (max-width: 650px)": {
          fontSize: 18,
      },
  },

  "&.modal-subtitle": {
    color: '#57534E',
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    lineHeight: "24px",
    marginTop: '15px',
    "@media (max-width: 650px)": {
      fontSize: 14,
    },
  },
  "&.dialogueBtns": {
    "& div": {
      display: 'flex', justifyContent: 'space-between', paddingBottom: 30, margin: '20px 10px 20px 10px',
      "@media (max-width: 650px)": {
        flexDirection: "column"
      },
    }
  },
  "&.confirmation-title": {
    fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110', marginTop: '20px', alignItems: 'center', width: '400px',
    "@media (max-width: 650px)": {
      fontSize: 16,
      width: "90%"
    },
  },
  "&.confirmation-description": {
    width: '450px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#1C2110', marginTop: '20px', alignItems: 'center',
    "@media (max-width: 650px)": {
      fontSize: 14,
      width: "95%"
    },
  },
  "&.sublabel": {
    fontSize: "18px",
    "@media (max-width: 700px)": {
      fontSize: "16px",
    },
    "@media (max-width: 550px)": {
      fontSize: "14px",
    },
  },
  "&.security-details-txt": {
    fontSize: "20px",
    fontWeight: 700,
    "@media (max-width: 550px)": {
      fontSize: "18px",
    },
  },

  "&.security-sublabel": {
    fontSize: '16px',
    fontWeight: 400,
    "@media (max-width: 550px)": {
      fontSize: "14px",
    },
  },

  "&.two-fa-sublabel": {
    color: "#5D6063",
    fontSize: "14px",
    width: "80%",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },

  "&.two-fa-title": {
    color: "#1C2110",
    fontSize: "16px",
    fontWeight: 700
  },

  "&.change-password": {
    color: "#8CA74B",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
    "@media (max-width: 550px)": {
      fontSize: "14px",
    },
  },

  "&.blocked-acc-name": {
    color: "#1C2110",
    fontSize: "16px",
    marginLeft: "10px",
    "@media (max-width: 550px)": {
      fontSize: "14px",
    },
  }

});

const StyledButton = styled(Button)({
  "&.breadcrumbsButton": {
    textTransform: "none"
  }
})

const StyledBox = styled(Box)({
  "&.top-container": {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    maxWidth: "1635px",
    backgroundPosition: "center",
    height: "850px",
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 1475px)": {
      height: "fit-content",
    },
  },

  "&.data-container": {
    marginTop:"35px",
    width:"40%",
    "@media (max-width: 1200px)": {
      width:"60%",
    },
    "@media (max-width: 800px)": {
      width:"100%",
    },
  },

  "&.main-container": {
    padding: '30px', marginLeft: '50px', marginRight: '20px' ,
    "@media (max-width: 800px)": {
      marginLeft:"0px",
    },
  },
  "&.top-title-container": {
    display: 'flex', marginTop: "14px", alignItems: "flex-start",
    "@media (max-width: 550px)": {
      marginLeft:"-20px",
    },
  },
  "&.blocked-acc-container": {
    marginTop:"20px",
    maxHeight:"300px",
    display:"flex",
    flexDirection:"column",
    flexWrap: "wrap",
    maxWidth: "100%",
    overflow: 'auto',
    scrollbarColor: "#8CA74B transparent"
  }
});


const StyledBreadcrumbs = styled(Breadcrumbs)({
  "&.breadCrumbs": {
    marginLeft: '60px',
    "@media (max-width: 550px)": {
      marginLeft:"0px",
    },
  }
})

const StyledSwitch = styled(Switch)(() => ({
    width: 54,
    height: 28,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(25px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#8CA74B',
                opacity: 1,
                border: 0,
            },
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 25,
        height: 25,
    },
    '& .MuiSwitch-track': {
        borderRadius: 13,
        backgroundColor: '#E9E9EA',
        opacity: 1,
    },
}));

const StyledDialogBtn = styled(DialogActions)({
  "&.dialogueBtns": {
      display: 'flex', justifyContent: 'space-between', paddingBottom: 30, margin: '20px 10px 20px 10px',
      "@media (max-width: 650px)": {
          flexDirection: "column",
          gap: '10px'
      }
  },
});

const blockedAccounts = [
  {id: 1, avatar: avatar, name: "Andrew Johnson"},
  {id: 2, avatar: avatar_female, name: "Katherine Bain"},
]


// Customizable Area End

import PrivacySettingsController, {
  Props,
  configJSON,
} from "./PrivacySettingsController";

export default class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box>
        <HeaderDropdown data-test-id="headerComponent" navigation={this.props.navigation} id={""} />
        <StyledBox className="main-container" >
          <StyledBreadcrumbs className="breadCrumbs" separator={<NavigateNextIcon fontSize="small" />}>
            <StyledButton className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
              Settings
            </StyledButton>
            <StyledTypography className="breadcrumbsText">
              Security & privacy
            </StyledTypography>
          </StyledBreadcrumbs>
          <StyledBox className="top-title-container">
            <Button className="backButton" data-test-id="backButton" onClick={() => this.props.navigation.goBack()}>
              <img className="arrowButton" src={BackArrowBlack} alt="Back" />
            </Button>
            <Box>
              <StyledTypography className="titleText">Security & privacy</StyledTypography>
              <StyledTypography className="sublabel">
                Update your security preferences here.
              </StyledTypography>
            </Box>
          </StyledBox>
          <Box marginLeft={"20px"}>
            <Box marginTop={"35px"}>
              <StyledTypography className="security-details-txt">Security details</StyledTypography>
              <StyledTypography className="security-sublabel">
                Customize and enhance the security settings for your account. Take control of your privacy and data protection.
              </StyledTypography>
            </Box>
            <StyledBox className="data-container" >
              <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <StyledTypography className="two-fa-title">Two-factor authentication</StyledTypography>
                <StyledSwitch className="two-fa-switch" data-test-id="two-fa-switch" checked={this.state.isTwoFaEnabled}
                  onChange={(event) => this.handleTwofaChange(event)} />
              </Box>
              <StyledTypography className="two-fa-sublabel">
                You'll receive a unique code on your registered email, which you'll need to enter along with your password to log in.
              </StyledTypography>
            </StyledBox>
            <StyledBox className="data-container">
              <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <StyledTypography className="two-fa-title">Password</StyledTypography>
                <StyledTypography className="change-password" data-test-id="change-password-text" onClick={() => this.navigationTo("VerifyPassword")}>Change password</StyledTypography>
              </Box>
              <StyledTypography className="security-sublabel" style={{
                color: "#1C2110"
              }}>
                ********
              </StyledTypography>
            </StyledBox>
            <Box marginTop={"35px"}>
              <StyledTypography className="security-details-txt">Blocked accounts</StyledTypography>
              <StyledTypography className="security-sublabel">
                Prevent specific users from interacting with you on the platform.
              </StyledTypography>
            </Box>
            <StyledBox className="blocked-acc-container">
              { blockedAccounts?.length > 0 ? 
                blockedAccounts.map((acc) => {
                  return (
                    <Box key={acc.id} display={"flex"} alignItems={"center"} marginTop={"10px"} marginLeft={"5px"}>
                      <Avatar src={acc.avatar} style={{
                        height: "32px",
                        width: "32px",
                        border: "1px solid #C2E176",
                      }} />
                      <StyledTypography className="blocked-acc-name">{acc.name}</StyledTypography>
                      <img src={crossIcon} style={{ marginLeft: "5px", cursor: "pointer" }} alt="" />
                    </Box>
                  )
                }) : 
                <StyledTypography className="two-fa-title">No blocked accounts found.</StyledTypography>
              }
            </StyledBox>
          </Box>
        </StyledBox>
        <Dialog open={this.state.isConfirmationModalOpen} onClose={this.handleCloseConfirmationModal} PaperProps={{
          style: {
            height: 550,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: 8,
            borderBottomRightRadius: 50,
          },
        }} >
          <DialogTitle style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins' }}>
            <StyledTypography className="modal-title">Disable 2FA ?</StyledTypography>
            <IconButton
              style={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#666',
              }}
              onClick={this.handleCloseConfirmationModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Box style={{
                backgroundColor: '#8CA74B',
                textAlign: 'center',
                height: '130px',
                borderRadius: '80px',
                display: 'flex',
                justifyContent: 'center',
                width: '131px',
                marginTop: '20px',
                alignItems: 'center'
              }}>
                <img src={sad} style={{ height: '65px', width: '65px', color: 'white' }} />
              </Box>
            </div>
            <Box style={{ textAlign: 'center', margin: '20px 10px 20px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <StyledTypography className="confirmation-title" >Do you want to Disable Two factor authentication?</StyledTypography>
            </Box>
          </DialogContent>
          <Divider />
          <StyledDialogBtn className="dialogueBtns" >
            <Button data-test-id="handleCancelSuccessModal" onClick={this.handleCloseConfirmationModal} style={{
              border: '1px solid #C2E176',
              backgroundColor: '#F8FFE7',
              width: '233px',
              height: '56px',
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              fontFamily: "Poppins",
              lineHeight: "24px",
              color: '#8CA74B',
              whiteSpace: "nowrap"
            }}>Cancel</Button>
            <Button onClick={() => {this.toggleTwoFa(false); this.handleCloseConfirmationModal()}} style={{
              backgroundColor: '#8CA74B',
              width: '233px',
              height: '56px',
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              fontFamily: "Poppins",
              lineHeight: "24px",
              color: 'white',
              whiteSpace: "nowrap",
              marginLeft: "15px"
            }}>Disable</Button>
          </StyledDialogBtn>
        </Dialog>
        <Toaster/>
        {
          this.state.isLoading &&
          <>
            <Backdrop style={{ color: "#8CA74B", zIndex: 9999}} open = {this.state.isLoading}>
              <CircularProgress color="inherit" style={{
                height: "75px",
                width: "75px"
              }} />
            </Backdrop></>
        }
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
