import React from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Typography
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CreateGroupController, {
    Props,
} from "./CreateGroupController.web";
import { avatar, BackArrowBlack, dummyCreateImg, iIcon, people1, recipe } from "./assets";
import StarIcon from '@material-ui/icons/Star';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@mui/icons-material/Check';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MusicNoteOutlinedIcon from '@material-ui/icons/MusicNoteOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';

export default class CreateGroup extends CreateGroupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const mediaOpen = Boolean(this.state.mediaAnchorEl);
        const thingsOpen = Boolean(this.state.thingsAnchorEl);
        const peopleOpen = Boolean(this.state.peopleAnchorEl);
        const permissionOpen = Boolean(this.state.permissionAnchorEl);
        const locationOpen = Boolean(this.state.locationAnchorEl);
        const dateOpen = Boolean(this.state.dateAnchorEl);
        const people = [
            { name: 'Andrea', count: 10, avatar: { people1 } },
            { name: 'Sofi', count: 2, avatar: { people1 } },
            { name: 'Kate', count: 7, avatar: { people1 } },
            { name: 'Kevin', count: 28, avatar: { people1 } },
            { name: 'Joseph', count: 48, avatar: { people1 } },
            { name: 'Sarah', count: 5, avatar: { people1 } },
        ];
        const mediaType = [
            { name: 'Photos', icon: <PhotoCameraOutlinedIcon />, avatar: { people1 } },
            { name: 'People', icon: <PhotoCameraOutlinedIcon />, avatar: { people1 } },
            { name: 'Videos', icon: <VideocamOutlinedIcon />, avatar: { people1 } },
            { name: 'Recipes', icon: recipe, avatar: 'people1' },
            { name: 'Memories', icon: <OndemandVideoOutlinedIcon />, avatar: { people1 } },
            { name: 'Family Tree', icon: <AccountTreeOutlinedIcon />, avatar: { people1 } },
            { name: 'Documents', icon: <DescriptionOutlinedIcon />, avatar: { people1 } },
            { name: 'Audios', icon: <MusicNoteOutlinedIcon />, avatar: { people1 } },
        ];
        const thingsType = [
            { name: 'Wallet', count: 10, },
            { name: 'Laptop', count: 2, },
            { name: 'Table', count: 4, },
            { name: 'Clothes', count: 18, },

        ];
        const permissionType = [
            { name: 'Viewer' },
            { name: 'Contributor' },
            { name: 'Owner' }
        ]
        const placeType = [
            { name: 'House', count: 10 },
            { name: 'School', count: 2 },
            { name: 'Beach', count: 7 },
            { name: 'Park', count: 5 },
            { name: 'Building', count: 15 }
        ]
        const citiesType = [
            { name: 'Miami', count: 15 },
            { name: 'New York', count: 7 },
            { name: 'Madrid', count: 2 },
            { name: 'Austin', count: 10 },
        ]
        const years = Array.from(new Array(30), (val, index) => 2000 + index);
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'
        ];
        return (
            <Container>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '-22px' }}>
                        <Button className="backButton" data-test-id="backButton">
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Create a Sharing Group</Typography>
                    </Box>
                    <Box style={{ display: 'flex', gap: '15px' }}>
                        <Box>
                            <Typography className="selectText">Select content</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'end', gap: '5px' }}><Box className="bgDigitBox"><Typography className="selectedItemDigit">5</Typography></Box><Typography className="selectedText">Selected</Typography></Box>
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px' }}>
                            <Button className="doneBtn" data-test-id="handleDoneBtn" onClick={this.handleDoneBtn}>Done</Button>
                            <Button className="cancelBtn">Cancel</Button>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ marginTop: '30px' }}>
                    <Typography className="filterTextTitle">Filter by</Typography>
                    <Box className="filterMainBoxs">
                        <Box className="filterSubBoxs">
                            <Box className="filterBoxDiv">
                                <StarIcon style={{ height: '16px', width: '16px' }} /> <Typography className="filterBoxTextTitles" style={{ marginLeft: '2px' }}> Favorites</Typography>
                            </Box>
                            <Button className="filterBoxDiv" style={{ marginLeft: '7px' }} data-test-id="mediaClickBtn" onClick={this.handleMediaClick}>
                                <Typography className="filterBoxTextTitles">Media Type</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Popover
                                open={mediaOpen}
                                onClose={this.handleMediaClose}
                                anchorEl={this.state.mediaAnchorEl}
                                anchorOrigin={{
                                    horizontal: 'left',
                                    vertical: 'bottom',
                                }}>
                                <Box style={{ width: "300px", padding: '10px' }}>
                                    <List>
                                        {mediaType.map((person) => (
                                            <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                <Checkbox icon={<div style={{ border: '1px solid #333', width: 20, height: 20, borderRadius: '4px', boxSizing: 'border-box' }} />}
                                                    checkedIcon={<div style={{ backgroundColor: '#557C29', width: 20, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}  >
                                                        <CheckIcon style={{ color: 'white', fontSize: 18 }} />
                                                    </div>
                                                    } />
                                                <div style={{ marginRight: 10 }}>
                                                    {typeof person.icon === 'string' ? (
                                                        <img src={person.icon} alt={person.name} style={{ width: 24, height: 24 }} />
                                                    ) : (
                                                        person.icon
                                                    )}
                                                </div>
                                                <ListItemText primary={<Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 500 }}> {person.name} </Typography>}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <Button data-test-id="handleMediaCloseBtn" onClick={this.handleMediaClose} style={{ backgroundColor: '#557C29', color: 'white', width: '278px', padding: '6px 10px', fontFamily: 'Poppins', fontWeight: 600, borderRadius: 8, textTransform: "none" }} > Done </Button>
                                    </div>
                                </Box>
                            </Popover>
                            <Button className="filterBoxDiv" data-test-id="handleDateClickBtn" onClick={this.handleDateClick}>
                                <Typography className="filterBoxTextTitles">Date</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Popover
                                anchorEl={this.state.dateAnchorEl}
                                open={dateOpen}
                                data-test-id="handleDateCloseModal"
                                onClose={this.handleDateClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }} >
                                <div style={{ padding: '20px', width: "300px" }} >
                                    <Typography className="filterBoxTextTitles">Year</Typography>
                                    <FormControl margin="normal" variant="outlined" fullWidth style={{ marginTop: '1px' }}>
                                        <Select value={this.state.year} onChange={this.handleYearChange} autoComplete="off" placeholder="Choose Year" name="year" variant="outlined" displayEmpty>
                                            <MenuItem value="" disabled>Choose Year</MenuItem>
                                            {years.map((year) => (
                                                <MenuItem key={year} value={year}> {year} </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Typography className="filterBoxTextTitles" style={{ marginTop: '5px' }}>Month</Typography>
                                    <FormControl fullWidth margin="normal" variant="outlined" style={{ marginTop: '1px' }}>
                                        <InputLabel>Choose Month</InputLabel>
                                        <Select value={this.state.month} onChange={this.handleMonthChange} variant="outlined" autoComplete="off" placeholder="Choose Month" name="month" displayEmpty>
                                            <MenuItem value="" disabled>Choose Month</MenuItem>
                                            {months.map((month, index) => (
                                                <MenuItem key={index} value={month}>  {month} </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Popover>
                            <Button className="filterBoxDiv" data-test-id="handlethingsClickBtn" onClick={this.handlethingsClick}>
                                <Typography className="filterBoxTextTitles">Things</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Popover open={thingsOpen} anchorEl={this.state.thingsAnchorEl} onClose={this.handlethingsClose} anchorOrigin={{ horizontal: 'left', vertical: 'bottom', }}>
                                <div style={{ width: "300px", padding: '10px' }}>
                                    <div style={{ position: 'relative' }}>
                                        <SearchIcon style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none', color: '#5D6063' }} />
                                        <input type="text" placeholder="Search" style={{ width: '100%', padding: '8px 8px 8px 40px', borderRadius: '40px', border: '1px solid #ddd', fontFamily: 'Poppins', fontSize: '14px', }} />
                                    </div>
                                    <List>
                                        {thingsType.map((person) => (
                                            <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                <Checkbox icon={<div style={{ border: '1px solid #333', width: 20, height: 20, borderRadius: '4px', boxSizing: 'border-box' }} />}
                                                    checkedIcon={
                                                        <div style={{ backgroundColor: '#557C29', width: 20, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }} >
                                                            <CheckIcon style={{ color: 'white', fontSize: 18 }} />
                                                        </div>} />
                                                <ListItemText primary={<Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 500 }}> {person.name} </Typography>} />
                                                <Typography style={{ fontSize: 12, color: '#6b7280', borderRadius: '40px', width: '26px', display: 'flex', height: '18px', backgroundColor: '#f7f0f0', alignItems: 'center', justifyContent: 'center', fontFamily: 'Poppins', border: '1px solid #A8A29E' }}>{person.count}</Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                                        <Button onClick={this.handlethingsClose} data-test-id="handlethingsCloseBtn" style={{ backgroundColor: '#557C29', color: 'white', width: '278px', padding: '6px 10px', fontFamily: 'Poppins', fontWeight: 600, borderRadius: 8, textTransform: "none" }} > Done </Button>
                                    </div>
                                </div>
                            </Popover>
                            <Button className="filterBoxDiv" data-test-id="handlePeopleClickBtn" onClick={this.handlePeopleClick}>
                                <Typography className="filterBoxTextTitles">People</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Popover onClose={this.handlePeopleClose} open={peopleOpen} anchorEl={this.state.peopleAnchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}>
                                <div style={{ width: "300px", padding: '10px' }}>
                                    <div style={{ position: 'relative' }}>
                                        <SearchIcon style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none', color: '#5D6063' }} />
                                        <input type="text" placeholder="Search" style={{ width: '100%', padding: '8px 8px 8px 40px', borderRadius: '40px', border: '1px solid #ddd', fontFamily: 'Poppins', fontSize: '14px', }} />
                                    </div>
                                    <List>
                                        {people.map((person) => (
                                            <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                <Checkbox icon={<div style={{ border: '1px solid #333', width: 20, boxSizing: 'border-box', borderRadius: '4px', height: 20 }} />}
                                                    checkedIcon={<div style={{ height: 20, width: 20, alignItems: 'center', display: 'flex', backgroundColor: '#557C29', justifyContent: 'center', borderRadius: '4px', }} > <CheckIcon style={{ color: 'white', fontSize: 18 }} /> </div>} />
                                                <Avatar src={people1} alt={person.name} style={{ width: 32, marginRight: 10, height: 32 }} />
                                                <ListItemText primary={<Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 500 }}> {person.name} </Typography>} />
                                                <Typography style={{ color: '#6b7280', fontSize: 12, borderRadius: '40px', width: '26px', backgroundColor: '#f7f0f0', display: 'flex', height: '18px', alignItems: 'center', justifyContent: 'center', border: '1px solid #A8A29E', fontFamily: 'Poppins' }}>{person.count}</Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <Button onClick={this.handlePeopleClose} data-test-id="handlePeopleCloseBtn" style={{ backgroundColor: '#557C29', color: 'white', width: '278px', padding: '6px 10px', fontFamily: 'Poppins', fontWeight: 600, borderRadius: 8, textTransform: "none" }} > Done </Button>
                                    </div>
                                </div>
                            </Popover>
                            <Button className="filterBoxDiv" data-test-id="handlelocationClickBtn" onClick={this.handlelocationClick}>
                                <Typography className="filterBoxTextTitles">Location</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <StyledPopover
                                open={locationOpen}
                                anchorEl={this.state.locationAnchorEl}
                                onClose={this.handlelocationClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}>
                                <div style={{ width: "300px", padding: '10px' }}>
                                    <div style={{ position: 'relative' }}>
                                        <SearchIcon className="SearchIcon" />
                                        <input className="searchinputBox" type="text" placeholder="Search" />
                                    </div>
                                    <Typography className="listTitleText">Places</Typography>
                                    <List>
                                        {placeType.map((person) => (
                                            <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                <Checkbox icon={<div className="checkboxIcon" />}
                                                    checkedIcon={<div className="checkedIcon"> <CheckIcon style={{ color: 'white', fontSize: 18 }} /> </div>} />
                                                <ListItemText primary={<Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 500 }}> {person.name}  </Typography>} />
                                                <Typography className="personCountText">{person.count}</Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Typography className="listCityTitle">Cities/Countries</Typography>
                                    <List>
                                        {citiesType.map((person) => (
                                            <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                <Checkbox icon={<div className="checkboxIcon" />}
                                                    checkedIcon={<div className="checkedIcon"> <CheckIcon style={{ color: 'white', fontSize: 18 }} /> </div>} />
                                                <ListItemText primary={<Typography className="personNameText"> {person.name}  </Typography>} />
                                                <Typography className="personCountText">{person.count}</Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <div className="btnDiv">
                                        <Button className="doneBtnStyle" onClick={this.handlelocationClose} data-test-id="handlelocationCloseBtn">
                                            Done
                                        </Button>
                                    </div>
                                </div>
                            </StyledPopover>
                            <Button className="filterBoxDiv" data-test-id="handlepermissionClickBtn" onClick={this.handlepermissionClick}>
                                <Typography className="filterBoxTextTitles">Permission</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Popover
                                open={permissionOpen}
                                anchorEl={this.state.permissionAnchorEl}
                                onClose={this.handlepermissionClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}>
                                <div style={{ width: "190px", padding: '10px' }}>
                                    <List>
                                        {permissionType.map((person) => (
                                            <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                <Checkbox icon={<div style={{ border: '1px solid #333', width: 20, height: 20, borderRadius: '4px', boxSizing: 'border-box', }} />}
                                                    checkedIcon={
                                                        <div style={{
                                                            backgroundColor: '#557C29',
                                                            width: 20,
                                                            height: 20,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '4px',
                                                        }} >  <CheckIcon style={{ color: 'white', fontSize: 18 }} /> </div>} />
                                                <ListItemText primary={
                                                    <Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 500 }}>
                                                        {person.name}
                                                    </Typography>
                                                } />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                                        <Button onClick={this.handlepermissionClose} data-test-id="handlepermissionCloseBtn" style={{
                                            backgroundColor: '#557C29',
                                            color: 'white',
                                            width: '278px',
                                            padding: '6px 10px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 600,
                                            borderRadius: 8,
                                            textTransform: "none"
                                        }}  >
                                            Done
                                        </Button>
                                    </div>
                                </div>
                            </Popover>
                        </Box>
                        <Box className="filterBoxDiv">
                            <Typography className="filterBoxTextTitles">Date Taken</Typography>
                            <IconButton style={{ marginLeft: '10px', padding: '0' }}><KeyboardArrowDownIcon /></IconButton>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                    <Paper style={{ width: '100%', borderRadius: '40px', border: '1px solid #D6D3D1' }}>
                        <IconButton type="button" style={{ padding: "10px", color: "#A8A29E" }}> <SearchIcon /> </IconButton>
                        <InputBase style={{ marginLeft: 1, color: "#A8A29E", fontSize: '16px', width: '94%', fontWeight: 400 }} placeholder="Search" />
                        <IconButton type="button" style={{ color: "#A8A29E", padding: "10px" }}>
                            <PhotoCameraOutlinedIcon />
                        </IconButton>
                    </Paper>
                </Box>
                <Box>
                    <>
                        <Box style={{ marginTop: '10px' }}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox icon={<div
                                    style={{
                                        width: 20, border: '1px solid #333', borderRadius: '4px', boxSizing: 'border-box', height: 20,
                                    }} />}
                                    checkedIcon={<div style={{
                                        backgroundColor: '#557C29', height: 20, width: 20, alignItems: 'center', display: 'flex', borderRadius: '4px', justifyContent: 'center',
                                    }} >
                                        <CheckIcon style={{ color: 'white', fontSize: 18 }} />
                                    </div>
                                    } />
                                <Typography className="dateTypoText"> Apr 04, 2024</Typography></Box>
                            <Box style={{ display: 'flex', gap: '5px' }}>
                                <Box className="dummyImgBox">
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                        <Checkbox style={{ color: 'white' }} />
                                        <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '90px', padding: '22px' }}>
                                        <Box>
                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                        </Box>
                                        <Box style={{ display: 'flex', marginRight: '5px' }}>
                                            <ChatBubbleOutlineIcon style={{ color: 'white' }} /><Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                            <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} /><Typography style={{ color: 'white' }}>3</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="dummyImgBox">
                                    <Box style={{ display: 'flex', alignItems: 'center', padding: '10px', justifyContent: 'space-between' }}>
                                        <Checkbox style={{ color: 'white' }} />
                                        <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                    </Box>
                                    <Box style={{ display: 'flex', marginTop: '90px', justifyContent: 'space-between', padding: '22px', marginRight: '35px' }}>
                                        <Box>
                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                        </Box>
                                        <Box style={{ display: 'flex' }}>
                                            <ChatBubbleOutlineIcon style={{ color: 'white' }} /><Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                            <FavoriteIcon style={{ marginLeft: '3px', color: 'red' }} /><Typography style={{ color: 'white' }}>3</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                </Box>

                <Dialog
                    open={this.state.infoModalOpen}
                    onClose={this.handleCloseModal}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '560px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        }
                    }}
                >
                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: 15, alignItems: 'center' }}>
                        <Typography style={{
                            fontSize: 22,
                            fontWeight: 700,
                            fontFamily: "Inter",
                            color: '#1C2110'
                        }}>Group info</Typography>
                        <CloseIcon style={{ cursor: 'pointer', color: '#334155' }} onClick={this.handleCloseModal} />
                    </Box>
                    <Divider />
                    <DialogBoxContent>
                        <Box>
                            <Typography className="typoTextTitle">
                                Group name
                            </Typography>
                            <div className="textFieldCss">
                                <input className="inputTextFieldCSS"
                                    type="text"
                                    id="add-people-input"
                                    placeholder="Group name"
                                />
                            </div>
                        </Box>
                        <Typography className="typoTextTitle">
                            Share
                        </Typography>
                        <Typography className="typoTextTitle">
                            People, family or friends
                        </Typography>
                        <div className="textFieldCss">
                            <input className="inputTextFieldCSS"
                                type="text"
                                id="add-people-input"
                                placeholder="Add people who you want to share this file"
                            />
                            <div className="infoIconBox">
                                <img src={iIcon} className="infoIcon" />
                                <Button className="ContriBtn">
                                    Contributor
                                    <KeyboardArrowDownIcon className="keyIcon" />
                                </Button>
                            </div>
                        </div>
                        <Box className="shareBox">
                            <Typography className="linkText" >
                                Shareable link
                            </Typography>
                            <div className="contriDivBox">
                                <img src={iIcon} className="infoIcon" />
                                <Button className="ContriBtn">
                                    Contributor
                                    <KeyboardArrowDownIcon className="keyIcon" />
                                </Button>
                            </div>
                        </Box>
                        <Typography className="infoTitleText">Copy this link if you want to share with any user who has the link.</Typography>
                        <div className="textFieldCss">
                            <input className="inputTextFieldCSS"
                                type="text"
                                id="add-people-input"
                                placeholder="Add people who you want to share this file"
                            />
                        </div>
                    </DialogBoxContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: 25, marginTop: 15 }}>
                        <Button style={{ width: "141px", height: 56, borderRadius: 8, border: "1px solid #557C29", backgroundColor: '#F8FFE7' }}  >
                            <LinkIcon style={{ color: "#557C29", marginRight: '5px' }} /><Typography style={{ fontWeight: 700, fontSize: 16, color: "#557C29", textTransform: 'none' }}>Copy link</Typography>
                        </Button>
                        <Button style={{ backgroundColor: "#557C29", width: "141px", height: 56, borderRadius: 8, textTransform: 'none' }} data-test-id="handleCloseModal" onClick={this.handleCloseModal}>
                            <Typography style={{ fontWeight: 700, fontSize: 16, fontFamily: "Poppins", color: "#fff" }}>Done</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container >
        );
    }
}

// Customizable Area Start
const StyledPopover = styled(Popover)({
    '.MuiPopover-paper': {
        width: '300px',
        padding: '10px',
    },
    "& .SearchIcon": {
        position: 'absolute',
        left: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        color: '#5D6063'
    },
    "& .searchinputBox": {
        width: '100%',
        padding: '8px 8px 8px 40px',
        borderRadius: '40px',
        border: '1px solid #ddd',
        fontFamily: 'Poppins',
        fontSize: '14px'
    },
    "& .listTitleText": {
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: 700,
        marginTop: '15px'
    },
    "& .personCountText": {
        fontSize: 12,
        color: '#6b7280',
        backgroundColor: '#f7f0f0',
        borderRadius: '40px',
        width: '26px',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        border: '1px solid #A8A29E'
    },
    "& .personNameText": {
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500
    },
    "& .listCityTitle": {
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: 700
    },
    "& .checkboxIcon": {
        border: '1px solid #333',
        width: 20,
        height: 20,
        borderRadius: '4px',
        boxSizing: 'border-box'
    },
    "& .checkedIcon": {
        backgroundColor: '#557C29',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
    },
    "& .doneBtnStyle": {
        backgroundColor: '#557C29', color: 'white', width: '278px', padding: '6px 10px', fontFamily: 'Poppins', fontWeight: 600, borderRadius: 8, textTransform: "none"
    },
    "& .btnDiv": {
        display: 'flex', justifyContent: 'center', marginTop: '10px'
    }
});

const DialogBoxContent = styled(Box)({
    padding: '20px',
    "& .typoTextTitle": {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '5px'
    },
    "& .textFieldCss": {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '4px',
        height: '56px',
        padding: '0 10px',
        marginTop: '10px',
        marginBottom: '10px'
    },
    "& .inputTextFieldCSS": {
        maxWidth: '497px',
        width: '100%',
        fontSize: '14px',
        border: 'none',
        outline: 'none',
    },
    "& .groupTitle": {
        fontSize: 22,
        fontWeight: 700,
        fontFamily: "Inter",
        color: '#1C2110'
    },
    "& .infoIconBox": {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        marginLeft: 40
    },
    "& .ContriBtn": {
        color: '#557C29',
        cursor: 'pointer',
        textTransform: 'none',
        fontWeight: 700,
        fontFamily: 'Poppins',
        fontSize: 14
    },
    "& .infoIcon": {
        width: 20,
        height: 20
    },
    "& .infoTitleText": {
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 400
    },
    "& .linkText": {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Poppins',
        color: '#1C2110'
    },
    "& .keyIcon": {
        marginLeft: '8px'
    },
    "& .shareBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .contriDivBox": {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    }
})


const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    marginLeft: '20px',
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .cancelBtn": {
        borderRadius: '8px',
        border: '1px solid #C2E176',
        backgroundColor: '#F8FFE7',
        width: '109px',
        height: '44px',
        '& .MuiButton-label': {
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 700,
            color: '#8CA74B',
            fontFamily: "Poppins",
            lineHeight: "24px",
        }
    },
    "& .doneBtn": {
        width: '109px',
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '44px',
        '& .MuiButton-label': {
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: "Poppins",
            textTransform: "none",
            color: 'white',
            lineHeight: "24px",
        }
    },
    "& .selectText": {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: '#1C2110',
        lineHeight: "28px",
    },
    "& .bgDigitBox": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '28px',
        borderRadius: '4px',
        backgroundColor: '#557C29',
    },
    "& .selectedItemDigit": {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: '#FFFFFF',
        lineHeight: "28px",
    },
    "& .selectedText": {
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: '#1C2110',
        lineHeight: "28px",
    },
    "& .dummyImgBox": {
        backgroundImage: `url(${dummyCreateImg})`,
        backgroundRepeat: 'no-repeat',
        width: '21%',
        height: '230px'
    },
    "& .dateTypoText": {
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: "Poppins",
        color: '#1C2110',
        lineHeight: "26px",
    },
    "& .filterBoxDiv": {
        display: 'flex',
        fontSize: "16px",
        alignItems: 'center',
        fontFamily: "Poppins",
        color: '#5D6063',
        textTransform: "none",
        fontWeight: 700,
        lineHeight: '24px',
    },
    "& .filterBoxTextTitles": {
        fontFamily: "Poppins",
        color: '#5D6063',
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "none",
        lineHeight: '24px',
    },
    "& .filterMainBoxs": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .filterTextTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: "#1C2110",
    },
    "& .filterSubBoxs": {
        display: 'flex',
    }
})
// Customizable Area End

