import React, { Component } from 'react';
import { styled, Box, Icon, Typography, Menu, MenuItem, IconButton } from "@material-ui/core";
import { Logo } from '../../blocks/signuplogin/src/assets';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import { setStorageData } from '../../framework/src/Utilities';

interface HeaderProps {
    onLoginClick: () => void;
    onRegisterClick: () => void;
    getHeaderData: (data: string) => void;
}

interface HeaderState {
  isLargeScreen: boolean;
  anchorEl: null | HTMLElement,
  open: boolean,
}

class Header extends Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
      super(props);
      this.state = { 
        isLargeScreen: window.innerWidth >= 900,
        anchorEl: null as HTMLElement | null,
        open: false
      };
    }

    updateScreenSize = () => {
      this.setState({ isLargeScreen: window.innerWidth >= 900 });
    };

    componentDidMount() {
      window.addEventListener("resize", this.updateScreenSize);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateScreenSize);
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    render() {
        const { onLoginClick, onRegisterClick, getHeaderData } = this.props;
        const { isLargeScreen, anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const menuList = [
          { id: 1, title: "Photos", path: "/PhotosPage", header: "Family Photo Storage", isSignup: false, isLogin: false },
          { id: 2, title: "Moments", path: "/MomentsPage", header: "Immortalizing Family Moments", isSignup: false, isLogin: false },
          { id: 3, title: "Family Tree", path: "/FamilyTreePage", header: "Family Tree", isSignup: false, isLogin: false },
          { id: 4, title: "Recipes", path: "/RecipesPage", header: "Family Recipe Preservation", isSignup: false, isLogin: false },
          { id: 5, title: "Plans and Pricing", path: "/Subscriptionbilling", isSignup: false, isLogin: false },
          { id: 6, title: "Contact Us", path: "/ContactusPage", isSignup: false, isLogin: false },
          { id: 7, title: "Register", path: "", isSignup: true, isLogin: false  },
          { id: 8, title: "Log in", path: "/login", isSignup: false, isLogin: true },
        ];

        const handleMenuItemClick = (menu: {id: number, title: string, path: string, isSignup: boolean, isLogin: boolean, header?: string}) => {
          if(menu.header){
            sessionStorage.setItem('tab', menu.header)
          }
          if(menu.isSignup){
            let pathName = window.location.pathname === "/RecipesPage" ? "/RecipsPage" : window.location.pathname;
            setStorageData("signUpCameFrom", pathName)
            onRegisterClick();
          }
        }

        return (
            <HeaderStyle>
                <Box className="mainContainer">
                    <Box className='leftContainer'>
                        <Box className='logoContainer' >
                        <NavLink exact to="/LandingPage" activeClassName='active'><img className='imgLogo'  src={Logo} alt="Logo" /></NavLink>
                        </Box>
                        {
                          !isLargeScreen
                          ? <Box>
                            <IconButton
                              id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={this.handleClick}
                            >
                              <MenuIcon />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={this.handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              {
                                menuList.map((item) => {
                                  return (
                                    <MenuItem style={{padding: '10px 70px 10px 20px', marginBottom: "2px", backgroundColor: (item.isLogin || item.isSignup )? "#8CA74B" : "#fff"}}>
                                      {
                                        item.isSignup 
                                        ? <Typography style={{color: '#fff'}} onClick={() => handleMenuItemClick(item)}>Register</Typography>
                                        : <NavLink style={{textDecoration: 'none', color: (item.isLogin || item.isSignup ) ? '#fff' : '#334155'}} to={item.path} onClick={() => handleMenuItemClick(item)}>{item.title}</NavLink>
                                      }
                                    </MenuItem>
                                  )
                                })
                              }
                            </Menu>
                          </Box>
                         :
                        <Box className='menuContainer'>
                            <NavLink to="/PhotosPage" style={{marginLeft:'25px'}} className='MenuLink' activeClassName='active' onClick={()=>{getHeaderData("Family Photo Storage");sessionStorage.setItem('tab','Family Photo Storage')}}>Photos</NavLink>
                            <NavLink to="/MomentsPage" className='MenuLink' activeClassName='active' onClick={()=>{getHeaderData("Immortalizing Family Moments");sessionStorage.setItem('tab','Immortalizing Family Moments')}}>Moments</NavLink>
                            <NavLink to="/FamilyTreePage" className='MenuLink' activeClassName='active' onClick={()=>{getHeaderData("Family Tree");sessionStorage.setItem('tab','Family Tree')}}>Family Tree</NavLink>
                            <NavLink to="/RecipesPage" className='MenuLink' activeClassName='active' onClick={()=>{ getHeaderData("Family Recipe Preservation");sessionStorage.setItem('tab','Family Recipe Preservation')}}>Recipes</NavLink>
                            <NavLink to="/Subscriptionbilling" className='MenuLink' activeClassName='active'>Plans and Pricing</NavLink>
                            <NavLink to="/ContactusPage" className='MenuLink' activeClassName='active'>Contact Us</NavLink>
                        </Box>
                    }
                    </Box>
                    { isLargeScreen && (
                        <Box className='actionBtn'>
                            <Typography className='registerLink' onClick={() => {
                              let pathName = window.location.pathname === "/RecipesPage" ? "/RecipsPage" : window.location.pathname;
                              setStorageData("signUpCameFrom", pathName)
                              onRegisterClick();
                            }}>Register</Typography>
                            <NavLink to="/login" className='loginLink' onClick={onLoginClick}>
                                <Icon><PersonOutlineIcon /></Icon> Log in
                            </NavLink>
                        </Box>
                    )}
                </Box>
            </HeaderStyle>
        );
    }
}

const HeaderStyle = styled("div")({
    "& .mainContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px",
      borderBottom: "1px solid #E7E5E4",
      height: "70px",
      overflowX: "auto",
      "@media (max-width: 768px)": {
        flexDirection: "column",
        height: "auto", 
        alignItems: "flex-start",
      },
    },
    "& .leftContainer": {
      display: "flex",
      alignItems: "center",
      "@media (max-width: 900px)": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
    "& .logoContainer": {
      marginRight: "auto",
      "@media (max-width: 768px)": {
        marginRight: "0",
      },
    },
    "& .menuContainer": {
      display: "flex",
      alignItems: "center",
      "@media (max-width: 768px)": {
        flexWrap: "wrap", 
        justifyContent: "center",
        marginTop: "10px",
        width: "100%",
      },
      "@media (max-width: 486px)": {
        flexDirection: "column", 
        alignItems: "center",
      },
    },
    "& .MenuLink": {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "14px",
      color: "#44403C",
      margin: "10px",
      textDecoration: "none",
      "&.active": {
        color: "#8CA74B", 
      },
      "@media (max-width: 768px)": {
        margin: "5px",
        fontSize: "13px",
      },
      "@media (max-width: 486px)": {
        fontSize: "12px", 
        textAlign: "center",
      },
    },
    "& .imgLogo": {
      width: "80px",
      height: "41px",
      marginLeft: "25px",
      "@media (max-width: 486px)": {
        marginLeft: "10px",
      },
    },
    "& .actionBtn": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      marginRight: "20px",
      "@media (max-width: 768px)": {
        marginTop: "10px",
        gap: "10px",
        justifyContent: "center",
        width: "100%",
      },
      "@media (max-width: 486px)": {
        flexDirection: "column", 
        gap: "8px",
      },
    },
    "& .registerLink": {
      cursor: "pointer",
      color: "#8CA74B",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily: "Poppins",
      textDecoration: "none",
      "@media (max-width: 486px)": {
        fontSize: "12px",
      },
    },
    "& .loginLink": {
      backgroundColor: "#8CA74B",
      color: "white",
      height: "44px",
      width: "141px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      "@media (max-width: 486px)": {
        width: "120px", 
        height: "40px", 
        fontSize: "14px",
      },
    },
  });

export default Header;
