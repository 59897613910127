import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled } from "@material-ui/core";
import DOMPurify from 'dompurify';
import Header from "../../../components/src/Header.web";

const StyledBox = styled(Box)({

  "&.top-container": {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    maxWidth: "1635px",
    backgroundPosition: "center",
    height: "850px",
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 1475px)": {
      height: "fit-content",
    },
  },

  "&.secondbox": {
    "@media (max-width: 770px)": {
      marginLeft: "20px"
    },
  },

  "&.recipe-banner-container": {
    marginRight: "10px",
    "@media (max-width: 1200px)": {
      width: "100%"
    },
    "@media (max-width: 950px)": {
      display: "none"
    },
  },

  "&.recipe-banner-mobile-container" : {
    display: "none",
    "@media (max-width: 950px)": {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
      width: "70%"
    },
    "@media (max-width: 500px)": {
      width: "60%"
    },
  },
  "&.empty-image-recipe-container": {
    marginLeft: "15px",
    "@media (max-width: 1200px)": {
      height: "80%",
      width: "80%",
    },
    "@media (max-width: 800px)": {
      height: "70%",
      width: "70%",
    },
    "@media (max-width: 700px)": {
      height: "50%",
      width: "50%",
    },
  },

  "&.empty-image-recipe-container-odd": {
    width: "100%",
    marginRight: "15px",
    height: "100%",
    "@media (max-width: 1200px)": {
      display: "none"
    },
  },
"&.content-container-recipe": {
    display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'flex-start',
    "@media (max-width: 1200px)": {
      width: "90%",
      margin: "auto",
      flexDirection: "column",
      alignItems: 'center',
      textAlign: "center"
    },
  },
  "&.content-container-recipe-odd": {
    display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'flex-start',
    "@media (max-width: 1200px)": {
      width: "90%",
      margin: "auto",
      flexDirection: "column-reverse",
      textAlign: "center",
      alignItems: 'center',
    },
  },
});

const StyledTypography = styled(Typography)({

  "&.recipe-title": {
    fontSize: 72,
    fontWeight: 400,
    fontFamily: 'Abygaer',
    color: '#305328',
    marginTop: 130,
    "@media (max-width: 1200px)": {
      fontSize: 50,
      lineHeight: 1.2
    },
    "@media (max-width: 770px)": {
      fontSize: 40,
      lineHeight: 1.2,
      marginTop: 65
    },
    "@media (max-width: 500px)": {
      fontSize: 30,
      lineHeight: 1,
      marginTop: 50
    },
  },
  "&.headerTextsecond": {
    color: '#305328',
    fontWeight: 400,
    fontSize: 24,
    fontFamily: "Poppins",
    marginTop:20,
    "@media (max-width: 1200px)": {
      fontSize: 20,
      lineHeight: 1.2
    },
    "@media (max-width: 770px)": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "@media (max-width: 500px)": {
      fontSize: 16,
      lineHeight: 1.2
    },
  },
  "&.storage-title": {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    "& p": {
      marginBottom: "0px",
      marginTop: "0px",
    },
    "@media (max-width: 1200px)": {
      fontSize: 24,
      marginTop: "10px",
    },
    "@media (max-width: 800px)": {
      fontSize: 20,
      paddingRight: "5px",
    },
  },
  "&.storage-description": {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "Poppins",
    marginTop: 10,
    "& p": {
      "@media (max-width: 1200px)": {
        marginBottom: "0px",
        marginTop: "0px",
      },
    },
    "@media (max-width: 1200px)": {
      fontSize: 20,
    },
    "@media (max-width: 800px)": {
      fontSize: 18,
    },
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
  "&.familyTextRecipe": {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48,
    "@media (max-width: 1200px)": {
      fontSize: 34,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
     "@media (max-width: 770px)": {
      fontSize: 26,
      lineHeight: 1.2,
    },
  },
  "&.familyBottomTextRecipe": {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48,
    textAlign: "right",
    "@media (max-width: 1200px)": {
      fontSize: 34,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
     "@media (max-width: 770px)": {
      fontSize: 26,
      lineHeight: 1.2,
    },
  },
  "&.family-subtask": { 
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110", 
    "@media (max-width: 1200px)": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
    "@media (max-width: 770px)": {
      fontSize: 16,
      lineHeight: 1.2,
      width: "95%"
    },
  },
  "&.family-bottom-subtask": { 
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110", 
    textAlign: "right",
    "@media (max-width: 1200px)": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
    "@media (max-width: 770px)": {
      fontSize: 16,
      lineHeight: 1.2,
      width: "95%"
    },
  },
  "&.more-question-title": {
    fontWeight: 700,
    fontSize: 36,
    fontFamily: "Poppins",
    color: "#1C1917",
    "@media (max-width: 850px)": {
      fontSize: 25,
      lineHeight: 1.2,
      width: "95%"
    },
    "@media (max-width: 600px)": {
      fontSize: 20,
      lineHeight: 1.2,
      width: "95%"
    },
  },
  "&.more-question-subtitle": {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#5D6063",
    marginTop: 10,
    marginBottom: 70,
    "@media (max-width: 850px)": {
      fontSize: 16,
      lineHeight: 1.2,
      width: "95%"
    },
  }, 
  "&.frequentlyTextRecipe": {
    fontSize: 36,
    fontWeight: 700,
    color: '#1C1917',
    marginTop: 100,
    fontFamily: 'Poppins',
    "@media (max-width: 700px)": {
      fontSize: 24,
      lineHeight: 1.2,
    },
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
  },
  "&.simpleTextRecipe": {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063',
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
  "&.faqsAnswers": {
    fontFamily: 'Poppins',
    fontWeight: 400,
    flex: 1,
    fontSize: 16,
    color: '#5D6063',
  },
  "&.recipesQuestions": {
    fontFamily: 'Poppins', flex: 1, fontSize: 18, fontWeight: 700 ,
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
  "&.recipeFeatureTitle": {
    fontWeight: 700, fontSize: 44, fontFamily: 'Poppins', color: "#305328",
    "@media (max-width: 1200px)": {
      fontSize: 35,
    },
    "@media (max-width: 700px)": {
      fontSize: 24,
    },
    "@media (max-width: 550px)": {
      fontSize: 18,
       paddingRight: "5px"
    },
    "& p": {
      marginTop: "0px",
      marginBottom: "0px"
    }
  },
  "&.recipeFeatureSubTitle": {
    fontWeight: 400, fontFamily: "Poppins", fontSize: 20,
    "@media (max-width: 700px)": {
      fontSize: 16,
    },
    "@media (max-width: 550px)": {
      fontSize: 12,
      paddingRight: "5px"
    },
  },
  "&.bestFeatures": {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginTop: 30,
    fontSize: 48,
    "@media (max-width: 1200px)": {
      fontSize: 35,
    },
    "@media (max-width: 750px)": {
      fontSize: 24,
      paddingRight: "5px"
    },
    "@media (max-width: 550px)": {
      fontSize: 18,
      paddingRight: "5px"
    },
  },
  "&.bestFeaturesdescription": {
    color: "#5D6063",
    fontFamily: "Poppins",
    fontWeight: 400,
    width: "100%",
    fontSize: 20,
    textAlign: 'center',
    maxWidth: "900px",
    "@media (max-width: 700px)": {
      fontSize: 18,
    },
    "@media (max-width: 550px)": {
      fontSize: 14,
      paddingRight: "5px"
    },
  }
});

const StyledButton = styled(Button)({
  "&.get-started": {
    backgroundColor: "#8CA74B",
    height: '56px',
    borderRadius: '8px',
    width: '292px',
    marginTop: '52px',
    marginBottom: "15px",
    "@media (max-width: 1200px)": {
      marginTop: '52px',
    },
    "@media (max-width: 770px)": {
      marginTop: '30px',
    },
    "@media (max-width: 500px)": {
      marginTop: '15px',
      width: '175px',
      height: '40px',
    },
  }
})

const StyledRecipeImage = styled("img")({
  "&.empty-img-data": {
    maxWidth: "620px", maxHeight: "500px", zIndex: 1,
    width: "620px", height: "500px",
    "@media (max-width: 1200px)": {
      width: "100%",
      height: "100%",
      marginBottom: "20px"
    },
  },
})


// Customizable Area End
import MomentsPageController, {
  Props,
} from "./MomentsPageController";
import { recipeBanner, recipeBannerEmpty, recipeDesign2, recipeDesign4, downArrow, recipeDesign9, recipeDesign10, familyTreeBg, familyTreeDesign, familyTreeCircle } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class RecipsPage extends MomentsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFAQSection() {
    const { landingData } = this.state;

    return (
      <Container maxWidth="md" style={{ marginBottom: 40, marginTop: 20 }}>
        <div style={{ width: '100%', maxWidth: 920, margin: 'auto', marginTop: 50 }}>
          {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
            landingData[0].landing_page_faqs.map((faq: any, index: any) => (
              <Box
                key={faq.id}
                style={{
                  marginBottom: '16px',
                  borderRadius: '4px',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    padding: '12px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                  }}
                  onClick={() => this.handleAccordian(index)}
                  data-test-id={`coveracordian-${index + 1}`}
                >
                  <StyledTypography className="recipesQuestions"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question ) }}>
                  </StyledTypography>
                  <IconButton
                    aria-expanded={this.state.recipeFaq === index}
                    aria-label="show more"
                    style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                    size="small"
                  >
                    <img
                      src={downArrow}
                      style={{
                        transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
                {this.state.recipeFaq === index && (
                  <Box style={{ borderBottom: '1px solid #D6D3D1', padding: '12px' }}>
                    <StyledTypography
                      className="faqsAnswers"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer ) }}
                    >
                    </StyledTypography>
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <Typography>No FAQs available</Typography>
          )}
        </div>
      </Container>
    );
  }

  renderRecipeRightSection(workRecipeStorage: 
    { title: string | Node;
      description: string | Node;
      content_image_url: string;
    }
  ) {
    return (
      <>
        <Box style={{ paddingInline: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <StyledTypography className="storage-title"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(workRecipeStorage.title) }}>

          </StyledTypography>
          <StyledTypography className="storage-description"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(workRecipeStorage.description) }}>

          </StyledTypography>
        </Box>
        <StyledBox className="empty-image-recipe-container">
          <StyledRecipeImage className="empty-img-data" src={workRecipeStorage?.content_image_url ?? recipeBannerEmpty} />
        </StyledBox>
      </>
    )
  }

  renderRecipeStorageSection() {
    const { landingData } = this.state;

    return (
      <Box style={{ width: "100%",  marginInline: "auto", marginTop: 70,
        maxWidth: "1250px",
       }}
       data-test-id="workstorage-container">
      {landingData && landingData[0]?.work_storages?.length > 0 ? (
        landingData[0].work_storages.map((workStorage: any, index: any) => (
          <Box key={index} style={{ display: 'flex', position: 'relative', marginTop: index === 0 ? 0 : 70, marginBottom: index === landingData[0].work_storages?.length - 1 ? 70 : 0 }}>
            {index < 1 || (
              <img
                src={familyTreeCircle}
                style={{
                  width: "100%",
                  maxWidth: "204px",
                  height: 230,
                  position: 'absolute',
                  top: -120,
                  right: 518,
                }} />
            )}
            <StyledBox className={`${index % 2 === 0 ? "content-container-recipe" : "content-container-recipe-odd"}`}>
              {index % 2 === 0 ? (
                <>

                  <StyledBox className="empty-image-recipe-container">
                    <StyledRecipeImage className="empty-img-data" src={workStorage?.content_image_url ?? recipeBannerEmpty} />
                  </StyledBox>
                  <Box style={{ display: 'flex', flexDirection: 'column', paddingInline: 50, justifyContent: 'center' }}>
                    <StyledTypography
                      className="storage-title"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(workStorage.title) }}>

                    </StyledTypography>
                    <StyledTypography className="storage-description"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(workStorage.description) }}>

                    </StyledTypography>
                  </Box>
                </>
              ) : this.renderRecipeRightSection(workStorage)}
            </StyledBox>
          </Box>
        ))
      ) : (
        <Typography>No work storages available.</Typography>
      )}
    </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { landingData } = this.state;
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
          getHeaderData={(data) => {
            this.landingPageFilterAPi(data);
          }}
        />
        <StyledBox
          style={{
            backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
          }} className="top-container">
          <StyledBox style={webStyles.subHeaderBox} className="secondbox">
            <StyledTypography className="recipe-title prata"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.title) }}></StyledTypography>
            <StyledTypography className="headerTextsecond"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.description ) }}
            >
            </StyledTypography>
            <StyledButton className="get-started" data-test-id="btnGetStarted" type="submit" onClick={() => this.handleGetStarted("/RecipsPage")}>
              <Typography style={webStyles.continueTextRecipe as any}>Get started</Typography>
            </StyledButton>
          </StyledBox>
        </StyledBox>
        {this.renderRecipeStorageSection()}
        <RecipePageStyle>
          {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
            <Box className="priceBox" key={benefit.id}>
              <Typography className="priceTitle" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.title) }}></Typography>
              <Box className="priceCenterDiv">
                <Typography className="priceSubTitle" 
                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.description) }}
               />
              </Box>
              <Button className="startBtn" onClick={this.startNowBtn}>Start now</Button>
            </Box>
          )) || null}
        </RecipePageStyle>
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 100, position: "relative" }}>
          <Box style={webStyles.backgroundImageFeature as any}>
          <img src={familyTreeDesign} style={{ width: "100%", position: 'absolute', maxWidth: "160px", right: 0, top: -80, height: 270 }} />
            <img src={recipeDesign2} style={{ width: "100%", maxWidth: "115px", position: 'absolute', left: 0, top: 80, height: 122 }} />
            <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', right: 0, top: 420, height: 380 }} />

            <StyledTypography className="bestFeatures">The Best Features For Your Recipes</StyledTypography>
            <StyledTypography className="bestFeaturesdescription">MyKinship works as a recipe organizer and meal planner and a recipe management
              turning handwritten recipes into digital recipes for easier storage.</StyledTypography>

            <Box style={{ maxWidth: "950px", width: "90%", marginTop: 50 }}>
              {landingData[0]?.landing_page_features?.length ? (
                landingData[0].landing_page_features.map((feature: any, index: any) => (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: '#FFFFFF',
                      height: 180,
                      display: 'flex',
                      marginBottom: 65,
                      borderRadius: 8,
                      position: 'relative',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      width:'100%',
                      maxWidth:'950px'
                    }}
                  >
                    <img
                      src={this.getImageSource(index)}
                      data-test-id="imageElement"
                      style={{ width: 60, height: 60, display:'flex', alignSelf:'center', padding: 20 }}
                      alt={`Feature ${index}`}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth:'750px', width:"100%", paddingLeft:30 }}>
                      <StyledTypography className="recipeFeatureTitle"
                       dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feature.title) }}>
                      </StyledTypography>
                      <StyledTypography  className="recipeFeatureSubTitle"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feature.description) }}>
                      </StyledTypography>
                    </Box>

                    <img
                      src={recipeDesign9}
                      style={{
                        width: "100%",
                        maxWidth:"160px",
                        height: 220,
                        top: 130,
                        position: 'absolute',
                        zIndex: "-1",
                        [index % 2 === 0 ? 'right' : 'left']: -20,
                      }}
                      alt="Design 9"
                    />
                  </Box>
                ))
              ) : (
                <Typography>No features available</Typography>
              )}
            </Box>


          </Box>

        </Box>
        <Box style={{marginLeft:50}}>
        <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
          <StyledTypography className="frequentlyTextRecipe">Frequently asked questions</StyledTypography>
          <StyledTypography className="simpleTextRecipe" >Simple, transparent pricing that grows with you. Try any plan free for 30 days.</StyledTypography>
        </Box>
        {this.renderFAQSection()}
        <Box style={{ position: 'relative' }}>
          <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
            <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', height: 420, right: 0, top: -80, zIndex: -1 }} />
            <StyledTypography className="more-question-title">More Questions about Saving Family Recipes?</StyledTypography>
            <StyledTypography className="more-question-subtitle">Sign up today to immortalize your family recipes. If you have any additional questions about preserving family recipes and immortalizing memories, please don’t hesitate to contact MyKinship.</StyledTypography>
          </Box>
        </Box>
        </Box>
        <Footer navigation={this.props.navigation} id={""} />

      </Box>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    width: "32rem",
    gap: "2rem"
  },
  continueTextRecipe: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  familyText2: {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 48,
    textAlign: 'right',
    width:"100%",
    maxWidth:"625px"
  },
  contentTextRecipe: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110"
  },
  textContainerRecipe: {
    width: "100%",
    maxWidth: "656px",
    marginLeft: "20px",
    marginRight: "20px"
  },
  backgroundImageFeature: {
    backgroundImage: `url(${familyTreeBg})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '1300px',
    display: 'flex',
    backgroundSize: 'cover',
    flexDirection: "column",
    alignItems: 'center',
  },
  backgroundImage: {
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${recipeBanner})`,
    backgroundSize: 'cover',
    width: '100%',
    maxWidth: "1635px",
    height: '614px',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: "end",
  },
  subHeaderBox:
  {
    width: "100%",
    maxWidth: "666px",
    marginRight:50
  },
  faqQuestionsRecipe: {
    flex: 1,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700
  },
  workStorageBox:
    { display: "flex", gap: 80, justifyContent: 'center', marginTop: 150 },
};

const RecipePageStyle = styled("div")({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 60,
  gap: "50px",
  width: "90%",
  marginInline: "auto",
  flexWrap: "wrap",
  "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem",
      gap: "20px", 
  },
  "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem",
      gap: "20px", 
  },
  "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem",
      gap: "20px", 
  },
  
  "& .priceMainBox": {
    justifyContent: 'space-between',
    display: 'flex',
    width: '94%',
  },
  "& .priceBox": {
    borderRadius: '16px',
    width: '370',
    height: '380',
    boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
      width: '100%', 
  },
  "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
      width: '100%',
  },
  "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
      width: '100%',
  },
  },
  "& .priceTitle": {
    lineHeight: "44px",
    fontWeight: 700,
    fontSize: "36px",
    fontFamily: 'Poppins',
    marginTop: '40px',
    color: '#1C2110',
    letterSpacing: '-1%',
    marginBottom: '5px',
    textAlign: 'center'
  },
  "& .priceSubTitle": {
      fontWeight: 400,
      backgroundColor: 'transparent !important',
      fontSize: "18px",
      boxSizing: "border-box",
      fontFamily: 'Poppins',
      color: '#1C2110',
      padding: "18px 22px 18px 22px",
      textAlign: 'center',
      lineHeight: "28px",
  },
  "& .priceSubTitle span": {
      backgroundColor: "transparent !important",
  },
  "& .priceCenterDiv": {
        height: '140px',
        display: "flex",
        textAlign: 'center',
        backgroundColor: "#E8EDDB",
        alignItems: "center",
        marginTop: 40
  },

  "& .priceCenterBox": {
    backgroundColor: "#557C29",
    height: '140px'
  },
  "& .priceTag": {
    lineHeight: "56px",
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: "64px",
    textAlign: 'center',
    letterSpacing: '-1.5%',
    color: '#FFFFFF',
    padding: '13px',
  },
  "& .dollar": {
    lineHeight: "56px",
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: "32px",
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    color: '#FFFFFF',
    marginBottom: '20px',
    fontFamily: 'Poppins',
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: '40px',
    fontFamily: 'Poppins',
    color: '#1C2110',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    marginTop: '40px',
    marginBottom: '48px',
    padding: '10px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    textTransform:"none",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#FFFFFF',
    }
  }
})

const TypographyBox = styled(Typography)({
})


// Customizable Area End
