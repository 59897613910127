import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import {toast} from "react-hot-toast";

type Nullable<T> = T | null;
interface PrivacyObject {
  consent_to: string;
  access_level: Nullable<string>;
  consent_for: string;
  status?: string;
  id?: number;
  account_id?: number;
  created_by?: null;
  updated_by?: Nullable<string>;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
}


interface AccountAttributes {
  two_factor_enabled: boolean;
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface ToggleResponse  {
  data: AccountData;
}

export type responseJson = {
  message: string;
  privacy_setting: PrivacyObject[];
  errors?: string;
  meta: {
    token: string;
    refresh_token: string;
    id: number;
  };
}  &  {data: {attributes: {two_factor_enabled: boolean}}} & ToggleResponse;
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: NavigationProp<ParamListBase>;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  postChecked: boolean;
  videoChecked: boolean;
  photoChecked: boolean;
  postConsent: Array<PrivacyObject>;
  photoConsent: Array<PrivacyObject>;
  videoConsent: Array<PrivacyObject>;
  token: string;
  accountID: string;
  noData: boolean;
  isUpdating: boolean;
  updatedSettings: Array<PrivacyObject>;
  isTwoFaEnabled: boolean;
  isConfirmationModalOpen: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacySettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId: string = "";

  createPrivacySettingsCallId: string = "";
  toggleTwoFaStatusId: string = "";
  getPrivacySettingDataId: string = "";
  getAccountDetailsId: string = "";
  updatePrivacySettingsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      postChecked: false,
      videoChecked: false,
      photoChecked: false,
      postConsent: [],
      photoConsent: [],
      videoConsent: [],
      token: "",
      accountID: "",
      noData: false,
      isUpdating: false,
      updatedSettings: [],
      isTwoFaEnabled: true,
      isConfirmationModalOpen: false,
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.setState({
      isLoading: false
    })

    if (message.id === getName(MessageEnum.SessionResponseMessage)) {
      this.handleLoginResponse(message);
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    await this.handleApiResponse(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  // Customizable Area Start

  async handleApiResponse(
    apiRequestCallId: string,
    responseJson: responseJson,
  ) {
    switch (apiRequestCallId) {
      case this.createPrivacySettingsCallId:
        await this.handleCreatePrivacySettingsResponse(responseJson);
        break;
      case this.getPrivacySettingDataId:
        await this.handleGetPrivacySettingsResponse(responseJson);
        break;
      case this.updatePrivacySettingsCallId:
        await this.handleUpdatePrivacySettingsResponse(responseJson);
        break;
      case this.getAccountDetailsId:
         this.handleAccountResponse(responseJson);
        break;

      case this.toggleTwoFaStatusId:
          this.handleTwoFaResponse(responseJson);
          break;
      default:
        break;
    }
  }

  async handleCreatePrivacySettingsResponse(responseJson: responseJson) {
    if (responseJson && !responseJson.errors) {
      this.showAlert(
        `${configJSON.createdSuccessfullyText}`,
        `${configJSON.emptyText}`,
      );
      this.saveConsents(responseJson.privacy_setting);
    } else {
      this.showAlert(
        `${configJSON.uploadFailedText}`,
        `${configJSON.retryText}`,
      );
    }
  }

  handleAccountResponse = (responseJson: responseJson) => {
    this.setState({
      isTwoFaEnabled: responseJson?.data?.attributes?.two_factor_enabled
    })
  }

  handleTwoFaResponse = (responseJson: ToggleResponse) => {
    const feedbackMsg = responseJson?.data?.attributes?.two_factor_enabled ? "Two factor authentication enabled successfully." : 
                        "Two factor authentication disabled succesfully."

    toast.success(feedbackMsg);
  }

  handleLoginResponse(message: Message) {
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);

    if (token) {
      this.setState({ token }, () => this.getPrivacySettingsData());
    }
  }

  async handleGetPrivacySettingsResponse(responseJson: responseJson) {
    if (responseJson && responseJson.privacy_setting) {
      this.saveConsents(responseJson.privacy_setting);
    } else {
      await this.setStateAsync({ noData: true });
      this.showAlert(
        responseJson.message,
        `${configJSON.endPointIssueMessage}`,
      );
    }
  }

  async handleUpdatePrivacySettingsResponse(responseJson: responseJson) {
    if (responseJson && responseJson.privacy_setting) {
      this.saveConsents(responseJson.privacy_setting);
      this.showAlert(responseJson.message, "");
      await this.setStateAsync({ isUpdating: false });
    } else {
      this.showAlert(responseJson.message, "");
    }
  }

  async setStateAsync(state: {}) {
    return new Promise<void>((resolve) => {
      this.setState(state, () => resolve());
    });
  }

  saveConsents = (data: Array<PrivacyObject>) => {
    const photoConsent = data.filter(
      (consent: PrivacyObject) => consent.consent_for === "photos",
    );
    const videoConsent = data.filter(
      (consent: PrivacyObject) => consent.consent_for === "videos",
    );
    const postConsent = data.filter(
      (consent: PrivacyObject) => consent.consent_for === "posts",
    );

    photoConsent &&
      photoConsent.length > 0 &&
      this.setState({ photoChecked: true });
    videoConsent &&
      videoConsent.length > 0 &&
      this.setState({ videoChecked: true });
    postConsent &&
      postConsent.length > 0 &&
      this.setState({ postChecked: true });

    this.setState({
      postConsent,
      videoConsent,
      photoConsent,
      noData: false,
    });
  };

  async componentDidMount() {
    super.componentDidMount();
    this.login();
    this.getAccountDetails();
  }

  login = () => {
    const requestMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(requestMessage);
  };

  getPrivacySettingsData = () => {
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getPrivacySettingDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserPrivacySettings,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiGETMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createPrivacySettings = () => {
    const body = {
      data: {
        account_id: this.state.accountID,
        data: [
          ...this.state.photoConsent,
          ...this.state.postConsent,
          ...this.state.videoConsent,
        ],
      },
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createPrivacySettingsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPrivacySettings,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPOSTMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePrivacySettings = () => {
    const body = {
      data: {
        data: this.state.updatedSettings,
      },
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updatePrivacySettingsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePrivacySettings,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPUTMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createButtonButtonProps = {
    onPress: () =>
      this.state.photoChecked &&
      this.state.videoChecked &&
      this.state.postChecked
        ? this.createPrivacySettings()
        : this.showAlert(configJSON.allDataErrorMessage, configJSON.emptyText),
  };

  updateButtonButtonProps = {
    onPress: () =>
      this.state.isUpdating
        ? this.updatePrivacySettings()
        : this.setState({ isUpdating: true }),
  };

  createButtonButtonPropsWeb = () =>
    this.state.photoChecked && this.state.videoChecked && this.state.postChecked
      ? this.createPrivacySettings()
      : this.showAlert(configJSON.allDataErrorMessage, configJSON.emptyText);

  updateButtonButtonPropsWeb = () =>
    this.state.isUpdating
      ? this.updatePrivacySettings()
      : this.setState({ isUpdating: true });

  postConsentPickerProps = (index: number) => (itemValue: string) => {
    const postArray: Array<PrivacyObject> = this.state.postConsent;
    if (
      postArray.filter((item: PrivacyObject) => item.consent_to === itemValue)
        .length === 0
    ) {
      postArray[index].consent_to = itemValue;
      this.setState({ postConsent: postArray }, () => {
        this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.updatedSettings.concat(postArray[index]),
          }));
      });
    } else {
      this.showAlert(
        `${itemValue} ${configJSON.alreadySelectedText}`,
        configJSON.emptyText,
      );
    }
  };

  postLevelPickerProps = (index: number) => (itemValue: string) => {
    const postArray: Array<PrivacyObject> = this.state.postConsent;
    postArray[index].access_level = itemValue;

    this.setState(
      { postConsent: postArray },
      () =>
        this.state.isUpdating &&
        this.setState((prevState: S) => ({
          updatedSettings: prevState.updatedSettings.concat(postArray[index]),
        })),
    );
  };

  postLevelPickerPropsWeb =
    (index: number) => (itemValue: React.ChangeEvent<HTMLSelectElement>) => {
      const postArray: Array<PrivacyObject> = this.state.postConsent;
      postArray[index].access_level = itemValue.target.value;
      this.setState(
        { postConsent: postArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.updatedSettings.concat(postArray[index]),
          })),
      );
    };

  imageConsentPickerProps = (index: number) => (itemValue: string) => {
    const photoArray: Array<PrivacyObject> = this.state.photoConsent;
    if (
      photoArray.filter((item: PrivacyObject) => item.consent_to === itemValue)
        .length === 0
    ) {
      photoArray[index].consent_to = itemValue;
      this.setState(
        { photoConsent: photoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.photoConsent.concat(photoArray[index]),
          })),
      );
    } else {
      this.showAlert(
        `${itemValue} ${configJSON.alreadySelectedText}`,
        configJSON.emptyText,
      );
    }
  };

  postConsentPickerPropsWeb =
    (index: number) => (itemValue: React.ChangeEvent<HTMLSelectElement>) => {
      const postArray: Array<PrivacyObject> = this.state.postConsent;
      if (
        postArray.filter(
          (item: PrivacyObject) => item.consent_to === itemValue.target.value,
        ).length === 0
      ) {
        postArray[index].consent_to = itemValue.target.value;
        this.setState({ postConsent: postArray }, () => {
          this.state.isUpdating &&
            this.setState((prevState: S) => ({
              updatedSettings: prevState.updatedSettings.concat(
                postArray[index],
              ),
            }));
        });
      } else {
        this.showAlert(
          `${itemValue.target.value} ${configJSON.alreadySelectedText}`,
          configJSON.emptyText,
        );
      }
    };

  imageConsentPickerPropsWeb =
    (index: number) => (itemValue: React.ChangeEvent<HTMLSelectElement>) => {
      const photoArray: Array<PrivacyObject> = this.state.photoConsent;
      if (
        photoArray.filter(
          (item: PrivacyObject) => item.consent_to === itemValue.target.value,
        ).length === 0
      ) {
        photoArray[index].consent_to = itemValue.target.value;
        this.setState(
          { photoConsent: photoArray },
          () =>
            this.state.isUpdating &&
            this.setState((prevState: S) => ({
              updatedSettings: prevState.photoConsent.concat(photoArray[index]),
            })),
        );
      } else {
        this.showAlert(
          `${itemValue.target.value} ${configJSON.alreadySelectedText}`,
          configJSON.emptyText,
        );
      }
    };

  imageLevelPickerProps = (index: number) => (itemValue: string) => {
    const photoArray: Array<PrivacyObject> = this.state.photoConsent;
    photoArray[index].access_level = itemValue;
    this.setState(
      { photoConsent: photoArray },
      () =>
        this.state.isUpdating &&
        this.setState((prevState: S) => ({
          updatedSettings: prevState.photoConsent.concat(photoArray[index]),
        })),
    );
  };

  imageLevelPickerPropsWeb =
    (index: number) => (itemValue: React.ChangeEvent<HTMLSelectElement>) => {
      const photoArray: Array<PrivacyObject> = this.state.photoConsent;
      photoArray[index].access_level = itemValue.target.value;
      this.setState(
        { photoConsent: photoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.photoConsent.concat(photoArray[index]),
          })),
      );
    };

  videoConsentPickerProps = (index: number) => (itemValue: string) => {
    const videoArray: Array<PrivacyObject> = this.state.videoConsent;
    if (
      videoArray.filter((item: PrivacyObject) => item.consent_to === itemValue)
        .length === 0
    ) {
      videoArray[index].consent_to = itemValue;
      this.setState(
        { videoConsent: videoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.videoConsent.concat(videoArray[index]),
          })),
      );
    } else {
      this.showAlert(
        `${itemValue} ${configJSON.alreadySelectedText}`,
        configJSON.emptyText,
      );
    }
  };

  videoConsentPickerPropsWeb =
    (index: number) => (itemValue: React.ChangeEvent<HTMLSelectElement>) => {
      const videoArray: Array<PrivacyObject> = this.state.videoConsent;
      if (
        videoArray.filter(
          (item: PrivacyObject) => item.consent_to === itemValue.target.value,
        ).length === 0
      ) {
        videoArray[index].consent_to = itemValue.target.value;
        this.setState(
          { videoConsent: videoArray },
          () =>
            this.state.isUpdating &&
            this.setState((prevState: S) => ({
              updatedSettings: prevState.videoConsent.concat(videoArray[index]),
            })),
        );
      } else {
        this.showAlert(
          `${itemValue.target.value} ${configJSON.alreadySelectedText}`,
          configJSON.emptyText,
        );
      }
    };

  videoLevelPickerProps = (index: number) => (itemValue: string) => {
    const videoArray: Array<PrivacyObject> = this.state.videoConsent;
    videoArray[index].access_level = itemValue;
    this.setState(
      { videoConsent: videoArray },
      () =>
        this.state.isUpdating &&
        this.setState((prevState: S) => ({
          updatedSettings: prevState.videoConsent.concat(videoArray[index]),
        })),
    );
  };

  videoLevelPickerPropsWeb =
    (index: number) => (itemValue: React.ChangeEvent<HTMLSelectElement>) => {
      const videoArray: Array<PrivacyObject> = this.state.videoConsent;
      videoArray[index].access_level = itemValue.target.value;
      this.setState(
        { videoConsent: videoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.videoConsent.concat(videoArray[index]),
          })),
      );
    };

  videoSwitchProps = {
    onValueChange: (value: boolean) =>
      this.state.noData
        ? this.setState({
            videoChecked: value,
            videoConsent: [
              {
                consent_to: "friends",
                access_level: null,
                consent_for: "videos",
                status: "granted",
              },
              {
                consent_to: "family",
                access_level: null,
                consent_for: "videos",
                status: "granted",
              },
              {
                consent_to: "anyone",
                access_level: null,
                consent_for: "videos",
                status: "granted",
              },
            ],
          })
        : this.setState({
            videoChecked: value,
          }),
  };

  imageSwitchProps = {
    onValueChange: (value: boolean) =>
      this.state.noData
        ? this.setState({
            photoChecked: value,
            photoConsent: [
              {
                consent_to: "friends",
                access_level: null,
                consent_for: "photos",
                status: "granted",
              },
              {
                consent_to: "family",
                access_level: null,
                consent_for: "photos",
                status: "granted",
              },
              {
                consent_to: "anyone",
                access_level: null,
                consent_for: "photos",
                status: "granted",
              },
            ],
          })
        : this.setState({
            photoChecked: value,
          }),
  };

  postSwitchProps = {
    onValueChange: (value: boolean) =>
      this.state.noData
        ? this.setState({
            postChecked: value,
            postConsent: [
              {
                consent_to: "friends",
                access_level: null,
                consent_for: "posts",
                status: "granted",
              },
              {
                consent_to: "family",
                access_level: null,
                consent_for: "posts",
                status: "granted",
              },
              {
                consent_to: "anyone",
                access_level: null,
                consent_for: "posts",
                status: "granted",
              },
            ],
          })
        : this.setState({
            postChecked: value,
          }),
  };

  postSwitchWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.noData
      ? this.setState({
          postChecked: event?.target?.checked,
          postConsent: [
            {
              consent_to: "friends",
              access_level: null,
              consent_for: "posts",
              status: "granted",
            },
            {
              consent_to: "family",
              access_level: null,
              consent_for: "posts",
              status: "granted",
            },
            {
              consent_to: "anyone",
              access_level: null,
              consent_for: "posts",
              status: "granted",
            },
          ],
        })
      : this.setState({
          postChecked: event.target.checked,
        });
  };

  imageSwitchWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.noData
      ? this.setState({
          photoChecked: event?.target?.checked,
          photoConsent: [
            {
              consent_to: "friends",
              access_level: null,
              consent_for: "photos",
              status: "granted",
            },
            {
              consent_to: "family",
              access_level: null,
              consent_for: "photos",
              status: "granted",
            },
            {
              consent_to: "anyone",
              access_level: null,
              consent_for: "photos",
              status: "granted",
            },
          ],
        })
      : this.setState({
          photoChecked: event.target.checked,
        });
  };

  videoSwitchWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.noData
      ? this.setState({
          videoChecked: event?.target?.checked,
          videoConsent: [
            {
              consent_to: "friends",
              access_level: null,
              consent_for: "videos",
              status: "granted",
            },
            {
              consent_to: "family",
              access_level: null,
              consent_for: "videos",
              status: "granted",
            },
            {
              consent_to: "anyone",
              access_level: null,
              consent_for: "videos",
              status: "granted",
            },
          ],
        })
      : this.setState({
          videoChecked: event.target.checked,
        });
  };

  handleCloseConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false
    })
  }

  callApi = (payload: any, runEngine: any) => {
    const { contentType, method, endPoint, body, headers } = payload;
    const header = {
      "Content-Type": contentType,
      ...headers,
    };
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return requestMsg.messageId;
  };

  getAccountDetails = async () => {
    const authToken = await getStorageData("token");
    this.getAccountDetailsId = this.callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiGETMethodType,
        endPoint: `${configJSON.getAccountDetailsEndpoint as string}`,
        headers: { token: authToken },
      },
      runEngine
    );
  }

  navigationTo = (navigateTo: string) => {

    const navMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      navigateTo
    );
    navMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navMessage);

  }

  handleTwofaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = event.target;
    if(checked) {
      this.toggleTwoFa(true)
    }
    else {
      this.setState({
        isConfirmationModalOpen: true
      })
    }
  }

  toggleTwoFa = async (toggle: boolean) => {
    this.setState({
      isLoading: true
    })
    const authToken = await getStorageData("token");
    this.setState({
      isTwoFaEnabled: toggle
    })
    this.toggleTwoFaStatusId = this.callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiPUTMethodType,
        endPoint: `${configJSON.toggleTwoFaEndpoint as string}?two_factor_enabled=${toggle}`,
        headers: { token: authToken },
      },
      runEngine
    );
    
  }

  // Customizable Area End
}
