import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    IconButton,
    Divider,
    Paper,
    InputBase,
    Modal,
    Menu,
    MenuItem,
    ListItemIcon,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import DashboardController, { Props } from "./DashboardController.web";
import CloseIcon from '@mui/icons-material/Close';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import SideDrawer from "../../../components/src/SideDrawer";
import Header from "../../../components/src/Header.web";
import { avatar, categoryIcon, deleteIcon, dropDownIcon, folderOpenImage, groupImg, imageavtarone, openFolder, people1, people2, photoExpandImg, reloadIcon, threeDotsIcon, trashIconDelete, trashcan, tree, videoBtn } from "./assets";
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StarIcon from '@material-ui/icons/Star';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from "@mui/icons-material/Search";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

// Customizable Area End

export default class TrashFile extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getHeader=()=>{
        return(
        <Box className="Header">
        {
            this.state.authToken ? (
                <HeaderDropdown
                    data-test-id="headerComponenttestid"
                    navigation={this.props.navigation}
                    id={""}
                />
            ) : (
                <Header onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => { }} />
            )
        }
    </Box>
        )
    }
    getPopupMessage=()=>
        {
            return(
        <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '490', marginTop: 20, flexDirection: 'column', alignItems: 'center' }}>
        {this.getSelectedCount() === 1 && (
            <Typography style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', textAlign: 'center' }}>
                Are you sure you want to delete this file?
            </Typography>
        )}
        {this.getSelectedCount() > 1 && this.getSelectedCount() < this.state.folders.length && (
            <Typography style={{  fontFamily: 'Poppins', textAlign: 'center', fontWeight: 700, color: '#1C2110',fontSize: 20 }}>
                Are you sure you want to delete these files?
            </Typography>
        )}
        {this.getSelectedCount() === this.state.folders.length && (
            <Typography style={{ fontFamily: 'Poppins',fontSize: 20,  fontWeight: 700,textAlign: 'center', color: '#1C2110', }}>
                Are you sure you want to delete all files?
            </Typography>
        )}
        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 400, marginTop: 20 }}>
            This files will be permanently deleted
        </Typography>
    </Box>
            )
    }
    getReceipesExpanded=()=>{
        return(
            <Box>
                                    {this.state.isRecipesExpanded && (
                                        <>
                                            <Box style={{ display: 'flex', gap: '20px' }}>
                                                <Box className="momentExpandBox">
                                                    <Checkbox style={{ color: "#557C29", marginTop: '8px', marginLeft: '15px' }} />
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                        <IconButton><img src={tree} /></IconButton>
                                                        <IconButton><MoreVertIcon style={{ color: '#DADADA' }} /></IconButton>
                                                    </Box>
                                                    <Box style={{ marginLeft: '18px' }}>
                                                        <Typography className="birthdayText">kate family tree</Typography>
                                                        <Typography className="birthdayDesText">Modified by Kate, 10 Mar</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', padding: '20px', justifyContent: 'space-between' }}>
                                                        <Typography className="birthdayDigitText">50 members</Typography>
                                                        <Box>
                                                            <img src={avatar} style={{ width: '26px', height: '26px' }} />
                                                            <img src={avatar} style={{ height: '26px', width: '26px', marginLeft: '-15px' }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                                <Button style={{ fontWeight: 700, fontFamily: "Poppins", color: '#557C29', fontSize: "14px", textTransform: 'none' }}>view all</Button>
                                                <Box style={{ alignItems: 'center', display: 'flex' }}>
                                                    <Typography style={{ color: "#557C29", margin: '0 10px' }}> {this.state.page} of {this.state.totalPages} </Typography>
                                                    <Box className="recipesBox">
                                                        <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 1)}  >
                                                            <KeyboardArrowLeftIcon />
                                                        </Button>
                                                        <Divider flexItem orientation="vertical" />
                                                        <Button onClick={() => this.handlePageChange(null, this.state.page + 1)} disabled={this.state.page === this.state.totalPages}>
                                                            <KeyboardArrowRightIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    </Box>
        )
        
    }
    getGroupExpanded=()=>{
        return(
<Box>
                                    {this.state.isGroupsExpanded && (
                                        <>
                                            <Box className="groupExpandBox">
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Checkbox style={{ color: "#557C29" }} />
                                                    <Box>
                                                        <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                        <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                    </Box>
                                                </Box>
                                                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '19px' }}>
                                                    <img src={groupImg} />
                                                </Box>
                                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: '#1C1917', }}>My Photos</Typography>
                                                    <Button style={{ backgroundColor: '#8CA74B', borderRadius: '8px', height: '24px' }}><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                                </Box>

                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                                <Button style={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "14px", color: '#557C29', textTransform: 'none' }}>view all</Button>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                        {this.state.page} of {this.state.totalPages}
                                                    </Typography>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Button
                                                            onClick={() => this.handlePageChange(null, this.state.page - 1)}
                                                            disabled={this.state.page === 1} >
                                                            <KeyboardArrowLeftIcon />
                                                        </Button>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Button
                                                            onClick={() => this.handlePageChange(null, this.state.page + 1)}
                                                            disabled={this.state.page === this.state.totalPages} >
                                                            <KeyboardArrowRightIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    </Box>
        )
    }
    getMediaExpended=()=>{
        return(
                                   <Box>
                                    {this.state.isPhotoExpanded && (
                                        <>
                                            <Box style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                                {this.state.photosdata.map((photoItem: any, index: number) => (
                                                    <Box key={photoItem.id} className="photoExpandBox" style={{
                                                        backgroundImage: `url(${photoItem.media_url})`,
                                                        backgroundRepeat: 'round',
                                                        width: '15%',
                                                        height: '230px'
                                                    }}>
                                                        {console.log("response 686", JSON.stringify(this.state.photosdata))}
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                            <Checkbox
                                                                style={{ color: "#557C29" }}
                                                                checked={photoItem.isChecked || false}
                                                                data-test-id="handlePhotoCheckBox"
                                                                onChange={() => this.handlePhotoCheckboxChange(index)}
                                                            />


                                                            <IconButton onClick={(event) => this.handleMenuOpen(event)}>
                                                                <MoreVertIcon style={{ color: 'white' }} />
                                                            </IconButton>

                                                            <Menu
                                                                anchorEl={this.state.anchorEl}
                                                                keepMounted
                                                                open={Boolean(this.state.anchorEl)}
                                                                onClose={this.handleMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        borderRadius: "10px",
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem onClick={this.handleMenuClose}>
                                                                    <ListItemIcon style={{ minWidth: '30px' }}>
                                                                        <InfoIcon fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit" style={{ marginLeft: '6px' }}>
                                                                        Info
                                                                    </Typography>
                                                                </MenuItem>


                                                                <MenuItem onClick={this.handleMenuClose}>
                                                                    <ListItemIcon style={{ minWidth: '30px' }}>
                                                                        <BlockIcon fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit" style={{ marginLeft: '6px' }}>Unshare</Typography>
                                                                </MenuItem>

                                                                <MenuItem onClick={this.handleMenuClose}>
                                                                    <ListItemIcon style={{ minWidth: '30px' }}>
                                                                        <DeleteIcon fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit" style={{ marginLeft: '6px' }}>Delete</Typography>
                                                                </MenuItem>
                                                            </Menu>
                                                        </Box>

                                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '90px', padding: '22px' }}>
                                                            <Box>
                                                                <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                                <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                            </Box>
                                                            <Box style={{ display: 'flex' }}>
                                                                <ChatBubbleOutlineIcon style={{ color: 'white' }} />
                                                                <Typography style={{ color: 'white', marginLeft: '1px' }}>{photoItem.chatCount}</Typography>
                                                                <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} />
                                                                <Typography style={{ color: 'white' }}>{photoItem.likeCount}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>

                                            <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                                <Button style={{ textTransform: 'none', fontFamily: "Poppins", color: '#557C29', fontWeight: 700, fontSize: "14px" }}>view all</Button>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                        {this.state.page} of {this.state.totalPages}
                                                    </Typography>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 2)}><KeyboardArrowLeftIcon /></Button>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)}><KeyboardArrowRightIcon /></Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    </Box>
        )
    }
    getPeopleExpended=()=>{
return(
    <Box>
                                    {this.state.isPeopleExpanded && (
                                        <>
                                            <Box style={{ display: 'flex', gap: '30px', marginBottom: '10px' }}>
                                                <Box >
                                                    <img src={people1} />
                                                    <Box className="peopleNameBox"> <Checkbox style={{ color: "#557C29" }} /><Typography>Kate</Typography></Box>
                                                </Box>
                                                <Box >
                                                    <img src={people2} />
                                                    <Box className="peopleNameBox"> <Checkbox style={{ color: "#557C29" }} /><Typography>Sarah</Typography></Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                                <Button style={{ fontFamily: "Poppins", fontSize: "14px", color: '#557C29', fontWeight: 700, textTransform: 'none' }}>view all</Button>
                                                <Box style={{ alignItems: 'center', display: 'flex', }}>
                                                    <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                        {this.state.page} of {this.state.totalPages}
                                                    </Typography>
                                                    <Box className="mediaBox">
                                                        <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 1)}  >
                                                            <KeyboardArrowLeftIcon />
                                                        </Button>
                                                        <Divider />
                                                        <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)} >
                                                            <KeyboardArrowRightIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    </Box>
)
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SharingDashboardStyle>
                <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Trash"/>
                <Box className="MainContent">
                     {this.getHeader()}
                    <Box className="Content">
                        {this.state.photosdata ?
                            <Box>
                                <Typography className="sharingTitle">My Trash</Typography>
                                <Box className="imageView">
                                    <img src={trashcan} className="imageStyle" />
                                    <Typography className="welcomeText">Welcome to your trash</Typography>
                                    <Typography className="DescText1">
                                        • No items have been deleted yet
                                    </Typography>
                                    <Typography className="DescText">
                                        • You will see all items that have been deleted in the last 30 days
                                    </Typography>
                                    <Typography className="DescText">
                                        • After 30 days all items remaining in the trash will be permanently deleted
                                    </Typography>
                                </Box>
                            </Box> :
                            <Box>
                               <Box className="alertBox">
                                    <Typography className="alertText">Items in your trash will be permanently deleted in <strong style={{ color: '#D97706' }}>30 days</strong> if no action is taken. Restore or delete them permanently before the deadline.</Typography>
                                    <CloseIcon style={{ width: 16, height: 16, color: '#D97706' }} />
                               </Box>
                                <Typography className="sharingTitle">My Trash</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className="selectAllText">Select all</Typography>
                                        <Checkbox color="success"
                                            checked={this.state.isAllSelected}
                                            data-test-id="coverSelectHandle"
                                            onChange={this.handleSelectAll}

                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    borderRadius: '6px',
                                                },
                                            }} />
                                        <Box
                                            style={{
                                                height: '40px',
                                                width: '2px',
                                                backgroundColor: '#D6D3D1',
                                                margin: '0 10px',
                                            }}
                                        />

                                        <div className="dropdownContainer">
                                            <img
                                                src={dropDownIcon}
                                                data-test-id="toggleDropdown"
                                                onClick={this.toggleDropdown}
                                                style={{
                                                    width: 10,
                                                    height: 5,
                                                    cursor: 'pointer',
                                                    top: '50px',
                                                    right: '100px',
                                                    zIndex: 2,
                                                }}
                                                alt="dropdown"
                                            />
                                            {this.state.showDropdown && (
                                                <div ref={this.dropdownRef} className="recoverDeleteDiv" style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    left: 0,
                                                    backgroundColor: '#fff',
                                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                                    zIndex: 1,
                                                    marginTop: 10
                                                }}>
                                                    <div className="btnStyles" onClick={this.openRecoverModal} data-test-id="recovermodalBtn">
                                                        <img src={reloadIcon} style={{ width: 20, height: 20 }} alt="reload" />
                                                        <Typography className="recoverDelete">Recover all</Typography>
                                                    </div>
                                                    <div style={{ padding: '5px 0', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={this.openModal} data-test-id="deleteBtn">
                                                        <img src={deleteIcon} style={{ width: 20, height: 20 }} alt="delete" />
                                                        <Typography className="recoverDelete">Delete all</Typography>
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <Modal
                                                    open={this.state.showDeleteModal}
                                                    onClose={this.closeModal}
                                                    data-test-id="closemodalBtn"
                                                    aria-describedby="delete-method"
                                                    aria-labelledby="delete-method-modal"
                                                >
                                                    <Box
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            maxWidth: 500,
                                                            transform: 'translate(-50%, -50%)',
                                                            width: '100%',
                                                            backgroundColor: '#fff',
                                                            padding: 30,
                                                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                            borderBottomRightRadius: 50,
                                                        }}
                                                    >
                                                        <Box>
                                                            <Box style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                                                                <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>
                                                                    Delete photo
                                                                </Typography>
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={this.closeModal}
                                                                    data-tes-id="handleCloseSimulate"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: 0,
                                                                        top: 20,
                                                                        color: '#666',
                                                                    }}
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <Divider style={{ width: 'calc(100% + 90px)', margin: '10px -44px' }} />
                                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
                                                            <div
                                                                style={{
                                                                    width: "130px",
                                                                    display: "flex",
                                                                    height: "130px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "#8CA74B",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    alignSelf: 'center',
                                                                }}
                                                            >
                                                                <img
                                                                    src={trashIconDelete}
                                                                    alt="Centered"
                                                                    style={{ width: 85 }}
                                                                    className="image"
                                                                />
                                                            </div>
                                                        </Box>

                                                        {this.getPopupMessage()}
                                                        <Divider style={{ margin: '10px -44px', marginTop: 30, width: 'calc(100% + 90px)' }} />

                                                        <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20, gap: 30 }}>
                                                            <Button
                                                                style={{
                                                                    backgroundColor: "#F8FFE7",
                                                                    borderRadius: '8px',
                                                                    width: '233px',
                                                                    marginTop: '20px',
                                                                    height: '56px',
                                                                    border: "1px solid #C2E176"
                                                                }}
                                                                onClick={this.closeModal}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        fontFamily: "Poppins",
                                                                        color: "#8CA74B",
                                                                        fontWeight: 700,
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        textTransform: 'none'
                                                                    }}
                                                                >
                                                                    Keep it
                                                                </Typography>
                                                            </Button>

                                                            <Button
                                                                style={{
                                                                    backgroundColor: "#8CA74B",
                                                                    borderRadius: '8px',
                                                                    height: '56px',
                                                                    width: '233px',
                                                                    marginTop: '20px'
                                                                }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        color: "#FFFFFF",
                                                                        textTransform: 'none',
                                                                    }}
                                                                    onClick={this.handleDeleteAll}
                                                                    data-test-id="deleteButtonTestId"
                                                                >
                                                                    Yes, delete it
                                                                </Typography>
                                                            </Button>

                                                        </Box>
                                                    </Box>
                                                </Modal>
                                            </div>

                                            <div>
                                                <Modal
                                                    open={this.state.showRecoverModal}
                                                    onClose={this.closeRecoverModal}
                                                    data-test-id="closerecoverModalBtn"
                                                    aria-describedby="delete-method"
                                                    aria-labelledby="delete-method-modal"
                                                >
                                                    <Box
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            maxWidth: 500,
                                                            transform: 'translate(-50%, -50%)',
                                                            width: '100%',
                                                            backgroundColor: '#fff',
                                                            padding: 30,
                                                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                            borderBottomRightRadius: 50,
                                                        }}
                                                    >
                                                        <div>
                                                            <Modal
                                                                open={this.state.showRecoverModal}
                                                                onClose={this.closeRecoverModal}
                                                                data-test-id="closerecoverModalBtn"
                                                                aria-describedby="delete-method"
                                                                aria-labelledby="delete-method-modal"
                                                            >
                                                                <Box
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        maxWidth: 500,
                                                                        transform: 'translate(-50%, -50%)',
                                                                        width: '100%',
                                                                        backgroundColor: '#fff',
                                                                        padding: 30,
                                                                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                                        borderBottomRightRadius: 50
                                                                    }}
                                                                >
                                                                    <Box>

                                                                        <Box style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                                                                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Recover all</Typography>

                                                                            <IconButton
                                                                                aria-label="close"
                                                                                onClick={this.closeRecoverModal}
                                                                                data-tes-id="handleCloseSimulate"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    right: 0,
                                                                                    color: '#666',
                                                                                    top: 20,
                                                                                }}
                                                                            >
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>

                                                                    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '490px', marginTop: 20, flexDirection: 'column' }}>
                                                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 400 }}>
                                                                            {this.areAllFilesSelected()
                                                                                ? "All files will be recovered to their place of origin."
                                                                                : (
                                                                                    <>
                                                                                        This file will be restored and can be found in the <strong style={{ fontWeight: 700 }}>Photos Dashboard</strong>.
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </Typography>
                                                                    </Box>

                                                                    <Divider style={{
                                                                        width: 'calc(100% + 90px)',
                                                                        margin: '10px -44px',
                                                                        marginTop: 20
                                                                    }} />


                                                                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 30, gap: 20 }}>
                                                                        <Button style={{
                                                                            borderRadius: '8px',
                                                                            height: '56px',
                                                                            width: '152px',
                                                                            backgroundColor: "#F8FFE7",
                                                                            marginTop: '20px',
                                                                            border: "1px solid #C2E176"
                                                                        }} onClick={this.closeModal}>
                                                                            <Typography style={{
                                                                                fontFamily: "Poppins",
                                                                                fontSize: "16px",
                                                                                fontWeight: 700,
                                                                                lineHeight: "24px",
                                                                                color: "#8CA74B",
                                                                                textTransform: 'none'
                                                                            } as any}>Cancel</Typography>
                                                                        </Button>

                                                                        <Button style={{
                                                                            backgroundColor: "#8CA74B",
                                                                            borderRadius: '8px',
                                                                            width: '152px',
                                                                            height: '56px',
                                                                            marginTop: '20px'
                                                                        }} onClick={this.handleRecover} data-test-id="buttonTestTryagainRecover">
                                                                            <Typography style={{
                                                                                fontWeight: 700,
                                                                                fontSize: "16px",
                                                                                fontFamily: "Poppins",
                                                                                lineHeight: "24px",
                                                                                color: "#FFFFFF",
                                                                                textTransform: 'none'
                                                                            } as any}>Recover</Typography>

                                                                        </Button>

                                                                    </Box>

                                                                </Box>
                                                            </Modal>
                                                        </div>

                                                        <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20, gap: 30 }}>
                                                            <Button style={{
                                                                borderRadius: '8px',
                                                                backgroundColor: "#F8FFE7",
                                                                width: '152px',
                                                                height: '56px',
                                                                marginTop: '20px'
                                                            }} onClick={this.closeModal}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    lineHeight: "24px",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    color: "#8CA74B",
                                                                    textTransform: 'none'
                                                                } as any}>Cancel</Typography>
                                                            </Button>

                                                            <Button style={{
                                                                backgroundColor: "#8CA74B",
                                                                borderRadius: '8px',
                                                                width: '152px',
                                                                height: '56px',
                                                                marginTop: '20px'
                                                            }} onClick={this.handleRecover} data-test-id="buttonTestTryagainRecover">
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    fontWeight: 700,
                                                                    color: "#FFFFFF",
                                                                    textTransform: 'none'
                                                                } as any} >Recover</Typography>

                                                            </Button>

                                                        </Box>

                                                    </Box>
                                                </Modal>
                                            </div>
                                        </div>
                                    </Box>
                                    <Box className="selectedContentBox">
                                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography className="selectedContent">Select content</Typography>
                                            <Box style={{ display: 'flex', gap: "20px", marginTop: 5 }}>
                                                <Typography className="oneTextStyle">
                                                    {this.getSelectedCount() === this.state.folders.length ? 'all' : this.getSelectedCount()}
                                                </Typography>
                                                <Typography className="selectedText">selected</Typography>
                                            </Box>
                                        </Box>

                                        <Box className="secondDiv">
                                            <Button className="deleteBtn" data-test-id="getMediaType" onClick={this.openModal}>
                                                <DeleteOutlineOutlinedIcon style={{ color: '#fff' }} />
                                                <Typography className="deleteBtnText">Delete</Typography>
                                            </Button>
                                            <Button className="createBtn">
                                                <Typography className="createBtnText">Cancel</Typography>
                                            </Button>
                                        </Box>
                                    </Box>

                                </Box>

                                <Box>
                                    <Typography className="deletedFoldertext">Deleted folders</Typography>
                                    <Box style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                                        {this.state.folders.map((folder, index) => (
                                            <Box className="deleteModalBox" key={index}>
                                                <Box className="boxesStyle">
                                                    <Checkbox
                                                        color="success"
                                                        checked={folder.isChecked || false}
                                                        data-test-id="handleFolderCheckBox"
                                                        onChange={() => this.handleFolderCheckboxChange(index)}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                borderRadius: '6px',
                                                            },
                                                        }}
                                                    />
                                                    <img src={threeDotsIcon} style={{ width: 24, height: 24 }} />
                                                </Box>
                                                <Box style={{ marginLeft: 20 }}>
                                                    <img src={folderOpenImage} style={{ width: 48, height: 48 }} />
                                                    <Typography className="birthdaymiamiText">{folder.name}</Typography>
                                                    <Box style={{ display: "flex", alignItems: 'center', gap: '55px' }}>
                                                        <Typography className="createdAtText">Created by me, {folder.createdDate}</Typography>
                                                        <img src={imageavtarone} style={{ width: 24, height: 24 }} />
                                                    </Box>
                                                    <Typography className="filesText">{folder.filesCount} files</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>

                                <Box style={{ marginTop: '30px' }}>
                                    <Typography className="filterText">Filter by</Typography>
                                    <Box className="filterMainBox">
                                        <Box className="filterSubBox">
                                            <Box className="filterBox">
                                                <StarIcon style={{ width: 15, height: 15, marginTop: 2 }} /> <Typography className="filterBoxText" style={{ marginLeft: '10px' }}> Favorites</Typography>
                                            </Box>
                                            <Button className="filterBox" >
                                                <Typography className="filterBoxText">Media Type</Typography>
                                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                                            </Button>
                                            <Button className="filterBox">
                                                <Typography className="filterBoxText">Date</Typography>
                                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                                            </Button>
                                            <Button className="filterBox">
                                                <Typography className="filterBoxText">Things</Typography>
                                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                                            </Button>
                                            <Button className="filterBox">
                                                <Typography className="filterBoxText">People</Typography>
                                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                                            </Button>
                                            <Button className="filterBox">
                                                <Typography className="filterBoxText">Location</Typography>
                                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                                            </Button>
                                            <Divider orientation="vertical" flexItem style={{ height: 40, marginTop: 10 }} />
                                            <Box style={{ marginLeft: '15px' }} className="filterBox">
                                                <img src={categoryIcon} />
                                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                                            </Box>
                                        </Box>
                                        <div className="filterBox" style={{ position: "relative", marginRight: 18 }}>
                                            <div
                                                className="filterBoxText"
                                                style={{
                                                    backgroundColor: "white",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    border: 'none',
                                                    justifyContent: "space-between",
                                                    cursor: "pointer",
                                                }}
                                                data-test-id="toggleDropdownTestId"
                                                onClick={this.toggleDropdownOpen}
                                            >
                                                {this.state.selectedOption}
                                                <IconButton size="small">
                                                    <KeyboardArrowDownIcon />
                                                </IconButton>
                                            </div>

                                            {this.state.isOpen && (
                                                <div
                                                    className="dropdownMenu"
                                                    style={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: 0,
                                                        width: "192px",
                                                        height: "130px",
                                                        borderRadius: "8px",
                                                        border: "1px solid #ddd",
                                                        backgroundColor: "white",
                                                        zIndex: 10,
                                                        overflow: "hidden",
                                                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                                    }}
                                                >
                                                    <div
                                                        className="dropdownOption"
                                                        data-test-id="selectOptions"
                                                        style={{ padding: "10px", cursor: "pointer", fontSize: 14, fontWeight: 400, fontFamily: 'Poppins', color: '#0F172A' }}
                                                        onClick={() => this.handleSelect("Most recent")}
                                                    >
                                                        Most recent
                                                    </div>
                                                    <div
                                                        className="dropdownOption"
                                                        data-test-id="selectOptions"
                                                        style={{ padding: "10px", cursor: "pointer", fontSize: 14, fontWeight: 400, fontFamily: 'Poppins', color: '#0F172A' }}
                                                        onClick={() => this.handleSelect("Sort by A-Z")}
                                                    >
                                                        Sort by A-Z
                                                    </div>
                                                    <div
                                                        className="dropdownOption"
                                                        data-test-id="selectOptions"
                                                        style={{ padding: "10px", cursor: "pointer", fontSize: 14, fontWeight: 400, fontFamily: 'Poppins', color: '#0F172A' }}
                                                        onClick={() => this.handleSelect("Sort by Z-A")}
                                                    >
                                                        Sort by Z-A
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </Box>
                                </Box>
                                <Box style={{ width: '97%', display: "flex", justifyContent: "left", marginTop: '20px' }}>
                                    <Paper
                                        style={{
                                            width: '100%',
                                            border: '1px solid #D6D3D1',
                                            borderRadius: '40px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '0 10px', 
                                        }}
                                    >
                                        <IconButton
                                            type="button"
                                            style={{ padding: "10px", color: "#A8A29E" }}
                                            aria-label="search"
                                        >
                                            <SearchIcon />
                                        </IconButton>

                                        <InputBase
                                            data-test-id="inputBoxId"
                                            style={{
                                                marginLeft: 1,
                                                color: "#A8A29E",
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                flex: 1,
                                            }}
                                            placeholder="Search"
                                        />

                                        <IconButton
                                            type="button"
                                            style={{
                                                padding: "10px",
                                                color: "#A8A29E",
                                                marginLeft: 'auto', // Automatically pushes to the right
                                            }}
                                            aria-label="search"
                                        >
                                            <PhotoCameraOutlinedIcon />
                                        </IconButton>
                                    </Paper>
                                </Box>

                                <Box style={{ marginTop: '40px', marginBottom: '40px' }}>
                                    <Button className="expandBoxs" data-test-id="handleGroupExpand" onClick={this.handleGroupExpand}>
                                        <Typography className="expandBtnText">Groups</Typography>
                                        <IconButton>{this.state.isGroupsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                    </Button>
                                    {this.getGroupExpanded()}
                                    <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                    <Button className="expandBoxs" data-test-id="handlePhotoExpand" onClick={this.handlePhotoExpand}>
                                        <Typography className="expandBtnText">Media</Typography>
                                        <IconButton>{this.state.isPhotoExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                    </Button>
                                    {this.getMediaExpended()}
                                    <Divider style={{ marginBottom: '5px', marginTop: '5px' }} />
                                    <Button className="expandBoxs" data-test-id="handlePeopleExpand" onClick={this.handlePeopleExpand}>
                                        <Typography className="expandBtnText">People</Typography>
                                        <IconButton><KeyboardArrowDownIcon /></IconButton>
                                    </Button>
                                    {this.getPeopleExpended()}
                                    <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                    <Button className="expandBoxs" data-test-id="handleMemoriesExpand" onClick={this.handleMemoriesExpand}>
                                        <Typography className="expandBtnText">Moments</Typography>
                                        <IconButton><KeyboardArrowDownIcon /></IconButton>
                                    </Button>
                                    {this.state.isMemoriesExpanded && (
                                        <>
                                            <Box style={{ gap: '20px', display: 'flex' }}>
                                                <Box className="momentExpandBox">
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                        <Checkbox style={{ color: "#557C29" }} />
                                                        <IconButton><MoreVertIcon style={{ color: '#DADADA' }} /></IconButton>
                                                    </Box>
                                                    <Box style={{ padding: '20px' }}>
                                                        <img src={videoBtn} />
                                                        <Typography className="birthdayText">Birthday</Typography>
                                                        <Typography className="birthdayDesText">Created by me, 10 Mar</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                                                        <Typography className="birthdayDigitText">20 files</Typography>
                                                        <Box>
                                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                            <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className="momentExpandBox">
                                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                                                        <Checkbox style={{ color: "#557C29" }} />
                                                        <IconButton><MoreVertIcon style={{ color: '#DADADA' }} /></IconButton>
                                                    </Box>
                                                    <Box style={{ padding: '20px' }}>
                                                        <img src={videoBtn} />
                                                        <Typography className="birthdayText">Anniversary</Typography>
                                                        <Typography className="birthdayDesText">Created by me, 20 Feb</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', }}>
                                                        <Typography className="birthdayDigitText">10 files</Typography>
                                                        <Box>
                                                            <img src={avatar} style={{ width: '26px', height: '26px' }} />
                                                            <img src={avatar} style={{ marginLeft: '-15px', width: '26px', height: '26px' }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px' }}>
                                                <Button style={{ fontFamily: "Poppins", fontWeight: 700, textTransform: 'none', fontSize: "14px", color: '#557C29', }}>view all</Button>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography style={{ margin: '0 10px', color: "#557C29" }}> {this.state.page} of {this.state.totalPages} </Typography>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 1)} > <KeyboardArrowLeftIcon /> </Button>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)}> <KeyboardArrowRightIcon />   </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}

                                    <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                    <Button className="expandBoxs" data-test-id="familyTreeExpandBtn" onClick={this.handleFamilyTreeExpand}>
                                        <Typography className="expandBtnText">Family Tree</Typography>
                                        <IconButton><KeyboardArrowDownIcon /></IconButton>
                                    </Button>
                                    {this.state.isFamilyTreeExpanded && (
                                        <>
                                            <Box style={{ display: 'flex', gap: '20px' }}>
                                                <Box className="momentExpandBox">
                                                    <Checkbox style={{ color: "#557C29", marginTop: '8px', marginLeft: '15px' }} />
                                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                                                        <IconButton><img src={tree} /></IconButton>
                                                        <IconButton><MoreVertIcon style={{ color: '#DADADA' }} /></IconButton>
                                                    </Box>
                                                    <Box style={{ marginLeft: '18px' }}>
                                                        <Typography className="birthdayText">kate family tree</Typography>
                                                        <Typography className="birthdayDesText">Modified by Kate, 10 Mar</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', }}>
                                                        <Typography className="birthdayDigitText">50 members</Typography>
                                                        <Box>
                                                            <img src={avatar} style={{ width: '26px', height: '26px' }} />
                                                            <img src={avatar} style={{ height: '26px', marginLeft: '-15px', width: '26px' }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', marginBottom: '20px' }}>
                                                <Button style={{ fontWeight: 700, fontFamily: "Poppins", color: '#557C29', fontSize: "14px", textTransform: 'none' }}>view all</Button>
                                                <Box style={{ alignItems: 'center', display: 'flex' }}>
                                                    <Typography style={{ margin: '0 10px', color: "#557C29" }}> {this.state.page} of {this.state.totalPages} </Typography>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Button onClick={() => this.handlePageChange(null, this.state.page - 1)} disabled={this.state.page === 1}  >
                                                            <KeyboardArrowLeftIcon />
                                                        </Button>
                                                        <Divider />
                                                        <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)} >
                                                            <KeyboardArrowRightIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                    <Button className="expandBoxs" data-test-id="recipeExpands" onClick={this.handleRecipesExpand}>
                                        <Typography className="expandBtnText">Recipes</Typography>
                                        <IconButton><KeyboardArrowDownIcon /></IconButton>
                                    </Button>
                                    {this.getReceipesExpanded()}
                                </Box>

                            </Box>
                        }


                    </Box>
                </Box>
            </SharingDashboardStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const SharingDashboardStyle = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    "& .MainContent": {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    "& .Content": {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginLeft: '20px',
        // maxWidth: "1180px",
        width: "95%"
    },
    "& .sharingTitle": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: '-0.5%'
    },
    "& .imageView": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
    "& .imageStyle": {
        width: "240px",
        height: "208px"
    },
    "& .welcomeText": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        fontWeight: 700,
        marginTop: 20
    },
    "& .DescText": {
        fontFamily: 'Poppins',
        fontSize: "14px",
        fontWeight: "400px",
        color: "#1C2110"
    },
    "& .DescText1": {
        fontFamily: 'Poppins',
        fontSize: "14px",
        fontWeight: "400px",
        color: "#1C2110",
        marginTop: 20
    },
    "& .selectAllText": {
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "Poppins",
        color: "#557C29"
    },
    "& .recoverDelete": {
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: 14,
        color: "#1C2110",
        marginLeft: 20
    },
    "& .recoverDeleteDiv": {
        backgroundColor: 'white',
        border: '1px solid #ddd',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        left: 0,
        zIndex: 1000,
        width: "162px",
        borderRadius: 8,
        transform: 'translateY(-10%)'
    },
    "& .deletedFoldertext": {
        fontFamily: "Poppins",
        fontWeight: "700",
        fontSize: "18px",
        color: "#1C2110",
        marginTop: 20
    },
    "& .deleteModalBox": {
        width: 282,
        height: 208,
        borderRadius: 12,
        border: '1px solid #D6D3D1',
        padding: 2,
        boxSizing: 'border-box',
        marginTop: 20
    },
    "& .secondDiv": {
        display: 'flex',
        gap: '15px',
        flexWrap: "wrap"
    },
    "& .createBtn": {
        height: '56px',
        borderRadius: '8px',
        padding: '4px',
        width: '162px',
        marginTop: '20px',
        color: "#FFFFFF",
        backgroundColor: "#F8FFE7",
        border: "1px solid #557C29"
    },
    '& .createBtnText': {
        marginLeft: '8px',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: "none",
        color: "#557C29",

    },
    "& .deleteBtn": {
        height: '56px',
        borderRadius: '8px',
        padding: '4px',
        width: '162px',
        marginTop: '20px',
        color: "#FFFFFF",
        border: "1px solid #557C29",
        backgroundColor: "#557C29",
    },
    '& .deleteBtnText': {
        marginLeft: '8px',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        color: "#FFFFFF",
        textTransform: "none",
    },
    "& .oneTextStyle": {
        backgroundColor: '#557C29',
        borderRadius: 4,
        color: '#fff',
        width: "42px",
        fontSize: 20,
        fontFamily: "Poppins",
        fontWeight: "700",
        display: 'flex',
        justifyContent: 'center'
    },
    "& .btnStyles": {
        padding: '5px 0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    "& .selectedContent": {
        fontFamily: "Poppins",
        fontWeight: "700",
        fontSize: "20px",
        color: "#1C2110",
        marginTop: 15
    },
    "& .selectedText": {
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "18px",
        color: "#1C2110"
    },
    "& .selectedContentBox": {
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        gap: "20px"
    },
    "& .birthdaymiamiText": {
        fontFamily: "Poppins",
        fontWeight: "700",
        fontSize: "16px",
        color: "#5D6063"
    },
    "& .createdAtText": {
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "14px",
        color: "#5D6063"
    },
    "& .filesText": {
        fontFamily: "Poppins",
        fontWeight: "700",
        fontSize: "12px",
        color: "#A8A29E",
        marginTop: 10
    },
    "& .boxesStyle": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12
    },
    "& .filterMainBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .filterText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: "#1C2110",
    },
    "& .filterSubBox": {
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: "start"
    },
    "& .filterBox": {
        display: 'flex',
        alignItems: 'center',
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        textTransform: "none"
    },
    "& .filterBoxText": {
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        textTransform: "none",
    },
    "& .expandBoxs": {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#1C2110',
        width: '100%',
        padding: '10px'
    },
    "& .expandBtnText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: '#1C2110',
        textTransform: "none"
    },
    "& .groupExpandBox": {
        padding: '20px',
        width: '200px',
        borderRadius: '8px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
    },
    "& .photoExpandBox": {
        backgroundImage: `url(${photoExpandImg})`,
        backgroundRepeat: 'no-repeat',
        width: '15%',
        height: '230px'
    },
    "& .peopleNameBox": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F8FFE7',
        borderRadius: '8px',
        padding: '10px',
        marginTop: '10px'
    },
    "& .momentExpandBox": {
        border: '1px solid #D6D3D1',
        borderRadius: '12px',
        width: '280px'
    },
    "& .dropdownContainer": {
        position: "relative",
        marginLeft: 20
    },
    "& .alertBox": {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FEF3C7',
        width: '96%',
        borderRadius: 4,
        padding: '15px 25px 15px 20px',
        borderLeft: '4px solid #D97706',
        alignItems: 'center',
        marginBottom: 40,
    },

    "& .alertText": {
        fontFamily: 'Poppins',
        fontWeight: "400",
        fontSize: 14,
        color: '#D97706'
    },
    "& .mediaBox": {
        display: "flex"
    },
    "& .recipesBox": {
        display: 'flex'
    }


});
// Customizable Area End
