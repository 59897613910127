import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled, Card, CardContent } from "@material-ui/core";
import DOMPurify from 'dompurify'; 
import Header from "../../../components/src/Header.web";


const StyledImg = styled("img")({
  "&.empty-img-data": {
    maxWidth: "620px", maxHeight: "500px", zIndex: 1,
    width: "620px", height: "500px",
    "@media (max-width: 1200px)": {
      width: "100%",
      height: "100%",
      marginBottom: "20px"
    },
  },
})



const StyledBox = styled(Box)({

    "&.top-container": {
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      maxWidth: "1635px",
      backgroundPosition: "center",
      height: "850px",
      display: "flex",
      justifyContent: "end",
      "@media (max-width: 1475px)": {
        height: "fit-content",
      },
    },
    "&.background-image-feature": {
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: 'auto',
      display: 'flex',
      backgroundSize: 'cover',
      flexDirection: "column",
      alignItems: 'center',
    },

  "&.page-description-left": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 50,
    marginRight: 0,
    textAlign: "left",
    width: "95%",
    "@media (max-width: 1200px)": {
      textAlign: "center",
      marginLeft: 0,
    },
  },
  "&.page-description-right": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 0,
    marginRight: 50 ,
    textAlign: "right",
    width: "95%",
    "@media (max-width: 1200px)": {
      textAlign: "center",
      marginRight: 0 ,
    },
  },

  "&.imageStoreParent": {
    flexDirection: "row",
    "@media (max-width: 1200px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "&.imageStoreParentReverse": {
    flexDirection: "row-reverse",
    "@media (max-width: 1200px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  "&.empty-image-container-odd": {
    width: "100%",
    height: "100%",
    marginRight: "15px",
    "@media (max-width: 1200px)": {
      display: "none"
    },
  },

  "&.empty-image-container": {
    marginLeft: "15px",
    "@media (max-width: 1200px)": {
      height: "80%",
      width: "80%",
    },
    "@media (max-width: 800px)": {
      height: "70%",
      width: "70%",
    },
    "@media (max-width: 700px)": {
      height: "50%",
      width: "50%",
    },
  },
  "&.content-container-odd": {
    display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'flex-start',
    "@media (max-width: 1200px)": {
      width: "90%",
      margin: "auto",
      flexDirection: "column-reverse",
      alignItems: 'center',
      textAlign: "center"
    },
  },

  "&.recipe-banner-container": {
    marginRight: "10px",
    "@media (max-width: 1200px)": {
      width: "100%"
    },
    "@media (max-width: 950px)": {
      display: "none"
    },
  },

  "&.content-container": {
    display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'flex-start',
    "@media (max-width: 1200px)": {
      width: "90%",
      margin: "auto",
      flexDirection: "column",
      alignItems: 'center',
      textAlign: "center"
    },
  },
  "&.store-container": {
    "@media (max-width: 1250px)": {
      width: "90%",
      margin: "auto",
    },
  },
  
    "&.secondbox": {
      "@media (max-width: 770px)": {
        marginLeft: "20px"
      },
    },
  
    "&.recipe-banner-mobile-container" : {
      display: "none",
      "@media (max-width: 950px)": {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        width: "70%"
      },
      "@media (max-width: 500px)": {
        width: "60%"
      },
    }
  });
  const StyledTypography = styled(Typography)({
  
    "&.recipe-title": {
      fontSize: 72,
      fontWeight: 400,
      fontFamily: 'Abygaer',
      color: '#305328',
      marginTop: 130,
      "@media (max-width: 1200px)": {
        fontSize: 50,
        lineHeight: 1.2
      },
      "@media (max-width: 770px)": {
        fontSize: 40,
        lineHeight: 1.2,
        marginTop: 65
      },
      "@media (max-width: 500px)": {
        fontSize: 30,
        lineHeight: 1,
        marginTop: 50
      },
    },
  "&.storage-description": {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "Poppins",
    marginTop: 10,
    "& p": {
      "@media (max-width: 1200px)": {
        marginBottom: "0px",
        marginTop: "0px",
      },
    },
    "@media (max-width: 1200px)": {
      fontSize: 20,
    },
    "@media (max-width: 800px)": {
      fontSize: 18,
    },
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
    "&.card-title": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48,
    color: "#305328",
    textAlign: "center",
    "@media (max-width: 1200px)": {
      fontSize: 35,
    },
    "@media (max-width: 800px)": {
      fontSize: 24,
      paddingRight: "5px",
    },
  },
    "&.headerTextsecond": {
      color: '#305328',
      fontWeight: 400,
      fontSize: 24,
      fontFamily: "Poppins",
      marginTop:20,
      "@media (max-width: 1200px)": {
        fontSize: 20,
        lineHeight: 1.2
      },
      "@media (max-width: 770px)": {
        fontSize: 18,
        lineHeight: 1.2
      },
      "@media (max-width: 500px)": {
        fontSize: 16,
        lineHeight: 1.2
      },
    },
    "&.familyTextRecipe": {
      color: "#305328",
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: 48,
      "@media (max-width: 1200px)": {
        fontSize: 34,
        lineHeight: 1.2
      },
      "@media (max-width: 950px)": {
        textAlign: "left"
      },
       "@media (max-width: 770px)": {
        fontSize: 26,
        lineHeight: 1.2,
      },
    },
    "&.familyBottomTextRecipe": {
      color: "#305328",
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: 48,
      textAlign: "right",
      "@media (max-width: 1200px)": {
        fontSize: 34,
        lineHeight: 1.2
      },
      "@media (max-width: 950px)": {
        textAlign: "left"
      },
       "@media (max-width: 770px)": {
        fontSize: 26,
        lineHeight: 1.2,
      },
    },
    "&.storage-moment-title": {
      fontWeight: 700,
      fontFamily: "Poppins",
      lineHeight: 1.2,
      fontSize: 36,
      "& p": {
        marginBottom: "0px",
        marginTop: "0px",
      },
      "@media (max-width: 1200px)": {
        fontSize: 24,
        marginTop: "10px",
      },
      "@media (max-width: 800px)": {
        paddingRight: "5px",
        fontSize: 20,
      },
    },
    "&.family-subtask": { 
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: 20,
      color: "#1C2110", 
      "@media (max-width: 1200px)": {
        fontSize: 18,
        lineHeight: 1.2
      },
      "@media (max-width: 950px)": {
        textAlign: "left"
      },
      "@media (max-width: 770px)": {
        fontSize: 16,
        lineHeight: 1.2,
        width: "95%"
      },
    },
    "&.family-bottom-subtask": { 
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: 20,
      color: "#1C2110", 
      textAlign: "right",
      "@media (max-width: 1200px)": {
        fontSize: 18,
        lineHeight: 1.2
      },
      "@media (max-width: 950px)": {
        textAlign: "left"
      },
      "@media (max-width: 770px)": {
        fontSize: 16,
        lineHeight: 1.2,
        width: "95%"
      },
    },
    "&.more-question-title": {
      fontWeight: 700,
      fontSize: 36,
      fontFamily: "Poppins",
      color: "#1C1917",
      "@media (max-width: 850px)": {
        fontSize: 25,
        lineHeight: 1.2,
        width: "95%"
      },
      "@media (max-width: 600px)": {
        fontSize: 20,
        lineHeight: 1.2,
        width: "95%"
      },
    },
    "&.more-question-subtitle": {
      fontWeight: 400,
      fontSize: 18,
      fontFamily: "Poppins",
      color: "#5D6063",
      marginTop: 10,
      marginBottom: 70,
      "@media (max-width: 850px)": {
        fontSize: 16,
        lineHeight: 1.2,
        width: "95%"
      },
    }, 
    "&.frequentlyTextRecipe": {
      fontSize: 36,
      fontWeight: 700,
      color: '#1C1917',
      marginTop: 100,
      fontFamily: 'Poppins',
      "@media (max-width: 700px)": {
        fontSize: 24,
        lineHeight: 1.2,
        marginTop: 50,
      },
      "@media (max-width: 500px)": {
        fontSize: 20,
        marginTop: 35,
      },
    },
    "&.simpleTextRecipe": {
      fontSize: 18,
      fontFamily: 'Poppins',
      fontWeight: 400,
      color: '#5D6063',
      "@media (max-width: 500px)": {
        fontSize: 16,
      },
    },
    "&.faqsAnswers": {
      fontFamily: 'Poppins',
      fontWeight: 400,
      flex: 1,
      fontSize: 16,
      color: '#5D6063',
    },
    "&.recipesQuestions": {
      fontFamily: 'Poppins', flex: 1, fontSize: 18, fontWeight: 700 ,
      "@media (max-width: 500px)": {
        fontSize: 16,
      },
    },
    "&.recipeFeatureTitle": {
      fontWeight: 700, fontSize: 44, fontFamily: 'Poppins', color: "#305328",
      "@media (max-width: 1200px)": {
        fontSize: 35,
      },
      "@media (max-width: 700px)": {
        fontSize: 24,
      },
      "@media (max-width: 550px)": {
        fontSize: 18,
         paddingRight: "5px"
      },
      "& p": {
        marginTop: "0px",
        marginBottom: "0px"
      }
    },
    "&.recipeFeatureSubTitle": {
      fontWeight: 400, fontFamily: "Poppins", fontSize: 20,
      "@media (max-width: 700px)": {
        fontSize: 16,
      },
      "@media (max-width: 550px)": {
        fontSize: 12,
        paddingRight: "5px"
      },
    },
    "&.bestFeatures": {
      color: "#305328",
      fontFamily: "Poppins",
      fontWeight: 700,
      marginTop: 30,
      fontSize: 48,
      textAlign: 'center',
      "@media (max-width: 1200px)": {
        fontSize: 35,
      },
      "@media (max-width: 750px)": {
        fontSize: 24,
        paddingRight: "5px"
      },
      "@media (max-width: 550px)": {
        fontSize: 18,
        paddingRight: "5px"
      },
    },
    "&.bestFeaturesdescription": {
      color: "#5D6063",
      fontFamily: "Poppins",
      fontWeight: 400,
      width: "100%",
      fontSize: 20,
      textAlign: 'center',
      maxWidth: "900px",
      "@media (max-width: 700px)": {
        fontSize: 18,
      },
      "@media (max-width: 550px)": {
        fontSize: 14,
        paddingRight: "5px",
      },
    },
    "&.card-subtitle": {
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: 20,
      color: "1C2110",
      marginTop: 10,
      "@media (max-width: 700px)": {
        fontSize: 18,
      },
      "@media (max-width: 550px)": {
        fontSize: 15,
        paddingRight: "5px",
      },
    },
  });
  
  const StyledButton = styled(Button)({
    "&.get-started": {
      backgroundColor: "#8CA74B",
      height: '56px',
      borderRadius: '8px',
      width: '292px',
      marginTop: '52px',
      marginBottom: "15px",
      "@media (max-width: 1200px)": {
        marginTop: '52px',
      },
      "@media (max-width: 770px)": {
        marginTop: '30px',
      },
      "@media (max-width: 500px)": {
        marginTop: '15px',
        width: '175px',
        height: '40px',
      },
    }
  })




// Customizable Area End
import MomentsPageController, {
    Props,
} from "./MomentsPageController";
import { mainMomments, recipeDesign1, recipeBannerEmpty, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, downArrow, recipeDesign8, recipeDesign10, outlineCheck, familyTreeCircle, familyTreePin } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class MomentsPage extends MomentsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderBackground() {
        const { landingData } = this.state;
        const backgroundStyle = {
            backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
        };
    
        return (
            <StyledBox className="top-container" style={backgroundStyle}>
                <StyledBox className="secondbox">
                    <StyledTypography className="recipe-title prata"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.title ) }}></StyledTypography>
                    <StyledTypography className="headerTextsecond"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.description ) }}>
                    </StyledTypography>
                    <StyledButton className="get-started" type="submit" onClick={() => this.handleGetStarted("/MomentsPage")} data-test-id = "getStartedTestId">
                        <Typography style={webStyles.continueTextMoment as any}>Get started</Typography>
                    </StyledButton>
                </StyledBox>
            </StyledBox>
        );
    }

    renderFaqs() {
        const { landingData } = this.state;
        return (
            <Container maxWidth="md" style={{ marginBottom: 40, marginTop: 20 }}>
                <div style={webStyles.faqContainer}>
                    {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
                        landingData[0].landing_page_faqs.map((faq: any, index: any) => (
                            <Box
                                key={faq.id}
                                style={{
                                    marginBottom: '16px',
                                    borderRadius: '4px',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '12px',
                                        cursor: 'pointer',
                                        borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                                    }}
                                    onClick={() => this.handleAccordian(index)}
                                    data-test-id={`coveracordian-${index + 1}`}
                                >
                                    <StyledTypography  className="recipesQuestions"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question ) }}>
                                        
                                    </StyledTypography>
                                    <IconButton
                                        size="small"
                                        aria-expanded={this.state.recipeFaq === index}
                                        style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                        aria-label="show more"
                                    >
                                        <img
                                            src={downArrow}
                                            style={{
                                                transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                                width: 24,
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                {this.state.recipeFaq === index && (
                                    <Box style={{ padding: '12px', borderBottom: '1px solid #D6D3D1' }}>
                                        <StyledTypography
                                            className="faqsAnswers"
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer ) }}
                                        >
                                        </StyledTypography>
                                    </Box>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Typography>No FAQs available</Typography>
                    )}
                </div>
            </Container>
        );
    }


    renderWorkStorages() {
        const { landingData } = this.state;
        return (
  
            <Box style={{ width: "100%", maxWidth: "1250px", marginTop: 70 }} data-test-id="workstorage-container">
                {landingData && landingData[0]?.work_storages?.length > 0 ? (
                    landingData[0].work_storages.map((storage: any, index: any) => (
                        <Box key={index} style={{ display: 'flex', position: 'relative', marginTop: index === 0 ? 0 : 70, marginBottom: index === landingData[0].work_storages?.length - 1 ? 70 : 0  }}>
                            {index < 1 || (
                            <img
                                    src={familyTreeCircle}
                                    style={{
                                        width: "100%",
                                        maxWidth: "204px",
                                        position: 'absolute',
                                        right: 518,
                                        top: -120,
                                        height: 230
                                    }} />
                            )}
                          <StyledBox className={`${index % 2 === 0 ? "content-container" : "content-container-odd" }`}>
                            {index % 2 === 0 ? (
                              <>

                                <StyledBox className="empty-image-container">
                                  <StyledImg className="empty-img-data" src={storage?.content_image_url ?? recipeBannerEmpty} />
                                </StyledBox>
                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 50 }}>
                                  <StyledTypography
                                    className="storage-moment-title"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(storage.title) }}>

                                  </StyledTypography>
                                  <StyledTypography className="storage-description"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(storage.description) }}>

                                  </StyledTypography>
                                </Box>
                              </>
                            ) : this.renderMomentsRightSection(storage)}
                          </StyledBox>
                        </Box>
                    ))
                ) : (
                    <Typography>No work storages available.</Typography>
                )}
            </Box>
        );
    }

  renderMomentsRightSection(momentStorage: { title: string | Node;
     description: string | Node; content_image_url: string; }) {
    return (
      <>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 50 }}>
          <StyledTypography className="storage-moment-title"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(momentStorage.title) }}>

          </StyledTypography>
          <StyledTypography className="storage-description"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(momentStorage.description) }}>

          </StyledTypography>
        </Box>
        <StyledBox className="empty-image-container">
          <StyledImg className="empty-img-data" src={momentStorage?.content_image_url ?? recipeBannerEmpty} />
        </StyledBox>
      </>
    )
  }
    
    

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { landingData } = this.state;
        return (
            //Merge Engine DefaultContainer
            <Box>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
                    getHeaderData={(data) => {
                        this.landingPageFilterAPi(data);
                    }}
                />
            <StyledBox
              style={{
                backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
              }} className="top-container">
              <StyledBox style={webStyles.subHeaderBox} className="secondbox">
                <StyledTypography className="recipe-title prata"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.title) }}></StyledTypography>
                <StyledTypography className="headerTextsecond"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.description) }}
                >
                </StyledTypography>
                <StyledButton className="get-started" data-test-id="btnGetStarted" type="submit" onClick={() => this.handleGetStarted("/RecipsPage")}>
                  <Typography style={webStyles.continueTextRecipe as any}>Get started</Typography>
                </StyledButton>
              </StyledBox>
            </StyledBox>
              
                <Box>
                    <StyledBox className="store-container" style={{ display: "flex", justifyContent: 'center', gap: 80, marginTop: 60, position: 'relative' }}>

                        <img src={recipeDesign5} style={{ maxWidth: "297px", width: "100%", position: 'absolute', right: 194, top: -200 }} />
                        <img src={recipeDesign1} style={{ maxWidth: "220px", width: "100%", position: 'absolute', left: 0, top: -130, zIndex: -1 }} />
                        <img src={recipeDesign3} style={{ maxWidth: "150px", width: "100%", position: 'absolute', right: 0, top: -650, height: "356px" }} />

                        <Card style={{ width: "100%", zIndex: 2, maxWidth: "1133px", borderRadius: 16, backgroundColor: "#fff", color: "#fff",
                        paddingTop: 0,
                        paddingRight: 30,
                        paddingBottom: 30,
                        paddingLeft: 30, boxShadow: "0 4px 8px rgba(0.5, 0.5, 0.5, 0.5)" }}>
                            <CardContent>
                                <StyledTypography className="card-title"
                                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.content_title ) }}>
                                </StyledTypography>
                                <StyledTypography className="card-subtitle"
                                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.content_description) }}>
                                </StyledTypography>
                            </CardContent>
                        </Card>
                    </StyledBox>
                </Box>
            <MomentPageStyle>
              {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
                <Box className="priceBox" key={benefit.id}>
                  <Typography className="priceTitle"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.title) }}></Typography>
                  <Box className="priceCenterDiv">
                    <Typography className="priceSubTitle"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.description) }}
                    />
                  </Box>
                  <Button className="startBtn" onClick={this.startNowBtn}>Start now</Button>
                </Box>
              )) || null}
            </MomentPageStyle>
                <Box style={{ display: 'flex', marginTop: 100, position: "relative", justifyContent: 'center', }}>
                    <StyledBox className="background-image-feature" style={webStyles.backgroundImageFeature as any}>
                        <img src={recipeDesign8} style={{ width: "100%", position: 'absolute', maxWidth: "229px", right: 0, top: -167, height: 300 }} />
                        <img src={recipeDesign2} style={{ width: "100%", position: 'absolute', maxWidth: "130px", left: 0, top: 90, height: 150 }} />
                        <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', top: 1000, right: 0, height: 352 }} />
                        <img src={familyTreePin} style={{ width: "100%", maxWidth: "390px", position: 'absolute', left: 0, bottom: 50 }} />
                        <StyledTypography className="bestFeatures">How to Save Family Moments with MyKinship</StyledTypography>
                        <StyledTypography className="bestFeaturesdescription">MyKinship offers a seamless and secure way to manage your family’s digital photo collection. Here’s how it works</StyledTypography>

                       {this.renderWorkStorages()}
              </StyledBox>

                </Box>
                <Box style={{ maxWidth: 1240, margin: 'auto', width: '90%' }}>
                    <StyledTypography className="frequentlyTextRecipe">Frequently asked questions</StyledTypography>
                    <StyledTypography className="simpleTextRecipe">Simple, transparent pricing that grows with you. Try any plan free for 30 days.</StyledTypography>
                </Box>
                {this.renderFaqs()}


                <Box style={{ position: 'relative' }}>
                    <Box style={{ width: '90%', maxWidth: 1240, margin: 'auto' }}>
                        <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', right: 0, height: 420, top: -80, zIndex: -1 }} />
                        <StyledTypography className="more-question-title">Reach Out to Us with Any Questions</StyledTypography>
                        <StyledTypography className="more-question-subtitle">Losing your mementos, primarily pictures or videos, might be irreversible, so why not store them in a safe location? If you want to immortalize your memories and still have access to them anytime from anywhere, sign up for MyKinship.</StyledTypography>
                    </Box>
                </Box>
                <Footer navigation={this.props.navigation} id={""} />
            </Box>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
  continueTextRecipe: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  subHeaderBox:
  {
    width: "100%",
    maxWidth: "666px",
    marginRight:50
  },
    container: {
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: "32rem",
        padding: "0.5rem 1rem",
        minHeight: "12rem",
        gap: "2rem",
    },
    HeaderTextMoment: {
        fontSize: 72,
        fontWeight: 400,
        fontFamily: 'Abygaer',
        color: '#305328',
        marginTop: 130,
        width: "100%",
        maxWidth: "700px"
    },
    subHeaderMoment: {
        color: '#305328',
        fontWeight: 400,
        fontSize: 24,
        fontFamily: "Poppins"
    },
    backgroundImage: {
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${mainMomments})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        maxWidth: "1635px",
        height: '800px',
        display: 'flex',
        justifyContent: "end",
    },
    continueButtonMoment: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '292px',
        marginTop: '80px',
    },
    continueTextMoment: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    familyText: {
        color: "#1C1917",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 36
    },
    textContainer: {
        width: "100%",
        maxWidth: "656px"
    },
    familyText2: {
        color: "#305328",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 48,
        textAlign: 'right',
        width: "100%",
        maxWidth: "720px"
    },
    contentText: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#1C2110"
    },
    bestFeatures: {
        color: "#305328",
        fontWeight: 700,
        fontSize: 48,
        fontFamily: "Poppins",
        marginTop: 30
    },

    bestFeatures2: {
        color: "#5D6063",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 20
    },
    backgroundImageFeature: {
        backgroundImage: `url(${recipeFeatureBg})`
    },
    frequentlyText: {
        fontSize: 36,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#1C1917',
        marginTop: 50
    },
    simpleText2: {
        fontSize: 18,
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#5D6063'
    },

    moreQuestions: {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 36,
        color: "#1C1917"
    },
    moreQuestions2: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 18,
        color: "#5D6063",
        marginBottom: 70
    },
    contentMomentTitle:
    {
        fontWeight: 700, fontFamily: "Poppins", fontSize: 48, color: "#305328", textAlign: "center"
    },
    contentMomentDescription: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "1C2110",
        marginTop:20
    },
    faqContainer:{
    width: '100%',
    maxWidth: 920, 
    marginTop: 50, 
    margin: 'auto' 
},
faqStyleMoment:{
    flex: 1,
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 16,
    color: '#5D6063',
}
};

const MomentPageStyle = styled("div")({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 60,
  gap: "50px",
  width: "90%",
  marginInline: "auto",
  flexWrap: "wrap",
  "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem",
      gap: "20px", 
  },
  "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem",
      gap: "20px", 
  },
  "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "1rem",
      gap: "20px", 
  },
  
  "& .priceMainBox": {
    justifyContent: 'space-between',
    display: 'flex',
    width: '94%',
  },
  "& .priceBox": {
    borderRadius: '16px',
    width: '370',
    height: '380',
    boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
      width: '100%', 
  },
  "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
      width: '100%',
  },
  "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
      width: '100%',
  },
  },
  "& .priceTitle": {
    lineHeight: "44px",
    fontWeight: 700,
    fontSize: "36px",
    fontFamily: 'Poppins',
    marginTop: '40px',
    color: '#1C2110',
    letterSpacing: '-1%',
    marginBottom: '5px',
    textAlign: 'center'
  },
  "& .priceSubTitle": {
      fontWeight: 400,
      backgroundColor: 'transparent !important',
      fontSize: "18px",
      boxSizing: "border-box",
      fontFamily: 'Poppins',
      color: '#1C2110',
      padding: "18px 22px 18px 22px",
      textAlign: 'center',
      lineHeight: "28px",
  },
  "& .priceSubTitle span": {
      backgroundColor: "transparent !important",
  },
  "& .priceCenterDiv": {
        height: '140px',
        display: "flex",
        textAlign: 'center',
        backgroundColor: "#E8EDDB",
        alignItems: "center",
        marginTop: 40
  },

  "& .priceCenterBox": {
    backgroundColor: "#557C29",
    height: '140px'
  },
  "& .priceTag": {
    lineHeight: "56px",
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: "64px",
    textAlign: 'center',
    letterSpacing: '-1.5%',
    color: '#FFFFFF',
    padding: '13px',
  },
  "& .dollar": {
    lineHeight: "56px",
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: "32px",
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    color: '#FFFFFF',
    marginBottom: '20px',
    fontFamily: 'Poppins',
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: '40px',
    fontFamily: 'Poppins',
    color: '#1C2110',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    marginTop: '40px',
    marginBottom: '48px',
    padding: '10px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    textTransform:"none",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#FFFFFF',
    }
  }
})


// Customizable Area End
