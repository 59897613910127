import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


interface S {
  // Customizable Area Start
  infoModalOpen: boolean;
  peopleAnchorEl: HTMLElement | null;
  mediaAnchorEl: HTMLElement | null;
  thingsAnchorEl: HTMLElement | null;
  permissionAnchorEl: HTMLElement | null;
  locationAnchorEl: HTMLElement | null;
  dateAnchorEl: HTMLElement | null;
  mediaClick: boolean;
  year: string;
  month: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      infoModalOpen: false,
      peopleAnchorEl: null,
      mediaAnchorEl: null,
      thingsAnchorEl: null,
      permissionAnchorEl: null,
      locationAnchorEl: null,
      dateAnchorEl: null,
      year: '',
      month: '',
      mediaClick: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  handleDoneBtn = () => {
    this.setState({
      infoModalOpen: true
    })
  }

  handleCloseModal = () => {
    this.setState({
      infoModalOpen: false
    })
  }

  handleMediaClick = (event: { currentTarget: any; }) => {
    this.setState({ mediaAnchorEl: event.currentTarget });
  };

  handleMediaClose = () => {
    this.setState({ mediaAnchorEl: null });
  };

  handlePeopleClick = (event: { currentTarget: any; }) => {
    this.setState({ peopleAnchorEl: event.currentTarget });
  };

  handlePeopleClose = () => {
    this.setState({ peopleAnchorEl: null });
  };

  handlethingsClick = (event: { currentTarget: any; }) => {
    this.setState({ thingsAnchorEl: event.currentTarget });
  };

  handlethingsClose = () => {
    this.setState({ thingsAnchorEl: null });
  };

  handlepermissionClick = (event: { currentTarget: any; }) => {
    this.setState({ permissionAnchorEl: event.currentTarget });
  };

  handlepermissionClose = () => {
    this.setState({ permissionAnchorEl: null });
  };

  handlelocationClick = (event: { currentTarget: any; }) => {
    this.setState({ locationAnchorEl: event.currentTarget });
  };

  handlelocationClose = () => {
    this.setState({ locationAnchorEl: null });
  };

  handleDateClick = (event: { currentTarget: any; }) => {
    this.setState({ dateAnchorEl: event.currentTarget });
  };

  handleDateClose = () => {
    this.setState({ dateAnchorEl: null });
  };

  handleYearChange = (event: any) => {
    this.setState({ year: event.target.value });
  };
  handleMonthChange = (event: any) => {
    this.setState({ month: event.target.value as string });
  };

  // Customizable Area End
}