import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { image, Logo } from "./assets";
import OtpInput from "react-otp-input";
// Customizable Area End

import TwoFactorAuthController, {
    Props,
} from "./TwoFactorAuthController.web";
import { Alert } from "@material-ui/lab";

export default class TwoFactorAuth extends TwoFactorAuthController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <OtpContainerStyle>
                <Box className="mainDiv">
                    <Box className="backgroundBox">
                        <Box className="backgroundImage">
                            <img className="bgImage" src={image} alt="Background" />
                        </Box>
                    </Box>
                    <Box className="containerBox">
                        <Box className="container">
                            <Box className="logoImage">
                                <img style={{ height: '60px', width: "110px" }} src={Logo} alt="Logo" />
                            </Box>
                            <Box className="titleBox">
                                {this.state.apiError && (
                                    <Alert
                                        className="alertBox"
                                        severity="error"
                                        icon={false}
                                        style=
                                        {{
                                            marginTop: '32px'
                                        }}
                                    >
                                        {this.state.apiError}
                                    </Alert>
                                )}
                                <br />
                                <Typography className="factorTitle">Two-Factor Authentication</Typography>
                                <Typography className="factorSubTitle">Enter the 5-digit code that we sent to your email</Typography>
                                <OtpInput
                                    data-test-id="otpValue"
                                    value={this.state.otpValue}
                                    renderSeparator={<span style={{ width: "32px" }}></span>}
                                    renderInput={(props) => <input {...props} />}
                                    numInputs={5}
                                    onChange={this.handleOtpChange}
                                    inputStyle={{
                                        border: "1px solid #D6D3D1",
                                        borderRadius: "8px",
                                        width: "56px",
                                        height: "56px",
                                        fontSize: "14px",
                                        caretColor: "black"
                                    }}

                                />
                                <Button className="verifyBtn" data-test-id="verifyBtn" onClick={this.handleVerifyClick} fullWidth>
                                    Verify
                                </Button>
                                <Button className="goBackBtn" data-test-id="goBackBtn" onClick={this.handleGoBack} >
                                    Go back
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </OtpContainerStyle>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const OtpContainerStyle = styled("div")({
    height: "100%",
    width: '100%',
    display: "flex",
    position: "relative",
    overflow: "hidden",

    "& .mainDiv": {
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
    },
    "& .backgroundBox": {
        width: "50%",
        height: "100%",
        position: "absolute",
        right: 0,
        zIndex: 0,
    },
    "& .backgroundImage": {
        width: "100%",
        height: "100%",
        backgroundPosition: 'right',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    "& .bgImage": {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '0 0 0 40px'
    },
    "& .alertBox": {
        borderRadius: '4px',
        border: '0px, 0px, 0px, 4px',
        borderLeft: '4px solid #DC2626',
        padding: "12px, 16px, 12px, 16px",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
    },
    "& .containerBox": {
        width: "50%",
        height: "100%",
        position: "relative",
        left: 0,
        zIndex: 1,
    },
    "& .container": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .logoImage": {
        marginTop: '16px',
        marginLeft: '40px',
        position: "absolute",
        top: 0,
        left: 0,
    },
    "& .titleBox": {
        textAlign: "center",
    },
    "& .verifyBtn": {
        backgroundColor: '#8CA74B',
        height: "56px",
        borderRadius: "8px",
        padding: "16px",
        marginTop: "32px",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        '& .MuiButton-label': {
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "none",
            fontFamily: "Poppins",
        }
    },
    "& .goBackBtn": {
        marginTop: '32px',
        color: '#A8A29E',
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        '& .MuiButton-label': {
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "none",
            fontFamily: "Poppins",
        }
    },
    "& .factorTitle": {
        fontWeight: 700,
        fontSize: "24px",
        fontFamily: "Poppins",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
        color: "#000000",
        display: 'flex',
        alignContent: 'start'
    },
    "& .factorSubTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#334155",
        marginTop: "8px",
        marginBottom: "32px",
        marginLeft: '-90px'
    },
    '@media (min-width: 750px) and (max-width: 1024px)': { 
        "& .titleBox": {
            width: "85%",
            padding: "0 16px", 
            marginTop:"290px",
            marginLeft:"-31px",
           
        },
        "& .factorSubTitle": {
            marginLeft: '1px',
        },
      },
  "@media (max-width: 600px)": {
    "& .backgroundBox": {
        display: "none",
    },
    "& .containerBox": {
        width: "100%",
        padding: "0 16px", 
    },
    "& .logoImage": {
        marginLeft: '16px', 
        marginTop: '8px',
        "& img": {
            height: '40px',
            width: '80px',
        }
    },
    "& .titleBox": {
        width: "85%", 
        padding: "0 16px", 
        marginTop:"290px",
        marginLeft:"-31px"
    },
    "& .factorTitle": {
        fontSize: "18px",
        lineHeight: "24px",
        textAlign: "center",
        marginLeft: '0',
        marginTop: "16px",
    },
    "& .factorSubTitle": {
        fontSize: "12px",
        lineHeight: "18px",
        marginLeft: '0',
        textAlign: "center",
        marginTop: "8px",
        marginBottom: "24px",
    },
    "& .verifyBtn": {
        fontSize: "14px",
        lineHeight: "20px",
        height: "44px",
        marginTop: "16px",
        width: "100%",
    },
    "& .goBackBtn": {
        fontSize: "14px",
        lineHeight: "20px",
        marginTop: "16px",
        width: "100%",
    },
    "& .alertBox": {
        fontSize: "10px",
        padding: "8px 12px",
        width: "100%",
        marginTop: "16px",
    },
    "& .container": {
        justifyContent: "flex-start",
        paddingTop: "24px",
        
    }
}
});
// Customizable Area End
