import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import PeopleDashboardController, {
    Props,
} from "./PeopleDashboardController";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { peopleZero } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { SharingDashboardZeroStyle } from "./SharingDashboard.web";
// Customizable Area End

export default class PeopleDashboard extends PeopleDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SharingDashboardZeroStyle>
                <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="People" />
                <Box className="MainContent">
                    <Box className="Header">
                        <HeaderDropdown
                            data-test-id="headerComponent" navigation={this.props.navigation} id={""}
                        />
                    </Box>
                    <Typography className="page-title">
                        People
                    </Typography>
                    <Box className="centerDiv">
                        <img className="img" src={peopleZero} alt="Trash Icon" />
                        <Typography className="welcomeTitle">No People Added Yet</Typography>
                        <Typography className="sublabel">Start connecting with friends and family! Once you add people, they’ll appear here.</Typography>
                        <Button className="shareBtn" data-test-id="addPeopleBtn"
                        ><Typography className="shareBtnText">Add People</Typography></Button>
                    </Box>
                </Box>
            </SharingDashboardZeroStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End
