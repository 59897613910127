import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface FilterButtonProps {
  label: string;
}
// Customizable Area End


interface S {
  // Customizable Area Start
  selectedView: string,
  drawerOpen: boolean,
  showShareContent: boolean,
  isGroupsExpanded: boolean,
  isPhotoExpanded: boolean,
  isPeopleExpanded: boolean,
  isMemoriesExpanded: boolean,
  isFamilyTreeExpanded: boolean,
  isRecipesExpanded: boolean,
  page: number,
  totalPages: number,
  showCreateGroupFile: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecipeDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedView: 'myFiles',
      drawerOpen: false,
      showShareContent: false,
      isGroupsExpanded: false,
      isPhotoExpanded: false,
      isPeopleExpanded: false,
      isMemoriesExpanded: false,
      isFamilyTreeExpanded: false,
      isRecipesExpanded: false,
      page: 1,
      totalPages: 2,
      showCreateGroupFile: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  // Customizable Area End
}