import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { Recipes, recipesImg, storeImg, Storage } from "./assets";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ShareIcon from "@mui/icons-material/Share";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';

interface MenuItem {
  text: string;
  subItems: string[];
  icon: React.ReactNode;
  root:string;
}


const menuItems: MenuItem[] = [
  { text: "Favorites", subItems: [], icon: <StarOutlineIcon />,root:"Favourites" },
  { text: "Photos", subItems: [], icon: <CameraAltOutlinedIcon /> ,root:"PhotoLibrary"},
  { text: "People", subItems: [], icon: <PeopleOutlinedIcon />,root:"PeopleDashboard" },
  { text: "Memories", subItems: [], icon: <SentimentVerySatisfiedOutlinedIcon />,root:"MemoriesDashboard" },
  { text: "Family Tree", subItems: [], icon: <AccountTreeOutlinedIcon />,root:"FamilyTreeDashboard" },
  { text: "Recipes", subItems: [], icon: null,root:"RecipeDashboard" },
  { text: "Sharing", subItems: [], icon: <ShareIcon />,root:"SharingDashboard" },
  { text: "Storage", subItems: [], icon: null,root:"DataStorage" },
  { text: "Trash", subItems: [], icon: <DeleteOutlineIcon />,root:"TrashFile" },
];

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";
import SideDrawer from "../../../components/src/SideDrawer";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  mainItemStyle = (text: string): React.CSSProperties => ({
    height: "40px",
    color: (this.state.selectedItem === text || this.state.hoveredItem === text) ? "#fff" : "#1C2110",
    backgroundColor: (this.state.selectedItem === text || this.state.hoveredItem === text) ? "#557C29" : "white",
    borderRadius: (this.state.selectedItem === text || this.state.hoveredItem === text) ? "4px" : "0px",
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {selectedItem, hoveredItem} = this.state;

    return (
      <Box sx={{
        width: 250, background: "white", boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)", height: "100vh", '@media (max-width:950px)': {
          display: `${this.props.isFromHeader ? 'block' : 'none'}`,
        },
      }}  >
        <List style={{ padding: '20px' }}>
        {menuItems.map(({ text, subItems, icon,root }) => (
            <React.Fragment key={text}>
              <ListItem disablePadding sx={{ padding: "0px" }}>
                <ListItemButton
                data-test-id = "nav-item-link"
                onClick={(event) => this.handleItemClick(text,root)}
                  onMouseEnter={() => this.handleItemHover(text)}
                  onMouseLeave={this.handleMouseLeave}
                  sx={{
                    ...this.mainItemStyle(text),
                    marginTop: "12px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    "&:hover": {
                      backgroundColor: "#557C29",
                      opacity: 1,
                    height:'40px',
                      color: "#fff !important",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: (selectedItem === text || hoveredItem === text) ? "#fff" : "#1C2110" }}>
                    {text === "Recipes" && (
                      <img
                        src={(selectedItem === "Recipes" || hoveredItem === "Recipes") ? Recipes : recipesImg}
                        style={{ color: "#1C2110" }}
                        alt="Recipes Icon"
                      />
                    )}
                    {text === "Storage" && (
                      <img
                        src={(selectedItem === "Storage" || hoveredItem === "Storage") ? Storage : storeImg}
                        style={{ color: "#1C2110" }}
                        alt="Recipes Icon"
                      />
                    )}
                    {icon}
                  </ListItemIcon>
                  <Typography style={{ marginLeft: '-15px' }}
                    variant="body1"
                    sx={{
                      fontSize: (selectedItem === text || hoveredItem === text) ? 16 : 14,
                      fontWeight: (selectedItem === text || hoveredItem === text) ? 700 : 400,
                      fontFamily: "Poppins, sans-serif",
                      "&:hover": {
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#fff !important",
                      },
                    }}
                  >
                    {text}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
