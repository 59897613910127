import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    Divider,
    Checkbox,
    IconButton,
    Toolbar,
    Chip
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import MemoriesDashboardController, {
    Props,
} from "./MemoriesDashboardController";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { memoriesIcon } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { SharingDashboardZeroStyle } from "./SharingDashboard.web";

// Customizable Area End

export default class MemoriesDashboard extends MemoriesDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <SharingDashboardZeroStyle>
                    <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Memories" />
                    <Box className="MainContent">
                        <Box className="Header">
                            <HeaderDropdown
                                data-test-id="headerComponent" navigation={this.props.navigation} id={""}
                            />
                        </Box>
                        <Typography className="page-title">
                            Memories
                        </Typography>
                        <Box className="centerDiv">
                            <img className="img" src={memoriesIcon} alt="Trash Icon" />
                            <Typography className="welcomeTitle">No Memories Created Yet</Typography>
                            <Typography className="sublabel">Capture and save your special moments. Your memories will show up here once you create them.</Typography>
                            <Button className="shareBtn" data-test-id="shareBtn"
                            ><Typography className="shareBtnText">Create Memory</Typography></Button>
                        </Box>
                    </Box>
                </SharingDashboardZeroStyle>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const MemoriesDashboardStyle = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    "& .MainContent": {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    "& .Content": {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginLeft: '20px',
        alignItems: 'center'
    },
    "& .sharingTitle": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: '-0.5%'
    },
    "& .modalStyle": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        backgroundColor: "#fff",
        border: "2px solid #000",
        boxShadow: "24px",
        padding: "16px"
    },
    "& .modalHeader": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .expandStyle":{
        position: 'absolute',
        right: '10px',
        top: '66%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        color: '#1C2110',
    },
    "& .relativeTextAreaStyle":{
        width: "100%",
        marginTop: 8,
        borderRadius: 8,
        height: 102,
        padding: '8px',
        resize: 'none',
        border: '1px solid #D6D3D1',
        color: "#000",
        fontSize: 16,
        fontFamily: "Poppins",
        fontWeight: 400,
    },
    "& .formateTextStyle":{
        fontWeight: 400,
        fontSize: 14,
        color: '#202020',
        marginBottom: 2,
        fontFamily: "Poppins",
        marginTop: 5
    },
    "& .privateMomentStyle":{
        fontSize: 16, 
        fontFamily: 'Poppins', 
        fontWeight: 400, 
        color: '#1C2110', 
        marginTop: 16 
    },
    "& .cancelButtonStyle":{
        backgroundColor: '#F8FFE7',
        textTransform: 'none',
        color: '#557C29',
        border: "1px solid #557C29",
        width: "153px",
        height: 56,
        borderRadius: 8,
        fontFamily: "Poppins",
        fontWeight: 700
    },
    "& .momentButtonStyle":{
        backgroundColor: '#557C29',
        textTransform: 'none',
        color: '#fff',
        border: "1px solid #557C29",
        width: "153px",
        height: 56,
        borderRadius: 8,
        fontFamily: "Poppins",
        fontWeight: 700
    }

});
// Customizable Area End
