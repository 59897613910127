import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled } from "@material-ui/core";
import DOMPurify from 'dompurify';
import Header from "../../../components/src/Header.web";

const StyledBox = styled(Box)({
  "&.top-container": {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    maxWidth: "1635px",
    backgroundPosition: "center",
    height: "850px",
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 1475px)": {
      height: "fit-content",
    },
  },
  "&.empty-image-container": {
    marginLeft: "15px",
    "@media (max-width: 1200px)": {
      height: "80%",
      width: "80%",
    },
    "@media (max-width: 800px)": {
      height: "70%",
      width: "70%",
    },
    "@media (max-width: 700px)": {
      height: "50%",
      width: "50%",
    },
  },

  "&.empty-image-container-odd": {
    width: "100%",
    height: "100%",
    marginRight: "15px",
    "@media (max-width: 1200px)": {
      display: "none"
    },
  },
"&.content-container": {
    display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'flex-start',
    "@media (max-width: 1200px)": {
      width: "90%",
      margin: "auto",
      flexDirection: "column",
      alignItems: 'center',
      textAlign: "center"
    },
  },
  "&.content-container-odd": {
    display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'flex-start',
    "@media (max-width: 1200px)": {
      width: "90%",
      margin: "auto",
      flexDirection: "column-reverse",
      alignItems: 'center',
      textAlign: "center"
    },
  },
  "&.secondbox": {
    "@media (max-width: 770px)": {
      marginLeft: "20px",
    },
  },

  "&.recipe-banner-container": {
    marginRight: "10px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
    "@media (max-width: 950px)": {
      display: "none",
    },
  },

  "&.recipe-banner-mobile-container": {
    display: "none",
    "@media (max-width: 950px)": {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
      width: "70%",
    },
    "@media (max-width: 500px)": {
      width: "60%",
    },
  },
  "&.family-text-container": {
    display: "flex",
    justifyContent: "center",
    marginTop: "150px",
    gap: 80,
  },
  "@media (max-width: 750px)": {
    marginTop: "49px !important",
  },
});

const StyledFamilyImage = styled("img")({
  "&.empty-img-data": {
    maxWidth: "620px", maxHeight: "500px", zIndex: 1,
    width: "620px", height: "500px",
    "@media (max-width: 1200px)": {
      width: "100%",
      height: "100%",
      marginBottom: "20px"
    },
  },
})


const StyledTypography = styled(Typography)({

  "&.storage-title": {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    "& p": {
      marginBottom: "0px",
      marginTop: "0px",
    },
    "@media (max-width: 1200px)": {
      fontSize: 24,
      marginTop: "10px",
    },
    "@media (max-width: 800px)": {
      fontSize: 20,
      paddingRight: "5px",
    },
  },
  
  "&.headerTextsecond": {
    color: '#305328',
    fontWeight: 400,
    fontSize: 24,
    fontFamily: "Poppins",
    marginTop:20,
    "@media (max-width: 1200px)": {
      fontSize: 20,
      lineHeight: 1.2
    },
    "@media (max-width: 770px)": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "@media (max-width: 500px)": {
      fontSize: 16,
      lineHeight: 1.2
    },
  },
  "&.storage-description": {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "Poppins",
    marginTop: 10,
    "& p": {
      "@media (max-width: 1200px)": {
        marginBottom: "0px",
        marginTop: "0px",
      },
    },
    "@media (max-width: 1200px)": {
      fontSize: 20,
    },
    "@media (max-width: 800px)": {
      fontSize: 18,
    },
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },

  "&.recipe-title": {
    fontSize: 72,
    fontWeight: 400,
    fontFamily: 'Abygaer',
    color: '#305328',
    marginTop: 130,
    "@media (max-width: 1200px)": {
      fontSize: 50,
      lineHeight: 1.2
    },
    "@media (max-width: 770px)": {
      fontSize: 40,
      lineHeight: 1.2,
      marginTop: 65
    },
    "@media (max-width: 500px)": {
      fontSize: 30,
      lineHeight: 1,
      marginTop: 50
    },
  },
  "&.familyTextRecipe": {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48,
    "@media (max-width: 1400px)": {
      marginLeft: "25px"
    },
    "@media (max-width: 1200px)": {
      fontSize: 34,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
     "@media (max-width: 770px)": {
      width: '90%',
      marginInline: "auto",
      textAlign: "center",
      fontSize: 26,
      lineHeight: 1.2,
    },
  },

  "&.family-bottom-subtask": { 
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110", 
    textAlign: "right",
    "@media (max-width: 1450px)": {
      marginRight: "25px"
    },
    "@media (max-width: 1200px)": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
    "@media (max-width: 770px)": {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 1.2,
      width: "95%",
      marginInline: "auto"
    },
  },
  "&.familyBottomTextRecipe": {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48,
    textAlign: "right",
    "@media (max-width: 1400px)": {
      marginRight: "25px"
    },
    "@media (max-width: 1200px)": {
      fontSize: 34,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
     "@media (max-width: 770px)": {
      fontSize: 26,
      lineHeight: 1.2,
      width: '90%',
      marginInline: "auto",
      textAlign: "center",
    },
  },
  "&.family-subtask": { 
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110", 
    "@media (max-width: 1400px)": {
      marginLeft: '25px'
    },
    "@media (max-width: 1200px)": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "@media (max-width: 950px)": {
      textAlign: "left"
    },
    "@media (max-width: 770px)": {
      fontSize: 16,
      lineHeight: 1.2,
      width: "95%",
      textAlign: "center",
      marginInline: "auto"
    },
  },
  "&.more-question-title": {
    fontWeight: 700,
    fontSize: 36,
    fontFamily: "Poppins",
    color: "#1C1917",
    "@media (max-width: 850px)": {
      fontSize: 25,
      lineHeight: 1.2,
      width: "95%"
    },
    "@media (max-width: 600px)": {
      fontSize: 20,
      lineHeight: 1.2,
      width: "95%"
    },
  },
  "&.more-question-subtitle": {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#5D6063",
    marginTop: 10,
    marginBottom: 70,
    "@media (max-width: 850px)": {
      fontSize: 16,
      lineHeight: 1.2,
      width: "95%"
    },
  }, 
  "&.frequentlyTextRecipe": {
    fontSize: 36,
    fontWeight: 700,
    color: '#1C1917',
    marginTop: 100,
    fontFamily: 'Poppins',
    "@media (max-width: 700px)": {
      fontSize: 24,
      lineHeight: 1.2,
    },
    "@media (max-width: 500px)": {
      fontSize: 20,
      marginTop: 35,
    },
  },
  "&.simpleTextRecipe": {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063',
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
  "&.faqsAnswers": {
    fontFamily: 'Poppins',
    fontWeight: 400,
    flex: 1,
    fontSize: 16,
    color: '#5D6063',
  },
  "&.recipesQuestions": {
    fontFamily: 'Poppins', flex: 1, fontSize: 18, fontWeight: 700 ,
    "@media (max-width: 500px)": {
      fontSize: 16,
    },
  },
  "&.recipeFeatureTitle": {
    fontWeight: 700, fontSize: 44, fontFamily: 'Poppins', color: "#305328",
    "@media (max-width: 1200px)": {
      fontSize: 35,
    },
    "@media (max-width: 700px)": {
      fontSize: 24,
    },
    "@media (max-width: 550px)": {
      fontSize: 18,
       paddingRight: "5px"
    },
    "& p": {
      marginTop: "0px",
      marginBottom: "0px"
    }
  },
  "&.recipeFeatureSubTitle": {
    fontWeight: 400, fontFamily: "Poppins", fontSize: 20,
    "@media (max-width: 700px)": {
      fontSize: 16,
    },
    "@media (max-width: 550px)": {
      fontSize: 12,
      paddingRight: "5px"
    },
  },
  "&.bestFeatures": {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginTop: 30,
    fontSize: 48,
    "@media (max-width: 1200px)": {
      fontSize: 35,
      textAlign: "center"
    },
    "@media (max-width: 750px)": {
      fontSize: 24,
      paddingRight: "5px",
      width: "90%",
      
    },
    "@media (max-width: 550px)": {
      fontSize: 18,
      paddingRight: "5px"
    },
  },
  "&.bestFeaturesdescription": {
    color: "#5D6063",
    fontFamily: "Poppins",
    fontWeight: 400,
    width: "100%",
    fontSize: 20,
    textAlign: 'center',
    maxWidth: "900px",
    "@media (max-width: 700px)": {
      fontSize: 18,
      width: "90%",
    },
    "@media (max-width: 550px)": {
      fontSize: 14,
      paddingRight: "5px"
    },
  }
});

const StyledButton = styled(Button)({
  "&.get-started": {
    backgroundColor: "#8CA74B",
    height: '56px',
    borderRadius: '8px',
    width: '292px',
    marginTop: '52px',
    marginBottom: "15px",
    "@media (max-width: 1200px)": {
      marginTop: '52px',
    },
    "@media (max-width: 770px)": {
      marginTop: '30px',
    },
    "@media (max-width: 500px)": {
      marginTop: '15px',
      width: '175px',
      height: '40px',
    },
  }
})
// Customizable Area End
import MomentsPageController, {
  Props,
} from "./MomentsPageController";
import { familyTreeBanner, recipeBannerEmpty, recipeDesign2, recipeDesign4, downArrow,  recipeDesign9, recipeDesign10, familyTreeDesign, familyTreeBg, familyTreeCircle } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class FamilyTreePage extends MomentsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFAQSection() {
    const { landingData } = this.state;

    return (
        <Container maxWidth="md" style={{ marginBottom: 40, marginTop: 20 }}>
            <div style={{ marginTop: 50, width: '100%', maxWidth: 920, margin: 'auto' }}>
                {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
                    landingData[0].landing_page_faqs.map((faq: any, index: any) => (
                        <Box
                            key={faq.id}
                            style={{
                                marginBottom: '16px',
                                borderRadius: '4px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '12px',
                                    borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                                }}
                                onClick={() => this.handleAccordian(index)}
                                data-test-id={`coveracordian-${index + 1}`}
                            >
                                <StyledTypography className="recipesQuestions"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question ) }}>
                                </StyledTypography>
                                <IconButton
                                    aria-expanded={this.state.recipeFaq === index}
                                    size="small"
                                    aria-label="show more"
                                    style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                >
                                    <img
                                        src={downArrow}
                                        style={{
                                            transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                            width: 24,
                                        }}
                                    />
                                </IconButton>
                            </Box>
                            {this.state.recipeFaq === index && (
                              <Box style={{ borderBottom: '1px solid #D6D3D1', padding: '12px' }}>
                                <StyledTypography
                                  className="faqsAnswers"
                                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }}
                                >
                                </StyledTypography>
                              </Box>
                            )}
                        </Box>
                    ))
                ) : (
                    <Typography>No FAQs available</Typography>
                )}
            </div>
        </Container>
       
    );
}

  renderFamilyWorkStorage() {
    const { landingData } = this.state;

    return (
      <Box style={{  maxWidth: "1250px",width: "100%", marginInline: "auto", marginTop: 70 }} data-test-id="workstorage-container">
        {landingData && landingData[0]?.work_storages?.length > 0 ? (
          landingData[0].work_storages.map((familyStorage: any, index: any) => (
            <Box key={index} style={{ display: 'flex', position: 'relative', marginTop: index === 0 ? 0 : 70, marginBottom: index === landingData[0].work_storages?.length - 1 ? 70 : 0 }}>
              {index < 1 || (
                <img
                  src={familyTreeCircle}
                  style={{
                    position: 'absolute',
                    right: 518,
                    top: -120,
                    height: 230,
                    width: "100%",
                    maxWidth: "204px",
                  }} />
              )}
              <StyledBox className={`${index % 2 === 0 ? "content-container" : "content-container-odd"}`}>
                {index % 2 === 0 ? (
                  <>

                    <StyledBox className="empty-image-container">
                      <StyledFamilyImage className="empty-img-data" src={familyStorage?.content_image_url ?? recipeBannerEmpty} />
                    </StyledBox>
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 50 }}>
                      <StyledTypography
                        className="storage-title"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(familyStorage.title) }}>

                      </StyledTypography>
                      <StyledTypography className="storage-description"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(familyStorage.description) }}>

                      </StyledTypography>
                    </Box>
                  </>
                ) : this.renderFamilyRightBlock(familyStorage) }
              </StyledBox>
            </Box>
          ))
        ) : (
          <Typography>No work storages available.</Typography>
        )}
      </Box>

    );
  }

  renderFamilyRightBlock(familyStorage: { title: string | Node; description: string | Node; content_image_url: string; }) {
    return (
      (
        <>
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 50 }}>
            <StyledTypography className="storage-title"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(familyStorage.title) }}>

            </StyledTypography>
            <StyledTypography className="storage-description"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(familyStorage.description) }}>

            </StyledTypography>
          </Box>
          <StyledBox className="empty-image-container">
            <StyledFamilyImage className="empty-img-data" src={familyStorage?.content_image_url ?? recipeBannerEmpty} />
          </StyledBox>
        </>
      )

    );
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { landingData } = this.state
    console.log("response 25", landingData[0]?.background_image_url);

    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
          getHeaderData={(data) => {
            this.landingPageFilterAPi(data);
          }}
        />
        <StyledBox className="top-container" style={{
          backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
        }} >
          <StyledBox style={{ maxWidth: "666px", width: "100%", marginRight:50 }} className="secondbox">
            <StyledTypography className="recipe-title prata"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.title ) }}></StyledTypography>
            <StyledTypography className="headerTextsecond"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.description) }}>
            
            </StyledTypography>
            <StyledButton className="get-started" data-test-id="btnGetStarted" type="submit" onClick={() => this.handleGetStarted("/FamilyTreePage")}>
              <Typography style={webStyles.continueTextTree as any}>Get started</Typography>
            </StyledButton>
          </StyledBox>
        </StyledBox>
        {this.renderFamilyWorkStorage()}

        <FamiltTreeStyle>
          {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
            <Box className="priceBox3" key={benefit.id}>
              <Typography className="priceTitle3"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.title) }}></Typography>
              <Box className="priceCenterDiv3">
                <Typography className="priceSubTitle3" 
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.description) }} />
              </Box>
              <Button className="startBtn3" onClick={this.startNowBtn}>Start now</Button>
            </Box>
          )) || null}
        </FamiltTreeStyle>
        <Box style={{ display: 'flex', marginTop: 100, justifyContent: 'center', position: "relative" }}>
          <Box style={webStyles.backgroundImageFeature as any}>
            <img src={familyTreeDesign} style={{ width: "100%", position: 'absolute', maxWidth: "160px", right: 0, top: -80, height: 270 }} />
            <img src={recipeDesign2} style={{ width: "100%", position: 'absolute', left: 0, top: 80, maxWidth: "115px", height: 122 }} />
            <img src={recipeDesign10} style={{ width: "100%", position: 'absolute', right: 0, maxWidth: "180px", top: 420, height: 380 }} />

            <StyledTypography className="bestFeatures">The Best Features For Preserve Your Family Tree</StyledTypography>
            <StyledTypography className="bestFeaturesdescription">Lorem ipsum dolor sit amet consectetur. Urna risus morbi nulla sem sollicitudin. Egestas mus amet vitae mattis nunc. Adipiscing et sit quis cras cursus sed. Risus.</StyledTypography>
            <Box style={{ width: "90%", maxWidth: "950px", marginTop: 40 }}>
              {landingData[0]?.landing_page_features?.length ? (
                landingData[0].landing_page_features.map((feature: any, index: any) => (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: '#FFFFFF',
                      marginBottom: 60,
                      height: 180,
                      display: 'flex',
                      position: 'relative',
                      borderRadius: 8,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <img
                      data-test-id="imageElement"
                      src={this.getImageSource(index)}
                      alt={`Feature ${index}`}
                      style={{ width: 60, height: 60, margin: "auto", padding: 20 }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20 }}>
                      <StyledTypography className="recipeFeatureTitle"
                       dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feature.title) }}>
                        
                      </StyledTypography>
                      <StyledTypography  className="recipeFeatureSubTitle"
                       dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feature.description) }}>

                      </StyledTypography>
                    </Box>
                    <img
                      src={recipeDesign9}
                      style={{
                        width: "100%",
                        maxWidth:"160px",
                        position: 'absolute',
                        height: 200,
                        top: 130,
                        zIndex: "-1",
                        [index % 2 === 0 ? 'right' : 'left']: -20,
                      }}
                      alt="Design 9"
                    />
                  </Box>
                ))
              ) : (
                <Typography>No features available</Typography>
              )}
            </Box> 
          </Box>

        </Box>
        <Box style={{marginLeft:50}}>
        <Box style={{ margin: 'auto', maxWidth: 1240, width: '100%' }}>
          <StyledTypography className="frequentlyTextRecipe">Frequently asked questions</StyledTypography>
          <StyledTypography className="simpleTextRecipe">Simple, transparent pricing that grows with you. Try any plan free for 30 days.</StyledTypography>
        </Box>
        {this.renderFAQSection()}
        <Box style={{ position: 'relative' }}>
          <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
            <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', height: 420, top: -300, zIndex: -1, right: 0 }} />
            <StyledTypography className="more-question-title">Do You Have More Questions about Building Your Family Tree?</StyledTypography>
            <StyledTypography className="more-question-subtitle">Sign up today to begin developing your family tree. If you have any additional questions about our service, please don’t hesitate to contact MyKinship.</StyledTypography>
          </Box>
        </Box>
        </Box>
                <Footer navigation={this.props.navigation} id={""} />
            </Box>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    padding: "0.5rem 1rem",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    minHeight: "12rem",
    gap: "2rem",
  },
  HeaderTextTree: {
    fontSize: 72,
    fontWeight: 400,
    color: '#305328',
    fontFamily: 'Abygaer',
    marginTop: 130,
  },
  subHeaderTree: {
    color: '#305328',
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 24,
    marginTop:20
  },
  backgroundImage: {
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${familyTreeBanner})`,
    backgroundSize: 'cover',
    width: '100%',
    maxWidth: "1635px",
    height: '614px',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: "end",
  },
  continueButtonTree: {
    backgroundColor: "#8CA74B",
    borderRadius: '8px',
    height: '56px',
    width: '292px',
    marginTop: '52px',
  },
  continueTextTree: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: 'none'
  },
  familyTextTree: {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48
  },
  textContainer: {
    width: "100%",
    maxWidth: "656px"
  },
  familyText2: {
    color: "#305328",
    fontWeight: 700,
    fontSize: 46,
    textAlign: 'right',
    width: "100%",
    maxWidth: "720px",
    lineHeight:'56px',
    fontFamily:'Poppins'
  },
  contentText: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Poppins",
    color: "#1C2110",
    marginTop:20
  },
  bestFeaturesTree: {
    color: "#305328",
    fontWeight: 700,
    fontSize: 48,
    fontFamily: "Poppins",
    marginTop: 30,
    width:"100%",
    maxWidth:"900px",
    display:"flex",
    textAlign:"center"
  },

  bestFeatures2Tree: {
    color: "#5D6063",
    fontFamily: "Poppins",
    width: "100%",
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    maxWidth: "900px"
  },
  backgroundImageFeature: {
    backgroundImage: `url(${familyTreeBg})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '1300px',
    display: 'flex',
    backgroundSize: 'cover',
    flexDirection: "column",
    alignItems: 'center',
  },
  frequentlyText: {
    fontSize: 36,
    fontFamily: 'Poppins',
    color: '#1C1917',
    fontWeight: 700,
    marginTop: 100
  },
  simpleText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#5D6063'
  },

  moreQuestions: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 36,
    color: "#1C1917"
  },
  moreQuestions2: {
    fontWeight: 400,
    color: "#5D6063",
    fontFamily: "Poppins",
    fontSize: 18,
    marginTop: 10,
    marginBottom: 70
  },
  workStorageBox:
  {
    display: "flex",
    gap: 80,
    justifyContent: 'center',
    marginTop: 60,
    position: 'relative'
  },
  familyTreeDescription: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110"
  },
  featureTitle: {
    fontWeight: 700,
    fontSize: '2rem', 
    fontFamily: 'Poppins',
    color: "#305328",
    
    "@media (max-width: 390px)": { 
     
      color:"red",
      marginTop:"5px"
     },
    '@media (max-width:600px)': {
      fontSize: '1.5rem', 
    },
    '@media (min-width:601px) and (max-width:960px)': {
      fontSize: '1.75rem', 
    },
    '@media (min-width:961px)': {
      fontSize: '2.75rem', 
    },
  },
  featureDescription: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: '1.25rem',
    color: "#666",
    '@media (max-width:600px)': {
      fontSize: '0.875rem',
    },
    '@media (min-width:601px) and (max-width:960px)': {
      fontSize: '1rem', 
    },
  },
};

const FamiltTreeStyle = styled("div")({
  justifyContent: 'center',
  marginTop: 60,
  display: 'flex',
  gap: "50px",
  width: "90%",
  marginInline: "auto",
  flexWrap: "wrap",
  "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
      flexDirection: "column",
      alignItems: "center",
      gap: "20px", 
      padding: "1rem",
  },
  "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
    padding: "1rem",  
    flexDirection: "column",
      alignItems: "center",
      
      gap: "20px", 
  },
  "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
    padding: "1rem",
    gap: "20px",   
    flexDirection: "column",
      alignItems: "center",
     
  },

  "& .priceMainBox3": {
    justifyContent: 'space-between',
    display: 'flex',
    width: '94%',
  },
  "& .priceBox3": {
    borderRadius: '16px',
    width: '370',
    height: '380',
    boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
      width: '100%', 
    },
    "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
        width: '100%',
    },
    "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
        width: '100%',
    },
  },
  "& .priceTitle3": {
    lineHeight: "44px",
    fontWeight: 700,
    marginBottom: '5px',
    fontSize: "36px",
    fontFamily: 'Poppins',
    color: '#1C2110',
    letterSpacing: '-1%',
    marginTop: '40px',
    textAlign: 'center'
  },
  "& .priceSubTitle3": {
      backgroundColor: 'transparent !important',
      fontWeight: 400,
      padding: "18px 22px 18px 22px",
      fontSize: "18px",
      fontFamily: 'Poppins',
      color: '#1C2110',
      lineHeight: "28px",
      textAlign: 'center',
      boxSizing: "border-box",
  },
  "& .priceSubTitle3 span": {
      backgroundColor: "transparent !important",
  },
  "& .priceCenterDiv3": {
    height: '140px',
    backgroundColor: "#E8EDDB",
    textAlign: 'center',
    display: "flex",
    alignItems: "center",
    marginTop: 40
  },

  "& .priceCenterBox": {
    height: '140px',
    backgroundColor: "#557C29",
  },
  "& .priceTag3": {
    lineHeight: "56px",
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#FFFFFF',
    fontSize: "64px",
    textAlign: 'center',
    letterSpacing: '-1.5%',
    padding: '13px',
  },
  "& .dollar3": {
    lineHeight: "56px",
    fontSize: "32px",
    fontFamily: 'Inter',
    fontWeight: 300,
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText3": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    marginBottom: '20px',
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText3": {
    lineHeight: "28px",
    fontSize: "20px",
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#1C2110',
    marginBottom: '40px',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn3": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    marginTop: '40px',
    marginBottom: '48px',
    padding: '10px',
    marginLeft: "auto",
    marginRight: "auto",
    textTransform:"none",
    display: "block",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#FFFFFF',
    }
  }
})


// Customizable Area End
