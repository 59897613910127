import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import SharingDashboardController, {
    Props,
} from "./SharingDashboardController";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { trashIcon } from "./assets";
import SideDrawer from "../../../components/src/SideDrawer";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MyFiles from "./MyFiles.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
// Customizable Area End

export default class SharingDashboard extends SharingDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SharingDashboardZeroStyle>
                <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Sharing"/>
                <Box className="MainContent">
                    <Box className="Header">
                        <HeaderDropdown
                            data-test-id="headerComponent" navigation={this.props.navigation} id={""}
                        />
                    </Box>
                    <Typography className="page-title">
                        Sharing
                    </Typography>
                    {this.state.showShareContent ? (
                        <MyFiles navigation={undefined} id={""} />
                    ) : (
                        <Box className="centerDiv">
                            <img className="img" src={trashIcon} alt="Trash Icon" />
                            <Typography className="welcomeTitle">Nothing Shared Yet</Typography>
                                <Typography className="sublabel">Share photos, videos, or memories with your loved ones. Your shared items will appear here.</Typography>
                                <Button className="shareBtn" data-test-id="shareBtn"
                                ><Typography className="shareBtnText">Start Sharing</Typography></Button>
                        </Box>
                    )}
                </Box>
            </SharingDashboardZeroStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
export const SharingDashboardZeroStyle = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    "& .img": {
        height: '239px',
        width: '239px'
    },
    "& .centerDiv": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '50px',
        marginBottom: '50px'
    },
    "& .welcomeTitle": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        lineHeight: '40px',
        fontWeight: 700,
        letterSpacing: '-0.5%',
        marginTop: '30px'
    },
    "& .sharingulDiv": {
        marginTop: '30px',
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
    },
    "& .MainContent": {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    "& .sublabel": {
        fontFamily: "Poppins",
        color: "#000000",
        fontSize: "16px",
        lineHeight: '24px',
        fontWeight: 400,
        maxWidth: "40%",
        textAlign: "center",
        marginTop: "15px",
        "@media (max-width: 950px)": { 
        maxWidth: "80%"
      },
    },
    "& .page-title": {
      fontSize: "30px",
      fontWeight: 700,
      color: "#1C2110",
      marginLeft: "25px",
      marginTop: "20px",
      "@media (max-width: 950px)": { 
        fontSize: "24px",
      },
    },
    "& .Content": {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginLeft: '20px'
    },
    "& .sharingliText": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "16px",
        lineHeight: '24px',
        fontWeight: 400,
    },
    "& .shareBtn": {
        backgroundColor: "#8CA74B",
        height: '56px',
        borderRadius: '8px',
        padding: '4px',
        width: '162px',
        marginTop: '40px',
        color: "#FFFFFF",
    },
    '& .shareBtnText': {
        marginLeft: '8px',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        color: "#FFFFFF",
        textTransform: "none",
    },
    "@media (max-width: 771px)": { 
        flexDirection: 'column',
        "& .centerDiv": {
            marginTop: '30px',
            marginBottom: '30px',
        },
        "& .sharingulDiv": {
            marginTop: '20px',
        },
        "& .sharingliText": {
            fontSize: "14px",
        },
        "& .shareBtn": {
            width: '120px',
            height: '45px',
        },
        "& .shareBtnText": {
            fontSize: "12px",
        },
        "& .img": {
            height: '180px',
            width: '180px',
        },
        "& .welcomeTitle": {
            fontSize: "22px",
            lineHeight: "32px",
        },
    },
    "@media (max-width: 1029px)": { 
        "& .welcomeTitle": {
            fontSize: "26px",
            lineHeight: "36px",
        },
        "& .img": {
            height: '200px',
            width: '200px',
        },
        "& .shareBtn": {
            width: '140px',
            height: '50px',
        },
        "& .shareBtnText": {
            fontSize: "14px",
        }
    },
    
});
// Customizable Area End
