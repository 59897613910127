import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: NavigationProp<ParamListBase>;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean;
  isLoading: boolean;
  inCorrectPwdMsg: string;
  currEmail: string;
  currName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VerifyPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPassword: false,
      isLoading: false,
      inCorrectPwdMsg: "",
      currEmail: "",
      currName: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.setState({ isLoading: false })
    if (message.id === getName(MessageEnum.SessionResponseMessage)) {
      return;
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.setState({ isLoading: false })

      if (responseJson && !responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  verfiyCurrentPasswordId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getUserData();
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: {message: boolean}) => {
    if (apiRequestCallId === this.verfiyCurrentPasswordId){
      if (responseJson?.message){
        this.navigateToPage("ResetPassword")
      }
      else {
        this.setState({
          inCorrectPwdMsg: "Incorrect password. Please try again."
        })
      }
    }
  }

  handleClickShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword
  });

  callApi = (payload: any, runEngine: any) => {
    const { contentType, method, endPoint, body, headers } = payload;
    const header = {
      "Content-Type": contentType,
      ...headers,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  verfiyCurrentPassword = async (currPassword: string) => {
    this.setState({ isLoading: true })
    const authToken = await getStorageData("token")

    const payload = {
      current_password: currPassword
    };

    this.verfiyCurrentPasswordId = this.callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiPOSTMethodType,
      endPoint: `${(configJSON.verfiyPasswordEndpoint as string)}`,
      body: payload,
      headers: { "token": authToken},
    }, runEngine);
  }

  getUserData = async () => {
    const fullName = await getStorageData("fullName");
    const email = await getStorageData("email");
    this.setState({
      currEmail: email,
      currName: fullName
    })
  }

  navigateToPage = (navigateTo: string) => {

    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      navigateTo
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);

  }
  // Customizable Area End
}
