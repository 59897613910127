export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backArrow = require("../assets/backArrow.png");
export const loginImage = require("../assets/image_.png");
export const Logo = require("../assets/image_Logo.png");
export const backImage = require("../assets/backimage_.png");
export const notValidate = require("../assets/cancel.svg");
export const validate = require("../assets/check.svg");
export const image = require("../assets/fa-image.png");
export const groupLogo = require("../assets/Group.png");
export const FbIcon = require("../assets/group_fb.png");
export const YoutubeIcon = require("../assets/youtubeIcon.png");
export const InstagramIcon = require("../assets/instaIcon.png");
export const XIcon = require("../assets/group_x.png");
export const BackArrowBlack = require("../assets/arrowBlack.png");
export const mail = require("../assets/mail.png");
export const bgImage = require("../assets/bgImage.png");
export const checkImg = require("../assets/checkImg.png");