Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiGETMethodType = "GET";
exports.validationApiPOSTMethodType = "POST";
exports.validationApiPUTMethodType = "PUT";

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PrivacySettings";
exports.labelBodyText = "PrivacySettings Body";

exports.labelAnyone = "anyone";
exports.labelFriends = "friends";
exports.labelFamily = "family";
exports.labelComment = "comment";
exports.labelView = "view";
exports.nullView = "none";
exports.labelPhotoConsent = "Photo";
exports.labelPostConsent = "Post";
exports.labelVideoConsent = "Video";
exports.privacySettingsText = "Privacy settings";
exports.nameText = "User name";
exports.descriptionText = "Description";
exports.btnExampleTitle = "CLICK ME";
exports.labelConsentTo = "Consent to:";
exports.labelAccessLevel = "Access level:";
exports.updateBtnText = "Update Privacy settings";
exports.editBtnText = "Edit Privacy settings";
exports.createdSuccessfullyText = "Created Success";
exports.uploadFailedText = "Upload Failed";
exports.retryText = "Retry!";
exports.emptyText = "";
exports.endPointIssueMessage = "privacy_setting endpoint issue";
exports.loginEndPoint = "bx_block_login/logins";
exports.allDataErrorMessage = "Add all data to create privacy settings";
exports.alreadySelectedText = "already selected";
exports.labelUpdateSettings = "Update Privacy settings";
exports.labelEditSettings = "Edit Privacy settings";

exports.createPrivacySettings = "bx_block_privacy_setting/privacy_settings";
exports.updatePrivacySettings =
  "bx_block_privacy_setting/privacy_settings/update_settings";
exports.getUserPrivacySettings =
  "bx_block_privacy_setting/privacy_settings/user_privacy_settings";

exports.verfiyPasswordEndpoint = "account_block/verify_password";
exports.getAccountDetailsEndpoint = "account_block/accounts";
exports.toggleTwoFaEndpoint = "account_block/two_factor_enabled";
// Customizable Area End
