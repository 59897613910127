import React from "react";
// Customizable Area Start
import {
    Box,
    Checkbox,
    IconButton,
    Typography
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShowCreateGroupsController, {
    Props,
} from "./ShowCreateGroupsController";
import { avatar, groupImg } from "./assets";
// Customizable Area End

export default class ShowCreateGroup extends ShowCreateGroupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ShowCreateGroupStyle>
                <Box style={{ display: 'flex' }}>
                    <Box className="groupExpandDiv">
                        <Box>
                            <img src={avatar} className="avtarImg" />
                            <img src={avatar} className="avtarImg2" />
                        </Box>
                        <Box className="centerImgBox">
                            <img src={groupImg} style={{ width: '170px' }} />
                        </Box>
                        <Box className="boxLastDiv">
                            <Typography className="photosText">My Photos</Typography>
                            <IconButton className="iconBtnStyle"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></IconButton>
                        </Box>
                    </Box>
                    <Box className="groupExpandDiv">
                        <Box>
                            <img src={avatar} className="avtarImg" />
                            <img src={avatar} className="avtarImg2" />
                            <img src={avatar} className="avtarImg2" />
                        </Box>
                        <Box className="centerImgBox">
                            <img src={groupImg} style={{ width: '170px' }} />
                        </Box>
                        <Box className="boxLastDiv">
                            <Typography className="photosText">My Family</Typography>
                            <IconButton className="iconBtnStyle"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></IconButton>
                        </Box>
                    </Box>
                </Box>
            </ShowCreateGroupStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const ShowCreateGroupStyle = styled("div")({
    "& .groupExpandDiv": {
        padding: '20px',
        width: '200px',
        borderRadius: '8px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
    },
    "& .photosText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        color: '#1C1917'
    },
    "& .centerImgBox": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
        marginBottom: '19px'
    },
    "& .boxLastDiv": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .iconBtnStyle": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '24px',
        padding: '0'
    },
    "& .avtarImg": {
        height: '26px',
        width: '26px'
    },
    "& .avtarImg2": {
        marginLeft: '-15px',
        height: '26px',
        width: '26px'
    }
});
// Customizable Area End
