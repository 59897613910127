import React from "react";

import {
  Button,
  // Customizable Area Start
  Box,
  styled,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import VerifyPasswordController, {
  Props,
} from "./VerifyPasswordController";
import { ErrorMessage, Formik } from "formik";
import { backArrow, backImage, Logo } from "../../forgot-password/src/assets";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";

export default class VerifyPassword extends VerifyPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  verfiyPasswordSchema = Yup.object({
    password: Yup.string()
      .required("Please enter your password"),
  });


  renderVisibilityIcons = (show: boolean) => {
      return show ? (
        <VisibilityOff
          style={{
            width: "22px",
            height: "19px",
            color: "#94A3B8"
          }}
        />
      ) : (
        <Visibility
          style={{
            width: "22px",
            height: "19px",
            color: "#94A3B8"
          }}
        />
      );
    };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <VerifyPasswordStyle>
        <Button data-test-id="backArraowBtn" className="backBtn" onClick={() => this.props.navigation.goBack()}>
          <img src={backArrow} alt="Back" />
        </Button>
        <Box className="mainContainerDiv">
          <Box className="formContainerDiv">
            <Box className="imageLogo">
              <img style={{ height: '70px' }} src={Logo} alt="Logo" />
            </Box>
            <Formik
              data-test-id="formikForm"
              initialValues={{
                password: "",
              }}
              validationSchema={this.verfiyPasswordSchema}
              onSubmit={values => {
                this.verfiyCurrentPassword(values.password);
              }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <form className="formContainerDiv" onSubmit={handleSubmit}>
                  {this.state.inCorrectPwdMsg && (
                    <Alert
                      className="alertContainer"
                      severity="error"
                      icon={false}
                      style=
                      {{
                        display: 'block',
                        marginTop: '32px',
                        color: "#DC2626"
                      }}
                    >
                      {this.state.inCorrectPwdMsg}
                    </Alert>
                  )}
                  <Typography className="mainFormTitle">Hey, {this.state.currName}</Typography>
                  <>
                    <Typography className="forgotTextTitles">Please to continue, first type the password
                      <br />
                      for
                      <span style={{fontWeight: "bold"}}> {this.state.currEmail} </span></Typography>
                    <Typography className="FieldText">Password</Typography>
                    <TextField
                      data-test-id="password-field"
                      className="placeholderTexts"
                      fullWidth
                      onChange={handleChange}
                      value={values.password}
                      autoComplete="off"
                      placeholder="Your password"
                      name="password"
                      type={!this.state.showPassword ? "password" : "text"}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.handleClickShowPassword}
                              edge="end"
                              data-test-id="showPasswordIconButton"
                              id="passwordConfirmationInput"
                            >
                              {this.renderVisibilityIcons(
                                !this.state.showPassword
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage name="password" component="div" className="errorMessages" />
                    <Typography className="forgot-password-txt" onClick={() => this.navigateToPage("ForgotPassword")}>
                      Forgot password?
                    </Typography>
                    <Button type="submit" data-test-id="signupButton" className="recoveryLinkBotton" variant="contained" color="primary" fullWidth >
                      Next
                    </Button>
                  </>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        {
          this.state.isLoading &&
          <>
            <Backdrop style={{ color: "#8CA74B", zIndex: 9999 }} open={this.state.isLoading}>
              <CircularProgress color="inherit" style={{
                height: "75px",
                width: "75px"
              }} />
            </Backdrop></>
        }
      </VerifyPasswordStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const VerifyPasswordStyle = styled("div")({
    height: "100vh",
    overflow: "hidden",
    backgroundImage: `url(${backImage})`,
    width: "100%",
    display: "flex",
    backgroundColor: "#305328",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",

    "& .backBtn": {
        position: "fixed",
        top: "20px",
        left: "20px",
        zIndex: 9999,
    },
    "& .forgot-password-txt": {
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: "bold",
      fontSize: "14px",
      cursor: "pointer",
      color: "#557C29",
      marginTop: "5px"
    },
    "& .mainContainerDiv": {
        backgroundColor: "white",
        borderRadius: "20px",
        width: "440px",
        padding: '40px',
        overflow: "hidden",
      "@media (max-width: 500px)": {
          width: "80%"
      },
    },
    "& .scrollableContent": {
        maxHeight: "calc(100vh - 160px)",
        overflowY: "auto",
    },
    "& .formContainerDiv": {
        width: "100%"
    },
    "& .imageLogo": {
        padding: "0px, 0px, 0px, 40px",
    },
    "& .mainFormTitle": {
        color: "#1C2110",
        marginTop: "32px",
        marginBottom: "15px",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
    },
    "& .alertContainer": {
        borderRadius: '4px',
        border: '0px, 0px, 0px, 4px',
        borderLeft: '4px solid #DC2626',
        padding: "12px, 16px, 12px, 16px",
        fontSize: "12px",
        foneFamily: "Poppins",
        fontWeight: 400,
    },
    "& .errorMessages": {
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "18px",
        fontSize: "13px",
        color: '#DC2626',
        marginTop: '2px'
    },
    "& .forgotTitle": {
        marginBottom: "16px",
        marginTop: "8px",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    "& .forgotTextTitles": {
        fontFamily: "Poppins",
        marginTop: '8px',
        fontSize: "16px",
        fontWeight: 400,
        color: "#202020",
        lineHeight: "24px",
        marginBottom: "15px"
    },
    "& .recoveryLinkBotton": {
        borderRadius: "8px",
        backgroundColor: '#8CA74B',
        height: "56px",
        Gap: "8px",
        padding: "16px",
        fontWeight: 700,
        marginTop: "32px",
        lineHeight: "24px",
        fontSize: "16px",
        '& .MuiButton-label': {
            textTransform: "none",
            fontWeight: 700,
            fontFamily: "Poppins",
        }
    },
    "& .accountContainer": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginTop: "16px"
    },
    "& .FieldText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginTop: '16px',
        marginBottom: '4px'
    },
    "& .placeholderTitle": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#94A3B8"
    },
    "& .accountTextTitle": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#1C2110"
    },
    "& .loginBotton": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#557C29",
        fontStyle: "Poppins",
        '& .MuiButton-label': {
            fontWeight: 700,
            fontFamily: "Poppins",
            textTransform: "none"
        }
    },
    "@media (max-width: 480px)": {
        "& .mainFormTitle": {
            fontSize: "18px",
            lineHeight: "24px",
        },
        "& .mainContainerDiv": {
            padding: "16px",
            width:"325px"
        },
        "& .forgotTitle": {
            fontSize: "12px",
            lineHeight: "16px",
        },
        "& .forgotTextTitles": {
            fontSize: "12px",
            lineHeight: "16px",
        },
        "& .recoveryLinkBotton": {
            fontSize: "12px",
            height: "40px",
        },
        "& .backBtn": {
            top: "10px",
            left: "10px",
        },
    }
});
// Customizable Area End
