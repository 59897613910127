import React from "react";
// Customizable Area Start
import { Box, Typography, Button } from "@material-ui/core";

import HeaderDropdown from "../../../blocks/navigationmenu/src/HeaderDropdown.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { SharingDashboardZeroStyle } from "../../../blocks/dashboard/src/SharingDashboard.web";
// Customizable Area End

import FavouritesController, { Props } from "./FavouritesController";
import { favoriteIcon } from "./assets";

export default class Favourites extends FavouritesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <SharingDashboardZeroStyle>
        <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Favorites" />
        <Box className="MainContent">
          <Box className="Header">
            <HeaderDropdown
              data-test-id="headerComponent" navigation={this.props.navigation} id={""}
            />
          </Box>
          <Typography className="page-title">
          Favorites
          </Typography>
            <Box className="centerDiv">
              <img className="img" src={favoriteIcon} alt="Trash Icon" />
              <Typography className="welcomeTitle">No Favorites Yet</Typography>
              <Typography className="sublabel">Start marking photos, recipes, or memories as favorites! Your most-loved items will appear here.</Typography>
              <Button className="shareBtn" data-test-id="add-favorite-btn" 
              ><Typography className="shareBtnText">Add Favorites</Typography></Button>
            </Box>
        </Box>
      </SharingDashboardZeroStyle>
    );
  }
}

// Customizable Area Start
// Customizable Area End
