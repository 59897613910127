// Customizable Area Start
import React from "react";
import BlogPostsManagementController, { Props } from "./BlogPostsManagementController";
import { backIcon, leftIcon, rightIcon } from "./assets";
import { Box, Card, CardActionArea, CardContent, CardMedia, IconButton, InputBase, Paper, Typography, styled } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Header from "../../../components/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import DOMPurify from 'dompurify';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SearchDetailsPaper = styled(Paper)({
  padding: "2px 4px",
  display: "flex",
  width: 780,
  alignItems: "center",
  height: 44,
  marginTop: 108,
  borderRadius: "40px",
  border: "1px solid #8CA74B",
  '@media(max-width: 900px)': {
    padding: "2px 4px",
    display: "flex",
    height: 44,
    alignItems: "center",
    width: 780,
    marginTop: 108,
    borderRadius: "40px",
    border: "1px solid #8CA74B",
    marginLeft: '10px'
  },
  '@media(max-width: 600px)': {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    height: 44,
    width: 780,
    marginTop: 108,
    borderRadius: "40px",
    border: "1px solid #8CA74B",
    marginLeft: '10px'
  }
});

const AllCategoriesDetailsBox = styled(Box)({
  cursor: "pointer",
  background: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 14,
  borderRadius: 8,
  border: `1px solid #8CA74B`,
  fontSize: 16,
  fontWeight: 500,
  color: "#8CA74B",
  '@media(max-width: 900px)': {
    cursor: "pointer",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 14,
    borderRadius: 8,
    fontWeight: 500,
    fontSize: 16,
    border: `1px solid #8CA74B`,
    color: "#8CA74B",
    marginRight: '10px'
  },
  '@media(max-width: 600px)': {
    cursor: "pointer",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 14,
    fontWeight: 500,
    border: `1px solid #8CA74B`,
    fontSize: 16,
    color: "#8CA74B",
    marginRight: '10px'
  }
});
const BlogTypography = styled(Typography)({
  color: '#78716C',
  fontSize: 20,
  fontFamily: 'Poppins',
  fontWeight: 400,
  marginLeft: '1%',
  borderBottom: '2px solid #78716C',
  width: 20,
  display: 'inline-block',
  paddingBottom: '10px',
  '@media(max-width: 960px)': {
    color: '#78716C',
    fontSize: 20,
    fontWeight: 400,
    marginLeft: '2%',
    fontFamily: 'Poppins',
    borderBottom: '2px solid #78716C',
    width: 20,
    display: 'inline-block',
    paddingBottom: '10px',
  },
  '@media(max-width: 600px)': {
    color: '#78716C',
    fontWeight: 400,
    fontSize: 16,
    fontFamily: 'Poppins',
    marginLeft: '3%',
    borderBottom: '2px solid #78716C',
    width: 20,
    display: 'inline-block',
    paddingBottom: '10px',
  },
})

const BlogTextLineBox = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 10,
  borderBottom: '2px solid #78716C',
  width: '6%',
  zIndex: -1,
  '@media(max-width: 900px)': {
    position: 'absolute',
    left: '18px',
    bottom: 0,
    width: '7%',
    borderBottom: '2px solid #78716C',
    zIndex: -1,
  },
  '@media(max-width: 600px)': {
    position: 'absolute',
    bottom: 0,
    width: '11%',
    left: 15,
    borderBottom: '2px solid #78716C',
    zIndex: -1,
  }
});

const TitleContentTypography = styled(Box)({
  color: '#1C2110',
  marginTop: 30,
  fontFamily: 'Poppins',
  fontSize: 48,
  fontWeight: 700,
  '@media(max-width: 900px)': {
    color: '#1C2110',
    fontFamily: 'Poppins',
    marginTop: 30,
    fontWeight: 700,
    fontSize: 40,
    marginLeft: 10
  },
  '@media(max-width: 600px)': {
    color: '#1C2110',
    fontSize: 24,
    fontFamily: 'Poppins',
    marginTop: 30,
    fontWeight: 700,
    marginLeft: 10
  }
});

const AuthorTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: 18,
  '@media(max-width: 900px)': {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 18,
    marginLeft: 10
  },
  '@media(max-width: 600px)': {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 18,
    marginLeft: 10
  }
});

const ParentBox = styled(Box)({
  '@media(max-width: 1050px)': {
    paddingInline: '12px',
    width: 'unset !important'
  }
})

const CookingBox = styled(Box)({
  width: 115,
  backgroundColor: "#E8FFB1",
  borderRadius: 28,
  '@media(max-width: 900px)': {
    width: 115,
    backgroundColor: "#E8FFB1",
    borderRadius: 28,
    marginLeft: 10
  },
  '@media(max-width: 600px)': {
    width: 115,
    backgroundColor: "#E8FFB1",
    borderRadius: 28,
    marginLeft: 10
  }
});
const BakingBox = styled(Box)({
  '@media(max-width: 960px)': {
    flexDirection: 'column',
    '& .bakingcontent': {
      borderTop: 'unset !important',
      borderLeft: '2px solid #8CA74B',
      borderRadius: '0px 0px 16px 16px !important'
    }
  },
  '@media(max-width: 480px)': {
    flexDirection: 'column',
    '& .bakingcontent': {
      border: 'none !important',
      borderRadius: '0px !important'
    }
  },
})
const DetailsCustomImage = styled('img')({
  maxWidth: "598px",
  width: "100%",
  height: "400px",
  borderRadius: "16px",
  objectFit: 'cover',
});
const Description = styled(Typography)({
  fontSize: 18, 
  fontWeight: 400, 
  fontFamily: 'Poppins', 
  marginTop: 20, 
  width: "1016px",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",

  '@media (max-width: 1050px)': {
    width: '960px',
    fontSize: 16,
  },
  '@media (max-width: 820px)': {
    width: '780px',
    fontSize: 16,
  },
  '@media (max-width: 768px)': {
    width: '72%',
    fontSize: 17,
    marginTop: 15,
  },
  '@media (max-width: 600px)': {
    width: '85%',
    fontSize: 14,
    marginTop: 12,
  },
  '@media (max-width: 490px)': {
    width: '36%',
    fontSize: "15px",
    marginTop: 10,
  },
  '@media (max-width: 375px)': {
    width: '95%',
    fontSize: 12,
  }
});

const SliderBox = styled(Box)({
  width: 1160,
  '@media(max-width: 1050px)': {
    width: '735px'
  },
  '@media(max-width: 767px)': {
    maxWidth: '500px',
    width: '100%',
  },
  '@media(max-width: 500px)': {
    gap: '15px !important'
  },
  '@media(max-width: 330px)': {
    gap: '0px !important'
  },
  '@media(max-width: 400px)': {
    '& .slider-card': {
      maxWidth: '240px !important'
    },
    '& .slider-arrow': {
      width: '20px !important',
      height: '20px !important',
    }
  },
});
export default class BlogpostsDetails extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { currentIndex, showBlogs,cardsPerRow } = this.state;
    return (
      // Customizable Area Start
      <Box>
        {
          this.state.authToken ? (
            <HeaderDropdown
              data-test-id="headerComponenttestid"
              navigation={this.props.navigation}
              id={""}
            />
          ) : (
            <Header onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => { }} />
          )
        }
        <ParentBox sx={webStyle.container}>
          <Box sx={webStyle.header}>
            <img src={backIcon} style={webStyle.backIcon} onClick={this.handleBackClick} data-test-id="backIconTest" />
            <SearchDetailsPaper
              component="form"
              onSubmit={this.handleSubmit}
            >
              <IconButton type="button" style={{ color: "#8CA74B", padding: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                data-test-id="inputBoxId"
                style={{ marginLeft: 1, flex: 1, color: "rgb(103, 147, 8)", fontSize: 18, fontFamily: "Poppins", fontWeight: 500 }}
                value={this.state.searchText}
                placeholder="Search"
                onChange={(e) => this.handleChange(e)}
                inputProps={{ className: "custom-input" }}

              />
              {this.state.searchText && (
                <IconButton data-test-id="clearBtn" onClick={this.handleClear} style={{ padding: "10px", color: "#8CA74B" }} aria-label="clear">
                  <ClearIcon />
                </IconButton>
              )}


            </SearchDetailsPaper>
            <div style={{ fontFamily: "sans-serif" }}>
              <div
                style={{
                  margin: "0 auto",
                  zIndex: 10000,
                  width: "188px",
                  marginTop: 92,
                  height: 55,
                  position: "relative",
                  marginLeft: 30,
                }}
              >
                <AllCategoriesDetailsBox
                  onClick={this.handleToggle.bind(this)}
                >
                  {this.state.selected}
                  {this.state.isActive ? (
                    <KeyboardArrowUpIcon
                      style={{
                        transition: 'transform 0.3s ease',
                        color: '#8CA74B',
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{
                        transition: 'transform 0.3s ease',
                        color: '#8CA74B',
                      }}
                    />
                  )}
                </AllCategoriesDetailsBox>
                <div
                  style={{
                    position: "absolute",
                    boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
                    width: "100%",
                    left: 0,
                    borderRadius: 8,
                    display: this.state.isActive ? "block" : "none"
                  }}
                >
                  {[
                    { name: "All categories", id: "all" },
                    ...this.state.categoriesData
                  ].map((option, index) => (
                    <div
                      key={index}
                      className="item"
                      style={{
                        padding: 10,
                        color: "#8CA74B",
                        cursor: "pointer",
                        backgroundColor: this.getBackgroundColor(option.name),
                        borderRadius: 4
                      }}
                      onClick={this.handleOptionSelect.bind(this, option)}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <Box display="inline-block" position="relative" mt={2}>
            <BlogTypography
            >
              BLOG
            </BlogTypography>
            <BlogTextLineBox
            />
          </Box>
          <Box sx={webStyle.detailsPage}>
            <TitleContentTypography>
              {showBlogs.title}
            </TitleContentTypography>
          </Box>
          <AuthorTypography style={{ fontFamily: 'Poppins', fontSize: 18, fontWeight: 400 }}>
            {showBlogs.author}
          </AuthorTypography>
          <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
            <CookingBox>
              <Typography
                style={{ color: "#557C29", textAlign: "center", padding: 5, fontSize: 12 }}
              >

                {showBlogs.category_name}
              </Typography>
            </CookingBox>

          </Box>
          <BakingBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
            <DetailsCustomImage src={showBlogs.attachment_urls[0].url} />
            <Box className="bakingcontent" style={{
              borderTop: '2px solid #8CA74B',
              borderBottom: '2px solid #8CA74B',
              borderRight: '2px solid #8CA74B',
              display: 'flex',
              padding: '25px',
              paddingRight: '30px',
              alignItems: 'flex-start',
              borderRadius: '0 16px 16px 0',
              height: "280px",
              overflowY: "auto",
              position: "relative"
            }}>
              <style>
              {`
                .bakingcontent::-webkit-scrollbar {
                  width: 4px; 
                }
                .bakingcontent::-webkit-scrollbar-thumb {
                  background: #8CA74B; 
                  border-radius: 10px;
                }
                .bakingcontent::-webkit-scrollbar-track {
                  background: transparent;
                  margin: 5px;
                }
              `}
              </style>
              <Typography style={{
                fontWeight: 700,
                fontFamily: 'Poppins',
                fontSize: 17,
                width: "362px",
              }}>
                {showBlogs.content}
              </Typography>
            </Box>
          </BakingBox>

          <Box style={{
            width: 1016,
          }}>
            <Description
              dangerouslySetInnerHTML={{ __html: showBlogs.description }}
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 20, position: 'relative' }}>
            <Typography style={{ fontSize: 30, fontWeight: 700, fontFamily: 'Poppins' }}>You may also like</Typography>
            <Box style={{ position: 'absolute', bottom: -10, width: 65, height: 2, backgroundColor: '#64748B' }} />
          </Box>

           <SliderBox style={{ display: 'flex', marginTop: 50, flexDirection: 'row', flexWrap: 'wrap', gap: '22px', marginInline: 50, alignSelf: 'center', justifyContent: 'center',
            marginBottom: '100px' }}>
            <img className="slider-arrow" src={leftIcon} style={{ width: '32px', height: '32px', display: 'flex', alignSelf: 'center', cursor: 'pointer' }} onClick={this.handleLeftClick} data-test-id="leftIcon" />
            {this.state.blogDetails.slice(currentIndex, currentIndex + cardsPerRow).map((data: any, index: any) => (
              <Card className="slider-card" key={index} style={{ maxWidth: 316, height: 490 }} onClick={this.gotoDestinationScreen.bind(this,data)}>
                <CardActionArea>
                  <Box
                    style={{
                      backgroundColor: "#E8FFB1",
                      borderRadius: 10,
                      width: 115,
                      marginLeft: 20
                    }}
                  >
                    <Typography
                      style={{ color: "#557C29", textAlign: "center", marginTop: 20, fontSize: 12 }}
                    >
                      {data.category_name}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <CardMedia
                      component="img"
                      style={{ borderRadius: 8, marginTop: 20, width: 270, height: 200 }}
                      image={data.attachment_urls[0].url}
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      style={webStyle.titleTextTypo as any}
                    >
                      <span
                        style={webStyle.titleText as any}
                      ></span>
                      {data.title}
                    </Typography>
                    <Typography
                      style={webStyle.truncateStyle}
                    >
                      {this.truncateText(data.content, 60)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
            <img className="slider-arrow" src={rightIcon} style={{ height: '32px', width: '32px', display: 'flex', alignSelf: 'center', cursor: 'pointer' }} onClick={this.handleRightClick} data-test-id="rightIcon" />
          </SliderBox>
        </ParentBox >
        <Footer navigation={this.props.navigation} id={""} />
      </Box>
      // Customizable Area End
    );
  }
}

const webStyle = {
  backIcon: {
    width: 32,
    height: 32,
    marginTop: 92,
    position: 'absolute' as 'absolute',
    left: -120,
    cursor: "Pointer"
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maxWidth: '1016px',
    margin: 'auto'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  searchIconStyle: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 780,
    height: 44,
    marginTop: 108,
    borderRadius: "40px",
    border: "1px solid #8CA74B",
  },
  dropdownStyle: {
    cursor: "pointer",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 14,
    borderRadius: 8,
    fontWeight: 500,
    border: `1px solid #8CA74B`,
    fontSize: 16
  },
  blogText: {
    color: '#78716C',
    fontWeight: 400,
    fontSize: 20,
    fontFamily: 'Poppins',
    marginLeft: "1%"
  },
  detailsPage: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  truncateStyle: {
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 400,
    marginTop: 30,
    marginLeft: 6,
    width: 230
  },
  titleText: {
    height: "100%",
    position: "absolute",
    top: 0,
    borderLeft: "7px solid #557C29",
    left: -15,
  },
  titleTextTypo: {
    fontSize: 16,
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginLeft: 5,
    maxWidth: "260px",
    width: "100%",
    height: 52
  }
};
// Customizable Area End
