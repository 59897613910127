import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import * as React from 'react';
import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPassword: boolean;
  formError: {
    fullName: string
    email: string
    password: string
  };
  apiError: string | null;
  isNewsChecked: boolean;
  agreeTerms: boolean;
  fullName: string,
  email: string,
  password: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validateAccountId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.InfoPageTitleMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPassword: false,
      formError: {
        fullName: '',
        email: '',
        password: '',
      },
      apiError: '',
      isNewsChecked: false,
      agreeTerms: false,
      fullName: "",
      email: "",
      password: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const signupValue = await message.getData(getName(MessageEnum.SignupPropsMessage));
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.validateAccountId
    ) {
      if (responseJson?.errors && responseJson?.errors?.length !== 0) {
        const errorMessage = "Email already Exists.";
        this.setState({
          formError: {
            fullName: '',
            email: '',
            password: '',
          },
          apiError: errorMessage,
        })
      }
      else {
        localStorage.setItem("email", this.state.email);
        localStorage.setItem("fullName", this.state.fullName);
        localStorage.setItem("isNewsChecked", String(this.state.isNewsChecked))
        localStorage.removeItem("agreeTerms")
        this.setState({ apiError: null });
        this.handleNavigateAuth()
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const formValuesJson = localStorage.getItem('formValues');
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    if (formValuesJson) {
      const storedFormValues = JSON.parse(formValuesJson);
      this.setState({
        fullName: storedFormValues.fullName,
        email: storedFormValues.email,
        password: storedFormValues.password,
        agreeTerms: storedFormValues.agreeTerms,
        isNewsChecked: storedFormValues.isNewsChecked
      })
    }
    const agreeTerms = localStorage.getItem("agreeTerms");
    if (agreeTerms === "true" && !this.state.agreeTerms) {
      this.setState({ agreeTerms: true });
    }
  }

  validatePassword = [
    {
      regex: configJSON.capital,
      text: "At least one capital letter"
    },
    {
      regex: configJSON.lower,
      text: "At least one lowercase letter"
    },
    {
      regex: configJSON.digit,
      text: "At least one number"
    },
    {
      regex: configJSON.length,
      text: "Minimum character length is 8 characters"
    }
  ];

  handleBeforeUnload = (event: Event) => {
    if (window.location.pathname === "/Signup") {
      event.preventDefault()
      localStorage.removeItem('formValues');
    }
  }

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isNewsChecked: event.target.checked })

  }

  handlePolicyCheckbox = (event: React.ChangeEvent<HTMLInputElement>, values: any) => {
    const checked = event.target.checked;
    this.setState((prevState) => {
      if (prevState.agreeTerms !== checked) {
        return { agreeTerms: checked, fullName: values.fullName, email: values.email, password: values.password };
      }
      return null;
    }, () => {
      localStorage.setItem("agreeTerms", this.state.agreeTerms.toString());
    });
  }  

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: "24px",
          height: "24px",
          color: "#94A3B8"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "24px",
          height: "24px",
          color: "#94A3B8"
        }}
      />
    );
  };

  signUpSchema = Yup.object({
    fullName: Yup.string()
      .required("Please enter your Full Name")
      .matches(
        /^[A-Za-z]+(?:\.| [A-Za-z]+)*(?: [A-Za-z]+)*(?: \.[A-Za-z]+)*\.?$/,
        'Invalid full name. Full name must contain only alphabets.'
      ),
    email: Yup.string()
      .email("Invalid e-mail")
      .required("Please enter your email address"),
    password: Yup.string()
      .matches(/^\S*$/, 'No White Space are allowed')
      .required("Please enter your password"),
    agreeTerms: Yup.boolean()
      .oneOf([true], "You have to agree with Privacy Policy and Terms and Conditions to Sign up.")
  });

  handleClickShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword
    });

  handlePolicyClick = (values: any) => {
    localStorage.setItem("formValues", JSON.stringify(values));
    this.handleLessonNavigation(values)
  }

  handleLessonNavigation = (values: any) => {
    const addMessage: Message = new Message(getName(MessageEnum.NavigationTermAndConditionMessage));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    addMessage.addData(getName(MessageEnum.InfoPageTitleMessage), values);
    this.send(addMessage);
  }
  checkValidatePassword = (password: string) => {
    let isValidPassword = true;
    this.validatePassword.forEach((item: { regex: RegExp }) => {
      if (!item.regex.test(password)) {
        isValidPassword = false;
      }
    });
    return isValidPassword;
  };

  submitValidateAccount = (full_name: string, email: string, password: string, isNewsChecked : boolean) => {
    const accountDetails = {
      type: 'email_account',
      attributes: {
        full_name: full_name,
        email: email,
        password: password,
        subscribe_to_newsletter: this.state.isNewsChecked
      },
    }

    this.setState({
      fullName: full_name,
      email: email,
      password: password,
      isNewsChecked:isNewsChecked
    })

    const header = {
      "Content-Type": 'application/json',
    };

    const httpBody = {
      data: accountDetails,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  handleNavigateLogin = () => {
    this.props.navigation.navigate("Login")
  }

  backArraowClick = () => {
    let path = localStorage.getItem("signUpCameFrom") || "/Login"

    const msgRequest = new Message(getName(MessageEnum.NavigationMessage));
    msgRequest.addData(getName(MessageEnum.NavigationTargetMessage), path.split('/')[1]);
    msgRequest.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgRequest.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgRequest)
  }

  handleNavigateAuth = () => {
    this.props.navigation.navigate("TwoFactorAuth");
  }
  // Customizable Area End
}
