import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  // Customizable Area Start
  Divider,
  Modal,
  FormControl,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { subscriptionLogo, peopleGroup, circleImage, subscriptionBackground, frGroup, downArrow, profileIcon, homeIcon, diversity } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";
import { outlineCheck } from "../../../blocks/landingpage/src/assets";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import Header from "../../../components/src/Header.web";
import DOMPurify from 'dompurify';
// Customizable Area End

import SubscriptionbillingController, {
  Props,
} from "./SubscriptionbillingController";


export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { expanded } = this.state;
    return (
      // Customizable Area Start
      <Box>
        <Box style={{ backgroundColor: '#FEFFFB' }}>
        {
            this.state.authToken ? (
              <HeaderDropdown
                data-test-id="headerComponent"
                navigation={this.props.navigation}
                id={""}
              />
            ) : (
              <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => 
                {}} />
            )
            }
          <Box style={{ position: 'relative' }}>
            <Box style={{ padding: 20 }}>
              <Box style={{ maxWidth: "85%", width: '100%', marginTop: 50, margin:'auto', }}>
                <HeaderTypography>Plans that fit your scale</HeaderTypography>
                <Typography style={webStyle.subHeaderText}>Simple, transparent pricing that grows with you. Try any plan free for 05 days.</Typography>
                <img src={circleImage} style={{ position: 'absolute', top: 10, right: 0, width: 160 }} />
                <img src={subscriptionBackground} style={{ position: 'absolute', bottom: 10, left: 0, width: 162 }} />
              </Box>
            </Box>
            <Box>


              <SubscriptionStyle>
                <Box className="priceMainBox">
                  {this.state.plansandPricing.map(plan => (
                   
                    
                    <Box key={plan.id} className="priceBox">
                      <Typography className="priceTitle">{plan.name}</Typography>
                        <Typography className="priceSubTitle"
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan.details) }}
                        >
                        </Typography>

                      <Box className="priceCenterDiv">
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography className="dollar"> $ </Typography>
                          <Typography className="priceTag"> {plan.price}</Typography>
                        </Box>
                        <Typography className="monthText">/ Month</Typography>
                      </Box>

                     
                      <Box style={{ display: "flex", justifyContent: "center", marginTop:20 }}>
                        <img src={outlineCheck} alt="check" />
                        <Typography style={{ color: '#1C2110', marginLeft: 10, fontSize: 18 }}>
                          {plan.storage} {plan.storage_unit} of storage
                        </Typography>
                      </Box>
                      {/* <Typography className="storageText"><img src={outlineCheck} alt="check icon" /> {plan.storage} {plan.storage_unit} of storage</Typography> */}
                      {
                        this.state.authToken ? (
                        <Button className="startBtn" data-test-id={`priceBtn${plan.id}`}
                         onClick={this.handleOpenModal.bind(this, plan)}
                        >Start now</Button>
                        ) : (
                        <Button className="startBtn" 
                        onClick={this.onRegisterClick}
                        >Start now</Button>
                      )}
                    </Box>
                  ))}


                </Box>
              </SubscriptionStyle>

            </Box>
          </Box>
        </Box>
        <DiscoverBox>
          <img src={subscriptionLogo} style={{ width: 56, height: 56, marginTop: '-25' }} />
          <Typography className="discoverText">Discover our Plans</Typography>
          <Typography style={webStyle.subPlanText}>Explore the various subscription options tailored to meet your needs. From individual to crew plans, unlock exclusive features such as photo storage, family tree tools, recipe sharing, and more. </Typography>
          <img style={webStyle.peopleGroupImg} src={peopleGroup}/>
        </DiscoverBox>
        <JoinCommunityBox>
          <Box>
            <Typography className="joinCommunityText">Join our community
              of 500+ individuals and start your journey with My Kinships today
            </Typography>
            <Button
              className="styleNowButton2"
            >
              <Typography style={webStyle.startNowText}>
                Get started
              </Typography>
            </Button>
          </Box>
          <img className="frGroup" src={frGroup} />
        </JoinCommunityBox>
        <FreqBox>
          <Typography className="frequentlyText">Frequently asked questions</Typography>
          <Typography style={webStyle.simpleText}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
        </FreqBox>
        
         <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
        <div style={{ width: '100%', marginTop: 50, maxWidth: 920, margin: 'auto' }}>
          {this.state.faqsData.map((faq:any, index:any) => (
            <Box
              key={index}
              style={{
                borderRadius: '4px',
                marginBottom: 16,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderBottom: expanded !== index ? '1px solid #D6D3D1' : 'none',
                }}
                data-test-id="coverAccordion1TestId"
                onClick={() => this.handleAccordion(index)}
              >
                <Typography
                  style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 700, fontSize: 18 }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question) }} >
                  
                </Typography>
                <IconButton
                  size="small"
                  data-test-id="coverAccordion2"
                  aria-expanded={expanded === index}
                  aria-label="show more"
                  style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                >
                  <img
                    src={downArrow}
                    style={{
                      width: 24,
                      transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    alt="Toggle"
                  />
                </IconButton>
              </Box>
              {expanded === index && (
                <Box style={{ padding: '12px', borderBottom: '1px solid #D6D3D1' }}>
                  <Typography
                    style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#5D6063' }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }}>
                    
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </div>
      </Container>
        <Footer navigation={this.props.navigation} id={""}/>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  cancleButton: {
    backgroundColor: '#C2E176',
    borderRadius: '8px',
    height: '56px',
    width: '195px',
    marginTop: '32px',
  },
  cancelText: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#8CA74B',
    textTransform: 'none',
  },
  styleNowButton: {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '20px',
    marginBottom: '48px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: '182px',
  },
  
  startNowText: {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "none",
    fontFamily: 'Poppins',
    color: '#FFFFFF',
  } as React.CSSProperties,

  headerView: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: '1px solid #E7E5E4'
  },

  subHeaderText: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063'
  },
  firstCardText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 30,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#1C1917'
  },
  secondCardText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#1C1917'
  },
  greenBox: {
    backgroundColor: '#8CA74B',
    padding: '16px',
    width: '100%',
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: 20
  },
  greenBox2: {
    backgroundColor: '#557C29',
    padding: '16px',
    width: '100%',
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: 20
  },
  monthText: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'center',
    color: '#fff'
  },
  subscriptionText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#475467',
    padding: 20,
    minHeight: '250px',
    textAlign: "initial"
  },
  dollerSign: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '32px',
    color: '#fff'
  },
  subPlanText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#475467',
    marginTop: 10
  },
  peopleGroupImg: {
    width: "100%",
    marginTop: "30px",
  },
  trailText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#475467',
    marginTop: 10
  },
  simpleText: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063'
  },
  
  titleTexts: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110"
  },
  titleTexts2: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    marginTop: 10
  },
  continueButton: {
    backgroundColor: "#8CA74B",
    borderRadius: '8px',
    height: '56px',
    width: '195px',
    marginTop: '32px',
  },
  continueText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  logoStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    width: '24px',
    height: '24px',
  },
};



const CustomErrorMessage = styled(Box)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "12px",
  color: 'red',
  marginTop: '5px',
})


const SubscriptionStyle = styled("div")({
  marginTop: "50px",
  "@media (max-width: 468px)": {
    marginTop: "20px",
  },
  "& .priceMainBox": {
    boxSizing: "border-box",
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: "wrap",
    gap: "30px",
    width: '85%',
    margin: "auto",
    "@media (max-width: 860px)": {
      justifyContent: 'center',
      alignItems: "center",
    },
    "@media (max-width: 468px)": {
      width: "90%",
    },
  },
  "& .priceBox": {
    width: '350',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    borderRadius: '16px',
  },
  "& .priceTitle": {
    lineHeight: "44px",
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#1C2110',
    fontSize: "36px",
    letterSpacing: '-1%',
    marginTop: '40px',
    textAlign: 'center',
    "@media (max-width: 468px)": {
      fontSize: "28px",
    },
  },
  "& .priceSubTitle": {
    lineHeight: "26px",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#1C2110',
    marginTop: '-15px',
    textAlign: 'center'
  },
  "& .priceCenterDiv": {
    height: '130px',
    backgroundColor: "#8CA74B",
    textAlign: 'center',
    marginTop:30
  },

  "& .priceCenterBox": {
    height: '140px',
    backgroundColor: "#557C29",
  },
  "& .priceTag": {
    fontWeight: 500,
    fontSize: "64px",
    lineHeight: "56px",
    fontFamily: 'Inter',
    color: '#FFFFFF',
    textAlign: 'center',
    letterSpacing: '-1.5%',
    padding: '13px',
  },
  "& .dollar": {
    lineHeight: "56px",
    fontWeight: 300,
    fontFamily: 'Inter',
    fontSize: "32px",
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText": {
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    marginBottom: '20px',
    lineHeight: "28px",
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    fontSize: "20px",
    color: '#1C2110',
    marginBottom: '40px',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '30px',
    marginLeft: "auto",
    marginBottom: '48px',
    marginRight: "auto",
    textTransform:'none',
    display: "block",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      fontWeight: 700,
      fontSize: "16px",
      color: '#FFFFFF',
      textAlign: 'center',
      fontFamily: 'Poppins',
    }
  }
});

const HeaderTypography = styled(Typography)({
  fontSize: 36,
  fontFamily: 'Poppins',
  fontWeight: 700,
  color: '#1C1917',
  "@media (max-width: 468px)": {
    fontSize: "28px",
  },
});

const DiscoverBox = styled(Box)({
  textAlign: 'center', 
  width: '65%', 
  margin: 'auto', 
  marginTop: 80,
  "@media (max-width: 860px)": {
    width: '80%', 
  },
  
  "& .discoverText": {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#1C1917',
    marginTop: 20,
    "@media (max-width: 468px)": {
      width: "90%",
      fontSize: "28px",
    },
  }
});

const JoinCommunityBox = styled(Box)({
  width: '85%',
  margin: 'auto', 
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center',
  gap: 64, 
  marginTop: 100, 
  "@media (max-width: 1125px)": {
    gap: "30px",
  },
  "@media (max-width: 1024px)": {
    gap: "0px",
  },
  "@media (max-width: 950px)": {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .joinCommunityText": {
    fontSize: 48,
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#1C2110',
    width: 576,
    display: 'flex',
    alignItems: 'center',
    lineHeight:1,
    letterSpacing:-2,
    "@media (max-width: 1125px)": {
      fontSize: 36,
    },
    "@media (max-width: 950px)": {
      width: "80%",
      margin: "auto",
    },
    "@media (max-width: 468px)": {
      fontSize: "28px",
    },
  },
  "& .frGroup": {
    width: '100%',
    margin: 'auto',
    "@media (max-width: 950px)": {
      marginTop: "20px",
      width: "50%"
    },
  },
  "& .styleNowButton2": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '48px',
    display: "block",
    width: '182px',
    "@media (max-width: 950px)": {
      margin: "auto",
      marginTop: '30px',
    },
  },
});

const FreqBox = styled(Box)({
  width: '85%',
  margin: 'auto',
  "& .frequentlyText": {
    fontSize: 36,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#1C1917',
    marginTop: 100,
    "@media (max-width: 860px)": {
      marginTop: "40px",
    },
    "@media (max-width: 668px)": {
      fontSize: 28,
    },
    "@media (max-width: 468px)": {
      fontSize: 24,
    },
  },
})
// Customizable Area End
