import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { SharingDashboardZeroStyle } from "../../../blocks/dashboard/src/SharingDashboard.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import HeaderDropdown from "../../../blocks/navigationmenu/src/HeaderDropdown.web";
// Customizable Area End

import DataStorageController, {
  Props,
  configJSON,
} from "./DataStorageController.web";
import { storageIcon } from "./assets";

export default class DataStorage extends DataStorageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <SharingDashboardZeroStyle>
        <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Storage" />
        <Box className="MainContent">
          <Box className="Header">
            <HeaderDropdown
              data-test-id="headerComponent" navigation={this.props.navigation} id={""}
            />
          </Box>
          <Typography className="page-title">
          Storage
          </Typography>
          <Box className="centerDiv">
            <img className="img" src={storageIcon} alt="Trash Icon" />
            <Typography className="welcomeTitle">Your Storage is Empty</Typography>
            <Typography className="sublabel">Once you start uploading files, photos, or videos, they’ll be stored here for easy access.</Typography>
            <Button className="shareBtn" data-test-id="uploadBtn"
            ><Typography className="shareBtnText">Start Uploading
            </Typography>
            </Button>
          </Box>
          
        </Box>
      </SharingDashboardZeroStyle>
  // Customizable Area End
);
  }
}

// Customizable Area Start
// Customizable Area End
