import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otpValue: string;
  apiError: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TwoFactorAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validateOtpId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      otpValue: '',
      apiError: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.validateOtpId
    ) {
      if (responseJson?.error && responseJson?.error?.length !== 0) {
        const errorMessage = "Invalid one time password";
        this.setState({
          otpValue: '',
          apiError: errorMessage,
        })
      }
      else {
        const email = localStorage.getItem("email");
        const fullName = localStorage.getItem("fullName");
        if (email && fullName) {
          localStorage.setItem("token", responseJson.meta.token)
          this.setState({ apiError: '', otpValue: '' });
          this.props.navigation.navigate("Subscriptionbilling");
        } else {
          localStorage.setItem("token", responseJson.meta.token)
          this.props.navigation.navigate('SharingDashboard');
        }
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  handleOtpChange = (otp: string) => {
    this.setState({
      otpValue: otp
    });
  }

  handleVerifyClick = () => {

    const email = localStorage.getItem("email");
    const otp = this.state.otpValue;
    const params = { otp, email };


    const convertString = (params: any) => {
      return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    };

    const queryString = convertString(params);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateOtpId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/verify_otp?${queryString}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true

  }

  handleGoBack = () => {
    const email = localStorage.getItem("email");
    const fullName = localStorage.getItem("fullName");
    if (email && fullName) {
      this.props.navigation.navigate("Signuplogin")
    } else {
      this.props.navigation.navigate("Login");
    }
  };
  // Customizable Area End
}
