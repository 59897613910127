import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import FamilyTreeDashboardController, {
    Props,
} from "./FamilyTreeDashboardController";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { familyZero } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { SharingDashboardZeroStyle } from "./SharingDashboard.web";
// Customizable Area End

export default class FamilyTreeDashboard extends FamilyTreeDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SharingDashboardZeroStyle>
                <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Family Tree" />
                <Box className="MainContent">
                    <Box className="Header">
                        <HeaderDropdown
                            data-test-id="headerComponent" navigation={this.props.navigation} id={""}
                        />
                    </Box>
                    <Typography className="page-title">
                        Family Tree
                    </Typography>
                    <Box className="centerDiv">
                        <img className="img" src={familyZero} alt="Trash Icon" />
                        <Typography className="welcomeTitle">Your Family Tree is Empty</Typography>
                        <Typography className="sublabel">Begin building your family tree by adding relatives. Your connections will grow here!</Typography>
                        <Button className="shareBtn" style={{
                            width: "180px"
                        }}data-test-id="family-tree"
                        ><Typography className="shareBtnText">Create Family Tree</Typography></Button>
                    </Box>
                </Box>
            </SharingDashboardZeroStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End
