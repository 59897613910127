export const logo = require("../assets/logo2.png");
export const avatar = require("../assets/avatar_.png");
export const BackArrowBlack = require("../assets/arrowBlack.png");
export const visaLogo = require("../assets/visaLogo.svg");
export const logoofmaster = require("../assets/logoofmaster.svg");
export const iIcon = require("../assets/iIcon.png");
export const profile = require("../assets/profile.png");
export const download = require("../assets/download.png");
export const download2 = require("../assets/download2.png");
export const kinshipslogo = require("../assets/kinshipslogo.png");
export const failPayment = require("../assets/failPayment.png");
export const wallet = require("../assets/wallet.png");
export const deleteIcon = require("../assets/delete.png");
export const bankAccountWhiteLogo = require("../assets/bankAccountWhiteLogo.png");
export const bankAccountGreyLogo = require("../assets/bankAccountGreyLogo.png");
export const creditCardWhiteLogo = require("../assets/creditCardLogo.png");
export const creditCardGreyLogo = require("../assets/creditCardGreyLogo.png");
export const dollarSign = require("../assets/dollar.png");
export const trashIcon = require("../assets/trash.png");